import React, { useEffect } from 'react'
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import { signOutStart } from '../../../redux/users/user.actions';

function ProfileLogout({signOut}) {
    let history = useHistory();
    useEffect(() => {
        document.cookie = '';
        signOut()
        history.push('/')
    }, [signOut, history])
    return (
        <h1>
            Logging Out...
        </h1>
    )
}

const mapDispatchToProps = (dispatch) => ({
    signOut: () => dispatch(signOutStart()),
  });

export default connect(null, mapDispatchToProps)(ProfileLogout);

