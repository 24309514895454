import { createSelector } from "reselect";
import { Farm } from "../../common/interfaces/Farms";
import { convertObjectToArray } from "../../utils/array/mapper";
import { selectCurrentUser } from "../users/user.selectors";
import { FarmState } from "./farm.reducer";

export const selectFarmState = (state:any) => state.farms as FarmState | undefined;

export const selectSelectedFarmId = createSelector<any, any>(
  [selectFarmState],
  (farms:FarmState| undefined) => farms?.selectedFarmId as String
);

export const selectFarmLoading = createSelector<any, any>(
  [selectFarmState],
  (farms:FarmState| undefined) => farms?.processing
);

export const selectVisibleFarms = createSelector<any, any>(
  [selectFarmState],
  (farms:FarmState| undefined) => farms?.visibleFarms
);

export const selectVisibleFarmsAsArray = createSelector<any,Array<Farm>>(
  [selectVisibleFarms],
  (allFarms: Object | undefined) =>
    allFarms
      ? convertObjectToArray(allFarms??{}).filter((farm) => !farm.deleted) as Array<Farm>
      : [] as Array<Farm>
);

export const selectCurrentUserOwnedFarmsAsArray = createSelector<any, any>(
  [selectVisibleFarmsAsArray, selectCurrentUser],
  (allFarms:Farm[], user:any | never) => {
    return allFarms ? allFarms.filter((f) => f.creatingUserId === user?.id ?? user?.uid ?? '') : [] as Farm[];
  }
);

export const selectUserOwnedFarmsAsArray = (user) =>
  createSelector<any, any>([selectVisibleFarmsAsArray], (allFarms) =>
    allFarms.filter(
      (f) => f.creatingUserId === user?.id ?? user?.uid
    )
  );

export const selectCurrentUserManagingFarmsAsArray = createSelector<any, any>(
  [selectVisibleFarmsAsArray, selectCurrentUser],
  (allFarms, user) =>
    allFarms.filter((f) =>
      f.managingUsers?.includes(user?.id ?? user?.uid)
    )
);

export const selectManagingFarmsAsArray = (user) =>
  createSelector<any, any>([selectVisibleFarms], (allFarms) =>
    convertObjectToArray(allFarms??{}).filter((f) =>
      f.managingUsers?.includes(user?.id ?? user?.uid)
    )
  );

export const selectCurrentUserWorkingFarmsAsArray = createSelector<any, any>(
  [selectVisibleFarms, selectCurrentUser],
  (allFarms, user) =>
    convertObjectToArray(allFarms??{}).filter((f) =>
      f.workingUsers?.includes(user?.id ?? user?.uid)
    )
);

export const selectWorkingFarmsAsArray = (user) =>
  createSelector<any, any>([selectVisibleFarms], (allFarms) =>
    convertObjectToArray(allFarms??{}).filter((f) =>
      f.workingUsers?.includes(user?.id ?? user?.uid)
    )
  );

export const selectCurrentUserFollowingFarmsAsArray = createSelector<any, any>(
  [selectVisibleFarms, selectCurrentUser],
  (allFarms, user) =>
    convertObjectToArray(allFarms??{}).filter((f) =>
      f.followingUsers?.includes(user?.id ?? user?.uid)
    )
);

export const selectFollowingFarmsAsArray = (user) =>
  createSelector<any, any>([selectVisibleFarms], (allFarms) =>
    convertObjectToArray(allFarms??{}).filter((f) =>
      f.followingUsers?.includes(user?.id ?? user?.uid)
    )
  );

export const selectCurrentUserInvitedFarmsAsArray = createSelector<any, any>(
  [selectVisibleFarms, selectCurrentUser],
  (allFarms, user) =>
    convertObjectToArray(allFarms??{}).filter((f) =>
      f.invitedUsers?.includes(user?.email)
    )
);

export const selectInvitedFarmsAsArray = (user) =>
  createSelector<any, any>([selectVisibleFarms], (allFarms) =>
    convertObjectToArray(allFarms??{}).filter((f) =>
      f.invitedUsers?.includes(user?.email)
    )
  );

export const selectCurrentFarm = createSelector<any, any>(
  [selectFarmState],
  (farms) => farms?.visibleFarms[farms.selectedFarmId]
);

export const selectCurrentSelectedInvitedWorkersArray = createSelector<any, any>(
  [selectCurrentFarm],
  (farm) => {
    let returnWorker = farm?.invitedUsers ? farm?.invitedUsers.reverse() : [];
    return returnWorker;
  }
);

export const selectCurrentSelectedFarmWorkersArray = createSelector<any, any>(
  [selectCurrentFarm],
  (farm) => {
    let returnWorker = farm?.workingUsers ? farm?.workingUsers?.reverse() : [];
    return returnWorker;
  }
);

export const selectUserCanModifyFarm = createSelector<any, any>(
  [selectCurrentUser, selectCurrentFarm],
  (user, farm) => {
    if (!user || !farm) return false;
    if (
      farm.managingUsers?.includes(user.id ?? user.uid) ||
      farm.creatingUserId === (user.id ?? user.uid)
    )
      return true;
    else return false;
  }
);

export const selectUserCanEditFarm = createSelector<any, any>(
  [selectCurrentUser, selectCurrentFarm],
  (user, farm) => {
    if (!user || !farm) return false;
    if (farm.creatingUserId === (user.id ?? user.uid)) return true;
    else return false;
  }
);
