import { omit } from 'lodash';
import animalActionTypes from './animal.action-types';
import ANIMAL_DETAILS_INITIAL_STATE from './animal.state';
import { mapStringToFilters } from './animal.utils';
import { replaceCampAnimals } from './animal.utils';
import UploadingGif from '../../common/assets/uploading.gif';

const animalReducer = (state = ANIMAL_DETAILS_INITIAL_STATE, action) => {
  const { type, payload } = action;
  const {
    animalDetails,
    weightDetails,
    medicationDetails,
    milkDetails,
    heatDetails,
    sellDetails,
    feedDetails,
    feedInfo,
    campHistory,
    endDetails,
  } = payload ?? {
    animalDetails: null,
    weightDetails: null,
    medicationDetails: null,
    milkDetails: null,
    heatDetails: null,
    sellDetails: null,
    feedDetails: null,
    feedInfo: null,
    campHistory: null,
    endDetails: null
  };
  let animalId = animalDetails?.id ?? animalDetails?.animalId;
  if (!animalId) animalId = payload?.animalId ?? payload?.id;

  switch (type) {
    case animalActionTypes.CLEAR_ANIMALS:
      return ANIMAL_DETAILS_INITIAL_STATE;

    case animalActionTypes.SET_PROCESSING_START:
      return {
        ...state,
        processing: true,
      };

    case animalActionTypes.CLEAR_FILTERS:
      return {
        ...state,
        animalFilters: {},
      };

    case animalActionTypes.SET_SELECTED_ANIMAL:
      return {
        ...state,
        selectedAnimalId: payload,
      };

    case animalActionTypes.FETCH_ANIMALS_FORSALE_SUCCESS:
      return {
        ...state,
        animalsForSale: payload,
      };

    case animalActionTypes.UPDATE_FILTERS:
      return {
        ...state,
        animalFilters: mapStringToFilters(payload),
      };

    case animalActionTypes.CREATE_ANIMAL_START:
      return {
        ...state,
        processing: true,
      };

    case animalActionTypes.CREATE_ANIMAL_FAILURE:
      return {
        ...state,
        error: payload,
        processing: false,
      };

    case animalActionTypes.FETCH_ANIMAL_START:
    case animalActionTypes.UPDATE_ANIMAL_START:
    case animalActionTypes.ADD_ANIMAL_WEIGHT_START:
    case animalActionTypes.ADD_ANIMAL_HEAT_START:
      return {
        ...state,
        processing: true,
      };

    case animalActionTypes.FETCH_ANIMAL_FAILURE:
    case animalActionTypes.ADD_ANIMAL_WEIGHT_FAILURE:
      return {
        ...state,
        error: payload,
        processing: false,
      };

    case animalActionTypes.CREATE_ANIMAL_SUCCESS:
      return {
        ...state,
        processing: false,
        visibleAnimals: {
          ...state.visibleAnimals,
          [animalId]: {
            ...payload,
            animalImage: state.visibleAnimals[animalId]?.animalImage?.includes('http') ? state.visibleAnimals[animalId]?.animalImage : UploadingGif,
            animalImages: [UploadingGif]
          }
        },
        selectedAnimalId: animalId ?? state.selectedAnimalId,
      };

    case animalActionTypes.UPDATE_ANIMAL_SUCCESS:
      return {
        ...state,
        processing: false,
        visibleAnimals: {
          ...state.visibleAnimals,
          [animalId]: {
            ...payload,
            animalImage: state.visibleAnimals[animalId]?.animalImage ?? UploadingGif,
            animalImages: state.visibleAnimals[animalId]?.animalImages ?? [UploadingGif]
          }
        },
        selectedAnimalId: animalId ?? state.selectedAnimalId,
      };


    case animalActionTypes.ADD_ANIMAL_IMAGES_SUCCESS:
      return {
        ...state,
        processing: false,
        visibleAnimals: {
          ...state.visibleAnimals,
          [animalId]: payload,
        },
        selectedAnimalId: animalId ?? state.selectedAnimalId,
      };

    case animalActionTypes.ASSIGN_FARM_AND_CAMP_SUCCESS:
      return {
        ...state,
        processing: false,
        visibleAnimals: {
          ...state.visibleAnimals,
          [animalId]: payload,
        },
        purchasedAnimals: {
          ...omit(state.purchasedAnimals, animalId),
        },
      };

    case animalActionTypes.ADD_ANIMAL_WEIGHT_SUCCESS:
      return {
        ...state,
        processing: false,
        visibleAnimals: {
          ...state.visibleAnimals,
          [animalId]: {
            ...state.visibleAnimals[animalId],
            weightData: weightDetails,
          },
        },
        selectedAnimalId: animalId ?? state.selectedAnimalId,
      };

    case animalActionTypes.ADD_ANIMAL_MEDICATION_SUCCESS:
      return {
        ...state,
        processing: false,
        visibleAnimals: {
          ...state.visibleAnimals,
          [animalId]: {
            ...state.visibleAnimals[animalId],
            medicationData: medicationDetails,
          },
        },
        selectedAnimalId: animalId ?? state.selectedAnimalId,
      };

    case animalActionTypes.ADD_ANIMAL_MILK_SUCCESS:
      return {
        ...state,
        processing: false,
        visibleAnimals: {
          ...state.visibleAnimals,
          [animalId]: {
            ...state.visibleAnimals[animalId],
            milkData: milkDetails,
          },
        },
        selectedAnimalId: animalId ?? state.selectedAnimalId,
      };

    case animalActionTypes.ADD_ANIMAL_HEAT_SUCCESS:
      return {
        ...state,
        processing: false,
        visibleAnimals: {
          ...state.visibleAnimals,
          [animalId]: {
            ...state.visibleAnimals[animalId],
            heatData: heatDetails,
          },
        },
        selectedAnimalId: animalId ?? state.selectedAnimalId,
      };

    case animalActionTypes.SELL_ANIMAL_SUCCESS:
      return {
        ...state,
        processing: false,
        animalsForSale: {
          ...state.animalsForSale,
          [animalId]: {
            ...animalDetails,
            sellData: sellDetails,
          },
        },
        selectedAnimalId: animalId ?? state.selectedAnimalId,
      };

    case animalActionTypes.SOLD_ANIMAL_SUCCESS:
      return {
        ...state,
        processing: false,
        soldAnimals: {
          ...state.soldAnimals,
          [animalId]: payload.animalDetails,
        },
        visibleAnimals: {
          ...omit(state.visibleAnimals, animalId),
        },
        selectedAnimalId: state.visibleAnimals[0]
          ? state.visibleAnimals[0]?.id
          : '',
      };

    case animalActionTypes.FETCH_PURCHASED_ANIMALS_SUCCESS:
      return {
        ...state,
        processing: false,
        purchasedAnimals: {
          ...state.purchasedAnimals,
          ...payload,
        },
      };

    case animalActionTypes.FETCH_SOLD_ANIMALS_SUCCESS:
      return {
        ...state,
        processing: false,
        soldAnimals: {
          ...state.soldAnimals,
          ...payload,
        },
      };

    case animalActionTypes.ADD_ANIMAL_FEED_SUCCESS:
      return {
        ...state,
        processing: false,
        visibleAnimals: {
          ...state.visibleAnimals,
          [animalId]: {
            ...state.visibleAnimals[animalId],
            animalFeed: feedDetails,
          },
        },
        selectedAnimalId: animalId ?? state.selectedAnimalId,
      };

    case animalActionTypes.ADD_CAMP_ANIMAL_FEED_SUCCESS:
      return {
        ...state,
        processing: false,
        visibleAnimals: {
          ...state.visibleAnimals,
          [animalId]: {
            ...state.visibleAnimals[animalId],
            animalFeed: feedInfo,
          },
        },
        selectedAnimalId: animalId ?? state.selectedAnimalId,
      };

    case animalActionTypes.END_ANIMAL_SUCCESS:
      return {
        ...state,
        processing: false,
        visibleAnimals: {
          ...state.visibleAnimals,
          [animalId]: {
            ...state.visibleAnimals[animalId],
            animalEnd: endDetails,
          },
        },
        selectedAnimalId: animalId ?? state.selectedAnimalId,
      };

    case animalActionTypes.DELETE_LATEST_ANIMAL_CAMP_HISTORY_SUCCESS:
      return {
        ...state,
        processing: false,
        visibleAnimals: {
          ...state.visibleAnimals,
          [animalId]: {
            ...state.visibleAnimals[animalId],
            campHistory: campHistory,
          },
        },
        selectedAnimalId: animalId ?? state.selectedAnimalId,
      };

    case animalActionTypes.FETCH_ANIMAL_SUCCESS:
      return {
        ...state,
        processing: false,
        visibleAnimals: {
          ...state.visibleAnimals,
          ...payload,
        },
      };

    case animalActionTypes.FETCH_CAMP_ANIMAL_SUCCESS:
      return {
        ...state,
        processing: false,
        visibleAnimals: replaceCampAnimals(state.visibleAnimals, payload),
      };

    case animalActionTypes.DELETE_ANIMAL_SUCCESS:
      return {
        ...state,
        processing: false,
        visibleAnimals: {
          ...omit(state.visibleAnimals, animalId),
        },
        selectedAnimalId: state.visibleAnimals[0]
          ? state.visibleAnimals[0]?.id
          : '',
      };

    case animalActionTypes.UNDO_ANIMAL_SELL_SUCCESS:
      return {
        ...state,
        processing: false,
        animalsForSale: {
          ...omit(state.animalsForSale, animalId),
        },
      };

    default:
      return state;
  }
};

export default animalReducer;
