import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { fetchAgentOrdersStart } from '../../../redux/orders/order.actions';
import { selectCurrentAgentOrdersAsArray } from '../../../redux/orders/order.selectors';
import { selectCurrentUser } from '../../../redux/users/user.selectors';
import { Grid, Box } from '@material-ui/core';
import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeItem from '@material-ui/lab/TreeItem';
import {
  GriDisplayFlex,
  MainColor,
  Bold,
} from '../../../styles/shared/shared.styles';
import { firebaseTimeStampToDate } from '../../minimal-ui/utils/formatTime';
import TreeViewTitle from '../../shared/tree-view-title/tree-view-title.component';

const AgentOrders = ({ user, fetchAgentOrders, agentOrders }) => {
  useEffect(() => {
    if (!user) return;
    if (user && user?.repCode) {
      fetchAgentOrders(user?.repCode);
    }
  }, []);

  return (
    <div>
      {agentOrders.map((order) => (
        <Box key={order?.createdDate.seconds}>
          <TreeView
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
          >
            <TreeItem
              nodeId="1"
              label={<TreeViewTitle item={order} title="Order:" />}
            >
              <Box>
                {order?.createdDate && (
                  <GriDisplayFlex container>
                    <Grid item xs={5}>
                      <Bold>
                        <MainColor>Date</MainColor>
                      </Bold>
                    </Grid>
                    <Grid item xs={7}>
                      {firebaseTimeStampToDate(order?.createdDate)}
                    </Grid>
                  </GriDisplayFlex>
                )}
                {order?.agentFee && (
                  <GriDisplayFlex container>
                    <Grid item xs={5}>
                      <Bold>
                        <MainColor>Agent Fee</MainColor>
                      </Bold>
                    </Grid>
                    <Grid item xs={7}>
                      R{order?.agentFee}
                    </Grid>
                  </GriDisplayFlex>
                )}
              </Box>
            </TreeItem>
          </TreeView>
        </Box>
      ))}
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  user: selectCurrentUser,
  agentOrders: selectCurrentAgentOrdersAsArray,
});

const mapDispatchToProps = (dispatch) => ({
  fetchAgentOrders: (AgentCode) => dispatch(fetchAgentOrdersStart(AgentCode)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AgentOrders));
