import React from 'react';
import AccordionItem from '../../minimal-ui/accordion/Accordion';
import ProfileAgentOrders from '../profile-agent-orders/profile-agent-orders.component';
import ProfileAgentSubscriptions from '../profile-agent-subscriptions/profile-agent-subscriptions.component';
import ReceiptIcon from '@material-ui/icons/Receipt';
import LoyaltyIcon from '@material-ui/icons/Loyalty';
import { Container } from '@material-ui/core';

const ProfileAgent = () => {
  return (
    <Container disableGutters maxWidth="md">
      <AccordionItem
        title="Orders"
        icon={<ReceiptIcon width={20} height={20} />}
        expanded={true}
      >
        <ProfileAgentOrders />
      </AccordionItem>
      <AccordionItem
        title="Subscriptions"
        icon={<LoyaltyIcon width={20} height={20} />}
        expanded={true}
      >
        <ProfileAgentSubscriptions />
      </AccordionItem>
      {/* <div>Total Agent Fee is 0</div> */}
    </Container>
  );
};

export default ProfileAgent;
