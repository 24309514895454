import documentActionTypes from './document.action-types';

export const clearDocuments = () => ({
  type: documentActionTypes.CLEAR_DOCUMENTS,
});

export const startDocumentUpload = (documentInfo) => ({
  type: documentActionTypes.START_DOCUMENT_UPLOAD,
  payload: documentInfo,
});

export const documentUploadSuccess = (documentInfo) => ({
  type: documentActionTypes.DOCUMENT_UPLOAD_SUCCESS,
  payload: documentInfo,
});

export const documentUploadFailed = (error) => ({
  type: documentActionTypes.DOCUMENT_UPLOAD_FAILED,
  payload: error,
});

export const documentFetchSuccess = (documentInfo) => ({
  type: documentActionTypes.DOCUMENT_FETCH_SUCCESS,
  payload: documentInfo,
});

export const documentFetchFailed = (error) => ({
  type: documentActionTypes.DOCUMENT_FETCH_FAILED,
  payload: error,
});

export const startFarmDocumentFetch = (farmId) => ({
  type: documentActionTypes.DOCUMENT_FARM_FETCH_START,
  payload: farmId,
});

export const documentFarmFetchSuccess = (farmId) => ({
  type: documentActionTypes.DOCUMENT_FARM_FETCH_SUCCESS,
  payload: farmId,
});

export const documentFarmFetchFailer = (error) => ({
  type: documentActionTypes.DOCUMENT_FARM_FETCH_FAILURE,
  payload: error,
});

export const startAnimalDocumentFetch = (animalId) => ({
  type: documentActionTypes.DOCUMENT_ANIMAL_FETCH_START,
  payload: animalId,
});

export const documentAnimalFetchSuccess = (animalId) => ({
  type: documentActionTypes.DOCUMENT_ANIMAL_FETCH_SUCCESS,
  payload: animalId,
});

export const documentAnimalFetchFailer = (error) => ({
  type: documentActionTypes.DOCUMENT_ANIMAL_FETCH_FAILURE,
  payload: error,
});

export const deleteFileStart = (document) => ({
  type: documentActionTypes.DELETE_DOCUMENT_START,
  payload: document,
});

export const deleteFileSuccess = (document) => ({
  type: documentActionTypes.DELETE_DOCUMENT_SUCCESS,
  payload: document,
});

export const deleteFileFailure = (error) => ({
  type: documentActionTypes.DELETE_DOCUMENT_FAILURE,
  payload: error,
});
