import subscriptionActionTypes from './subscription.action-types';

const INITIAL_STATE = {
  processing: null,
  selectedSubscription: '',
  subscriptions: {},
  agentSubscriptions: {},
  error: null,
};

const subscriptionsReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case subscriptionActionTypes.CLEAR_SUBSCRIPTIONS:
      return INITIAL_STATE;

    case subscriptionActionTypes.START_SUBSCRIPTION_CREATE:
      return {
        ...state,
        processing: true,
      };

    case subscriptionActionTypes.SUBSCRIPTION_CREATE_FAILED:
      return {
        ...state,
        processing: false,
      };

    case subscriptionActionTypes.SUBSCRIPTION_CREATE_SUCCESS:
    case subscriptionActionTypes.SUBSCRIPTION_UPDATE_SUCCESS:
      return {
        ...state,
        subscriptions: {
          ...state.subscriptions,
          [payload.subscriptionId ?? payload.id]: payload,
        },
        processing: false,
      };

    case subscriptionActionTypes.SUBSCRIPTION_FETCH_SUCCESS:
      return {
        ...state,
        subscriptions: {
          ...state.subscriptions,
          ...payload,
        },
        processing: false,
      };

    case subscriptionActionTypes.FETCH_AGENT_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        processing: false,
        agentSubscriptions: payload,
      };

    default:
      return state;
  }
};

export default subscriptionsReducer;
