import firebase from "firebase/compat/app";
import 'firebase/compat/firestore'
import 'firebase/compat/functions'
import 'firebase/compat/storage'
import 'firebase/compat/auth'
import 'firebase/compat/analytics'
import 'firebase/compat/messaging';
import 'firebase/compat/app-check';
import { getAuth, GoogleAuthProvider, FacebookAuthProvider, connectAuthEmulator } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDoycCx1GYeeZKvaNFdo1uZOSQPmS5jqvQ",
  authDomain: "anmlfarm-web.firebaseapp.com",
  databaseURL: "https://anmlfarm-web-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "anmlfarm-web",
  storageBucket: "anmlfarm-web.appspot.com",
  messagingSenderId: "149496621239",
  appId: "1:149496621239:web:354464786c0ed0d8ecc532",
  measurementId: "G-3KFJ1FX256"
};

export const mapboxMapConfig = `pk.eyJ1IjoidGhlamVhbmxvdXciLCJhIjoiY2tiam1kdzlrMHFydTJ4bHMyNXlybHBtaCJ9.zo2T40wde0uOC5g7m1LpHw`;
export const vapidKey = 'BF_NX0aDr5H09XjEqi1TO9pHDdEo_X81BKpNJ6BV8Fb5xee1C1h5Rzpc54r5TK4gD99v-ROLN02UrRjHSJwf-CE';

firebase.initializeApp(firebaseConfig);
export const auth = getAuth();
export const storage = firebase.storage();
storage.setMaxUploadRetryTime(100000);
storage.setMaxOperationRetryTime(100000);
export const firestore = firebase.firestore();
export const functions = firebase.functions();
export const firelytics = firebase.analytics();
export const appCheck = firebase.appCheck();
export const messaging = firebase.messaging.isSupported() ? firebase.messaging() : null;


// eslint-disable-next-line no-restricted-globals
if (location.hostname === 'localhost') {
  connectAuthEmulator(auth, "http://localhost:9099")
  firestore.useEmulator('localhost', 8080);
  storage.useEmulator("localhost", 9199);
  functions.useEmulator("localhost", 5001);
}

appCheck.activate(
  '6Lds2nwcAAAAANoHJQVJJutn4t2aV84vFoClZTNn',
  true);



try{
  firestore.enablePersistence();
} catch(err) {
  console.log(err)
}
export default firebase;

export const googleProvider = new GoogleAuthProvider();
export const facebookProvider = new FacebookAuthProvider();
// googleProvider.setCustomParameters({
//     prompt: 'select_account',
//     overrideUserAgent: 'Mozilla/5.0 (Linux; Android 4.4.4; One Build/KTU84L.H4) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/33.0.0.0 Mobile Safari/537.36 [FB_IAB/FB4A;FBAV/28.0.0.20.16;]',
//     OverrideUserAgent: 'Mozilla/5.0 (Linux; Android 4.4.4; One Build/KTU84L.H4) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/33.0.0.0 Mobile Safari/537.36 [FB_IAB/FB4A;FBAV/28.0.0.20.16;]',
// })

export const arrayRemove = firebase.firestore.FieldValue.arrayRemove;
export const arrayUnion = firebase.firestore.FieldValue.arrayUnion;
