import eventActionTypes from './event.action-types';

export const selectEvent = (eventId) => ({
  type: eventActionTypes.SELECT_EVENT,
  payload: eventId,
});

export const clearEvents = () => ({
  type: eventActionTypes.CLEAR_EVENTS,
});

export const fetchPublicEventsStart = (fromDate) => ({
  type: eventActionTypes.FETCH_PUBLIC_EVENTS_START,
  payload: fromDate
});

export const fetchDeviceEventsStart = (device) => ({
  type: eventActionTypes.FETCH_DEVICE_EVENTS_START,
  payload: device
});

export const createEventStart = (eventData) => ({
  type: eventActionTypes.CREATE_EVENT_START,
  payload: eventData,
});

export const createEventSuccess = (eventDetails) => ({
  type: eventActionTypes.CREATE_EVENT_SUCCESS,
  payload: eventDetails,
});

export const createEventFailure = (error) => ({
  type: eventActionTypes.CREATE_EVENT_FAILURE,
  payload: error,
});

export const fetchEventSuccess = (eventDetails) => ({
  type: eventActionTypes.FETCH_EVENTS_SUCCESS,
  payload: eventDetails,
});

export const fetchEventFailure = (error) => ({
  type: eventActionTypes.FETCH_EVENTS_FAILURE,
  payload: error,
});
