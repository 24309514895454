import errorActionTypes from "./error.action-types";

const INITIAL_STATE = {
    error: undefined,
    processing: false,
    selectedError: '',
    visibleErrors: {
    }
    
}

const errorsReducer = (state = INITIAL_STATE, action) => {
    const {type, payload} = action;
    switch(type) {


        case errorActionTypes.CLEAR_ERRORS:
            return INITIAL_STATE;

        case errorActionTypes.LOG_ERROR_START:
            return {
                ...state,
                processing: true
            }

        case errorActionTypes.LOG_ERROR_FAILURE:
            return {
                ...state,
                error: payload,
                processing: false
            }


        case errorActionTypes.LOG_ERROR_SUCCESS:
            return {
                ...state,
                processing: false,
                visibleErrors: {
                    ...state.visibleErrors,
                    [payload.errorId]: payload
                },
                selectedError: payload?.errorId ?? state.selectedError
            }


        default:
            return state;
    }
}

export default errorsReducer;