import { omit } from 'lodash';
import reminderActionTypes from './reminder.action-types';
import REMINDER_INITIAL_STATE from './reminder.state';

const reminderReducer = (state = REMINDER_INITIAL_STATE, action) => {
  const { type, payload } = action;
  const {
    reminderDetails
  } = payload ?? {
    reminderDetails: null
  };
  let reminderId = reminderDetails?.id ?? reminderDetails?.reminderId;
  if (!reminderId) reminderId = payload?.reminderId ?? payload?.id;

  switch (type) {

    case reminderActionTypes.CLEAR_REMINDERS:
      return REMINDER_INITIAL_STATE;

    case reminderActionTypes.CLEAR_NOTIFICATIONS:
      return {
        ...state,
        reminderNotifications: {},
      };

    case reminderActionTypes.SET_SELECTED_REMINDER:
      return {
        ...state,
        selectedReminderId: payload,
      };

    case reminderActionTypes.CREATE_REMINDER_START:
      return {
        ...state,
        processing: true,
      };

    case reminderActionTypes.CREATE_REMINDER_FAILURE:
      return {
        ...state,
        error: payload,
        processing: false,
      };

    case reminderActionTypes.FETCH_REMINDER_START:
      return {
        ...state,
        processing: true,
      };

    case reminderActionTypes.FETCH_REMINDER_FAILURE:
      return {
        ...state,
        error: payload,
        processing: false,
      };

    case reminderActionTypes.CREATE_REMINDER_SUCCESS:
      return {
        ...state,
        processing: false,
        visibleReminders: {
          ...state.visibleReminders,
          [reminderId]: payload,
        },
        selectedReminder: reminderId ?? state.selectedReminder,
      };

    case reminderActionTypes.UPDATE_REMINDER_SUCCESS:
      return {
        ...state,
        processing: false,
        visibleReminders: {
          ...state.visibleReminders,
          [reminderId]: payload,
        },
        selectedReminder: reminderId ?? state.selectedReminder,
      };

    case reminderActionTypes.FETCH_REMINDER_SUCCESS:
      return {
        ...state,
        processing: false,
        visibleReminders: {
          ...state.visibleReminders,
          ...payload,
        },
      };

    case reminderActionTypes.DELETE_REMINDER_SUCCESS:
      return {
        ...state,
        processing: false,
        visibleReminders: {
          ...omit(state.visibleReminders, reminderId),
        },
        selectedReminder: state.visibleReminders[0] ? state.visibleReminders[0]?.id : '',
      };

    default:
      return state;
  }
};

export default reminderReducer;
