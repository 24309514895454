export const calculateCurrentAge = (dateTime) => {
  
  if (!dateTime) return `${0} years, ${0} days`;
  const birthday = dateTime.seconds * 1000 ? dateTime : (new Date(dateTime)).getTime();
  const dateDifference = new Date().getTime() - birthday;
  const days = Math.round(dateDifference / (1000 * 60 * 60 * 24));
  const years = Math.floor(days / 365);
  const resDays = Math.round(days % 365);
  return `${years ? years.toString() + ' years, ':''}${resDays} days`;
};

export const calculateTimeDifference = (recentDateTime, originDateTime) => {
  if (!recentDateTime) return `${0} years, ${0} days`;
  const dateTime = recentDateTime.seconds ? recentDateTime : Date.parse(recentDateTime);
  if (!originDateTime) return `${0} years, ${0} days`;
  const originTime = originDateTime.seconds ? originDateTime : Date.parse(originDateTime);
  const dateDifference = dateTime - originTime;
  const days = Math.round(dateDifference / (1000 * 60 * 60 * 24));
  const years = Math.floor(days / 365);
  const resDays = Math.round(days % 365);
  return `${years ? years.toString() + ' years, ':''}${resDays} days`;
};

export const calculateCurrentAgeInDays = (dateTime) => {
  if (!dateTime) return `${0} years, ${0} days`;
  const birthday = dateTime.seconds * 1000 ? dateTime : (new Date(dateTime)).getTime();
  const dateDifference = new Date().getSeconds() - birthday;
  const days = Math.round(dateDifference / (1000 * 60 * 60 * 24));
  return days;
};

export const convertStringToDateTimeSeconds = (dateString) => {
  try {
    return Date.parse(dateString);
  } catch {
    return 0;
  }
};

export const addDaysToStringDate = (dateString, daysToAdd) => {
  let result = new Date(dateString);
  result.setDate(result.getDate() + daysToAdd);
  return result.toISOString().substr(0,10);
}