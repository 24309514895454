import { createSelector } from 'reselect';
import { convertObjectToArray } from '../../utils/array/mapper';
import { convertStringToDateTimeSeconds } from '../../utils/date/calculations';
import {
  selectFarmState,
  selectVisibleFarmsAsArray,
} from '../farms/farm.selectors';
import { selectCurrentUser } from '../users/user.selectors';

export const selectCamps = (state) => state.camps;

export const selectAllCampsAsArray = createSelector<any, any>([selectCamps], (camps) =>
  convertObjectToArray(camps)
);

export const selectCampsLoading = createSelector<any, any>(
  [selectCamps],
  (camps) => camps.processing
);

export const selectVisibleCamps = createSelector<any, any>(
  [selectCamps],
  (camps) => camps.visibleCamps
);

export const selectCurrentCamp = createSelector<any, any>(
  [selectCamps],
  (campDetais) => campDetais.visibleCamps[campDetais.selectedCamp]
);

export const selectVisibleCampsAsArray = createSelector<any, any>(
  [selectVisibleCamps],
  (camps) => convertObjectToArray(camps)
);

export const selectCurrentFarmCamps = createSelector<any, any>(
  [selectFarmState, selectVisibleCampsAsArray],
  (farmState, camps) =>
    camps.filter((c) => ((c.farmId ?? c.id) === farmState?.selectedFarmId) && !c.deleted)
);

export const selectCurrentActiveCampsAsArray = createSelector<any, any>(
  [selectCurrentFarmCamps],
  (camps) => camps.filter((a) => !a.deleted)
);

export const selectRecycleBinCampsAsArray = createSelector<any, any>(
  [selectCurrentFarmCamps],
  (camps) => camps.filter((a) => a.deleted)
);

export const selectCurrentFarmCampsCount = createSelector<any, any>(
  [selectCurrentActiveCampsAsArray],
  (camps) => camps?.length
);

export const selectCampById = (campId) =>
  createSelector<any, any>([selectVisibleCampsAsArray], (camps) =>
    camps ? camps[campId] : null
  );

export const selectCurrentSelectedCampFeedsAsArray = createSelector<any, any>(
  [selectCurrentCamp],
  (camp) => {
    let returnFeed = camp?.feedData
      ? Object.keys(camp.feedData)
          .map((key) => camp.feedData[key])
          .filter((f) => f?.feed?.length > 0)
      : [];
    returnFeed.sort((a, b) =>
      convertStringToDateTimeSeconds(a?.feedDate) >
      convertStringToDateTimeSeconds(b?.feedDate)
        ? 1
        : -1
    );
    return returnFeed;
  }
);

export const selectUserCanModifyCamp = createSelector<any, any>(
  [selectCurrentUser, selectCurrentCamp, selectVisibleFarmsAsArray],
  (user, camp, farms) => {
    if (!user || !camp || !farms) return false;
    const campFarms = farms.filter((f) => camp?.farmId === (f.id ?? f.farmId));
    const userModifyCamps = campFarms.filter(
      (f) =>
        f.workingUsers?.includes(user.id ?? user.uid) ||
        f.managingUsers?.includes(user.id ?? user.uid) ||
        f.creatingUserId === (user.id ?? user.uid)
    );

    if (!userModifyCamps || userModifyCamps?.length === 0) return false;

    return true;
  }
);

export const selectUserCanEditCamp = createSelector<any, any>(
  [selectCurrentUser, selectCurrentCamp, selectVisibleFarmsAsArray],
  (user, camp, farms) => {
    if (!user || !camp || !farms) return false;
    const campFarms = farms.filter((f) => camp?.farmId === (f.id ?? f.farmId));
    const userEditCamps = campFarms.filter(
      (f) =>
        f.managingUsers?.includes(user?.id ?? user.uid) ||
        f.creatingUserId === (user.id ?? user.uid)
    );

    if (!userEditCamps || userEditCamps?.length === 0) return false;

    return true;
  }
);
