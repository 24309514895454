import { omit } from 'lodash';
import farmDetailsActionTypes from './farm.action-types';
import UploadingGif from '../../common/assets/uploading.gif';
import { Farm } from '../../common/interfaces/Farms';

export interface FarmState {
  visibleFarms: {
    [farmId: string]: Farm
  };
  selectedFarmId: string;
  processing: boolean;
  error: string | undefined;
}

const INITIAL_STATE:FarmState = {
  error: undefined,
  processing: false,
  selectedFarmId: '',
  visibleFarms: {},
};

const farmsReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case farmDetailsActionTypes.CLEAR_FARMS:
      return INITIAL_STATE;

    case farmDetailsActionTypes.SELECT_FARM:
      return {
        ...state,
        selectedFarmId: payload,
      };

    case farmDetailsActionTypes.CREATE_FARM_START:
      return {
        ...state,
        processing: true,
      };

    case farmDetailsActionTypes.CREATE_FARM_FAILURE:
      return {
        ...state,
        error: payload,
        processing: false,
      };

    case farmDetailsActionTypes.FETCH_FARMS_START:
      return {
        ...state,
        processing: true,
      };

    case farmDetailsActionTypes.FETCH_FARMS_FAILURE:
      return {
        ...state,
        error: payload,
        processing: false,
      };

    case farmDetailsActionTypes.CREATE_FARM_SUCCESS:
      return {
        ...state,
        processing: false,
        visibleFarms: {
          ...state.visibleFarms,
          [payload?.farmId ?? payload?.id]: {
            ...payload,
            farmImage: UploadingGif,
            farmImages: [UploadingGif]
          },
        },
        selectedFarmId: payload?.farmId ?? payload?.id,
      };

    case farmDetailsActionTypes.UPDATE_FARM_SUCCESS:
      return {
        ...state,
        processing: false,
        visibleFarms: {
          ...state.visibleFarms,
          [payload?.farmId ?? payload?.id]: {
            ...payload,
            farmImage: state.visibleFarms[payload?.farmId ?? payload?.id]?.farmImage ?? UploadingGif,
            farmImages: state.visibleFarms[payload?.farmId ?? payload?.id]?.farmImages ?? [UploadingGif]
          }
        },
        selectedFarmId: payload?.farmId ?? payload?.id,
      };

    case farmDetailsActionTypes.UPDATE_FARM_IMAGES_SUCCESS:
      return {
        ...state,
        processing: false,
        visibleFarms: {
          ...state.visibleFarms,
          [payload?.farmId ?? payload?.id]: payload,
        },
        selectedFarmId: payload?.farmId ?? payload?.id,
      };

    case farmDetailsActionTypes.ADD_FARM_WORKER_SUCCESS:
      return {
        ...state,
        processing: false,
        visibleFarms: {
          ...state.visibleFarms,
          [payload.farmDetails?.id ?? payload.farmDetails?.farmId]: {
            ...state.visibleFarms[
              payload.farmDetails?.id ?? payload.farmDetails?.farmId
            ],
            invitedUsers: payload.workerDetails,
          },
        },
        selectedFarmId: payload.farmDetails?.id ?? payload.farmDetails?.farmId,
      };

    case farmDetailsActionTypes.FETCH_FARMS_SUCCESS:
      return {
        ...state,
        processing: false,
        visibleFarms: {
          ...state.visibleFarms,
          ...payload,
        },
      };

    case farmDetailsActionTypes.DELETE_FARM_SUCCESS:
      return {
        ...state,
        processing: false,
        visibleFarms: {
          ...omit(state.visibleFarms, payload.farmId),
        },
        selectedFarm: state.visibleFarms[0] ? state.visibleFarms[0]?.id : '',
      };

    default:
      return state;
  }
};

export default farmsReducer;
