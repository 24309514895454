import styled from 'styled-components';
import { Container, CardMedia, Grid } from '@material-ui/core';
import { Bold } from '../shared/shared.styles';

export const Info = styled.span`
  display: flex;
  justify-content: flex-end;
  color: #7fb986;
  font-weight: bold;
  margin: 0 10px;
  width: 50%;
`;

export const InfoBold = styled(Bold)`
  display: flex;
  justify-content: flex-start;
  width: 50%;
`;

export const SmallGray = styled.p`
  color: gray;
  font-size: 11px;
`;

export const GridSpaceEven = styled(Grid)`
  display: flex;
  justify-content: space-evenly;
  padding: 20px 0;
  margin: 20px 0;
`;

export const Media = styled(CardMedia)`
  height: 50px;
  padding-top: 56.25%;
  border-radius: 5px;
`;

export const MainContainer = styled(Container)`
  padding-top: '20px';
`;

export const GridColumnCenter = styled(Grid)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
`;

export const StoryBlock = styled(Grid)`
  padding: 20px;
  background: #fcfcfc;
  border-bottom: 1px solid #d8d8d8;
  border-top: 1px solid #dfdfdf;
`;

export const GridColumnAlign = styled(Grid)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;

export const GridShadow = styled(Grid)`
  background: white;
  padding: 20px;
  box-shadow: 1px 0px 3px 0.1px rgb(199, 199, 199);
  border-radius: 5px;
  border-top: solid 3px #7fb986;
  border-bottom: solid 1px #7fb986;
`;

export const LinkWrapper = styled(Grid)`
  border-top: 1px #ebebeb solid;
  border-bottom: 1px #ebebeb solid;
  background: #fcfcfc;
  justify-content: center;
  padding: 20px;
`;

export const LinkName = styled.div`
  border-left: 1px solid #00800033;
  border-right: 1px solid #0080003b;
  padding: 0 10px;
  margin-left: 10px;
  font-size: 18px;
  font-weight: bold;
  color: gray;
`;

export const Number = styled.div`
  color: green;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 3px;
`;
