const campActionTypes = {
  SET_PROCESSING_START: 'SET_PROCESSING_START',
  CREATE_CAMP_START: 'CREATE_CAMP_START',
  CREATE_CAMP_SUCCESS: 'CREATE_CAMP_SUCCESS',
  CREATE_CAMP_FAILURE: 'CREATE_CAMP_FAILURE',
  FETCH_CAMP_START: 'FETCH_CAMP_START',
  FETCH_CAMP_SUCCESS: 'FETCH_CAMP_SUCCESS',
  FETCH_SELECTED_CAMP_SUCCESS: 'FETCH_SELECTED_CAMP_SUCCESS',
  FETCH_CAMP_FAILURE: 'FETCH_CAMP_FAILURE',
  UPDATE_CAMP_START: 'UPDATE_CAMP_START',
  UPDATE_CAMP_SUCCESS: 'UPDATE_CAMP_SUCCESS',
  UPDATE_CAMP_IMAGES_SUCCESS: 'UPDATE_CAMP_IMAGES_SUCCESS',
  UPDATE_CAMP_FAILURE: 'UPDATE_CAMP_FAILURE',
  DELETE_CAMP_START: 'DELETE_CAMP_START',
  DELETE_CAMP_SUCCESS: 'DELETE_CAMP_SUCCESS',
  DELETE_CAMP_FAILURE: 'DELETE_CAMP_FAILURE',
  ADD_CAMP_FEED_START: 'ADD_CAMP_FEED_START',
  ADD_CAMP_FEED_SUCCESS: 'ADD_CAMP_FEED_SUCCESS',
  ADD_CAMP_FEED_FAILURE: 'ADD_CAMP_FEED_FAILURE',
  DELETE_LATEST_CAMP_FEED_START: 'DELETE_LATEST_CAMP_FEED_START',
  SELECT_CAMP: 'SELECT_CAMP',
  CLEAR_CAMPS: 'CLEAR_CAMPS',
  RESTORE_CAMP_START: 'RESTORE_CAMP_START',
  RESTORE_CAMP_SUCCESS: 'RESTORE_CAMP_SUCCESS',
  RESTORE_CAMP_FAILURE: 'RESTORE_CAMP_FAILURE',
  FETCH_ALL_CAMPS_SUCCESS: 'FETCH_ALL_CAMPS_SUCCESS',
};

export default campActionTypes;
