const farmActionTypes = {
  CLEAR_FARMS: 'CLEAR_FARMS',
  CREATE_FARM_START: 'CREATE_FARM_START',
  CREATE_FARM_SUCCESS: 'CREATE_FARM_SUCCESS',
  CREATE_FARM_FAILURE: 'CREATE_FARM_FAILURE',
  FETCH_USER_FARMS_START: 'FETCH_USER_FARMS_START',
  FETCH_FARMS_START: 'FETCH_FARMS_START',
  FETCH_FARMS_SUCCESS: 'FETCH_FARMS_SUCCESS',
  FETCH_FARMS_FAILURE: 'FETCH_FARMS_FAILURE',
  UPDATE_FARM_START: 'UPDATE_FARM_START',
  UPDATE_FARM_SUCCESS: 'UPDATE_FARM_SUCCESS',
  UPDATE_FARM_IMAGES_SUCCESS: 'UPDATE_FARM_IMAGES_SUCCESS',
  UPDATE_FARM_FAILURE: 'UPDATE_FARM_FAILURE',
  ADD_FARM_WORKER_START: 'ADD_FARM_WORKER_START',
  ADD_FARM_WORKER_SUCCESS: 'ADD_FARM_WORKER_SUCCESS',
  ADD_FARM_WORKER_FAILURE: 'ADD_FARM_WORKER_FAILURE',
  DELETE_INVITED_WORKER_START: 'DELETE_INVITED_WORKER_START',
  DELETE_FARM_WORKER_START: 'DELETE_FARM_WORKER_START',
  DELETE_FARM_START: 'DELETE_FARM_START',
  DELETE_FARM_SUCCESS: 'DELETE_FARM_SUCCESS',
  DELETE_FARM_FAILURE: 'DELETE_FARM_FAILURE',
  DELETE_WORKER_START: 'DELETE_WORKER_START',
  DELETE_WORKER_SUCCESS: 'DELETE_WORKER_SUCCESS',
  DELETE_WORKER_FAILURE: 'DELETE_WORKER_FAILURE',
  ACCEPT_INVITATION_START: 'ACCEPT_INVITATION_START',
  ACCEPT_INVITATION_SUCCESS: 'ACCEPT_INVITATION_SUCCESS',
  ACCEPT_INVITATION_FAILURE: 'ACCEPT_INVITATION_FAILURE',

  SELECT_FARM: 'SELECT_FARM',
};

export default farmActionTypes;
