import { all, call, put, takeLatest } from 'redux-saga/effects';
import { firestore } from '../../firebase/firebase.utils';
import orderActionTypes from './order.action-types';
import {
  clearOrders,
  fetchAgentOrdersFailure,
  fetchAgentOrdersSuccess,
  orderCreateFailed,
  orderCreateSuccess,
  orderDeleteFailed,
  orderDeleteSuccess,
  orderFetchFailed,
  orderFetchSuccess,
  updateOrderFailure,
  updateOrderSuccess,
} from './order.actions';
import { convertCollectionsSnapshotToMap } from '../../firebase/firebase.functions';
import UserActionTypes from '../users/user.types';
import { handleError } from '../../utils/errors/errorHandler';

export function* createNewOrderAsync(action) {
  const orderInfo = action.payload;
  if (
    orderInfo.id?.length > 6 ||
    orderInfo.paymentSuccess ||
    orderInfo.paymentFailure
  ) {
    try {
      const orderRef = firestore.doc(`orders/${orderInfo?.id}`);
      const snapShot = yield orderRef.get();
      if (snapShot.exists) {
        yield orderRef.update({ ...orderInfo, updatedDate: new Date() });
        yield put(orderCreateSuccess({ orderId: snapShot.id, ...orderInfo }));
      }
    } catch (error) {
      yield put(orderCreateFailed(error));
    }
  } else
    try {
      const collectionRef = firestore.collection('orders');
      const addedOrderRef = yield collectionRef.add({
        ...orderInfo,
        createdDate: new Date(),
      });
      yield put(
        orderCreateSuccess({ orderId: addedOrderRef.id, ...orderInfo })
      );
    } catch (error) {
      handleError(error);
      yield put(orderCreateFailed(error));
    }
}

export function* fetchUserOrders(action) {
  const user = action.payload;
  const userId = user.id ?? user.uid;
  if (!userId) return;
  try {
    const collectionRef = firestore
      .collection('orders')
      .where('userId', '==', userId);
    const snapshot = yield collectionRef.get();
    const collectionsMap = yield call(
      convertCollectionsSnapshotToMap,
      snapshot
    );
    yield put(orderFetchSuccess(collectionsMap));
  } catch (error) {
    handleError(error);
    yield put(orderFetchFailed(error.message));
  }
}

export function* fetchAgentOrdersAsync({ payload }) {
  try {
    const collectionRef = firestore
      .collection('orders')
      .where('agentCode', '==', payload);
    const snapshot = yield collectionRef.get();
    const collectionsMap = yield call(
      convertCollectionsSnapshotToMap,
      snapshot
    );
    yield put(fetchAgentOrdersSuccess(collectionsMap));
  } catch (error) {
    handleError(error);
    yield put(fetchAgentOrdersFailure(error));
  }
}

export function* deleteOrderAsync(action) {
  const orderDetails = action.payload;
  if (!orderDetails) return;
  try {
    const orderRef = firestore.doc(`orders/${orderDetails.id}`);
    const updatedOrderRef = yield orderRef.get();
    if (updatedOrderRef.exists) {
      yield put(
        orderDeleteSuccess({
          orderId: orderDetails.id,
          ...orderDetails,
        })
      );
      yield orderRef.delete();
    }
  } catch (error) {
    handleError(error);
    yield put(orderDeleteFailed(error));
  }
}

export function* updateOrderAsync(action) {
  const order = action.payload;
  if (!order) return;
  try {
    const orderRef = firestore.doc(`orders/${order?.id ?? order?.orderId}`);
    const updatedOrderRef = yield orderRef.get();
    if (updatedOrderRef.exists) {
      const updatedOrderDoc = yield updatedOrderRef.data();
      const updatedOrderDetails = { ...order };
      delete updatedOrderDetails.id;
      yield orderRef.update(updatedOrderDetails);
      yield put(
        updateOrderSuccess({
          id: order?.id,
          orderId: order?.id ?? order?.orderId,
          ...updatedOrderDoc,
          ...order,
        })
      );
    } else {
      yield put(orderDeleteSuccess(order));
    }
  } catch (error) {
    handleError(error);
    yield put(updateOrderFailure(error));
  }
}

export function* onOrderDelete() {
  yield takeLatest(orderActionTypes.START_ORDER_DELETE, deleteOrderAsync);
}

export function* onOrderUploadStart() {
  yield takeLatest(orderActionTypes.START_ORDER_CREATE, createNewOrderAsync);
}

export function* onUpdateStart() {
  yield takeLatest(orderActionTypes.UPDATE_ORDER_START, updateOrderAsync);
}

export function* onUserSelect() {
  yield takeLatest(UserActionTypes.SIGN_IN_SUCCESS, fetchUserOrders);
}

export function* clearOrdersData() {
  yield put(clearOrders());
}

export function* onUserSignout() {
  yield takeLatest(UserActionTypes.SIGN_OUT_SUCCESS, clearOrdersData);
}

export function* onAgentOrdersFetch() {
  yield takeLatest(
    orderActionTypes.FETCH_AGENT_ORDERS_START,
    fetchAgentOrdersAsync
  );
}

export function* orderSagas() {
  yield all([
    call(onOrderUploadStart),
    call(onUserSelect),
    call(onOrderDelete),
    call(onUpdateStart),
    call(onUserSignout),
    call(onAgentOrdersFetch),
  ]);
}
