import { all, call, put, takeLatest } from 'redux-saga/effects';
import { firestore } from '../../firebase/firebase.utils';
import { handleError } from '../../utils/errors/errorHandler';
import promptActionTypes from './prompt.action-types';
import {
  createPromptSuccess,
  createPromptFailure,
} from './prompt.actions';

export function* createNewPromptAsync(action) {
  debugger
  const promptDetails = action.payload;
  if (!promptDetails) return;
  try {
    const collectionRef = firestore.collection('prompts');
    const addedPromptRef = yield collectionRef.add(promptDetails);
    yield put(
      createPromptSuccess({
        promptId: addedPromptRef.id,
        ...promptDetails,
      })
    );
  } catch (error) {
    handleError(error, action);
    yield put(createPromptFailure(error));
  }
}

export function* onPromptCreate() {
  yield takeLatest(
    promptActionTypes.CREATE_PROMPT_START,
    createNewPromptAsync
  );
}

export function* promptSagas() {
  yield all([call(onPromptCreate)]);
}
