const eventActionTypes = {
    CREATE_EVENT_START: 'CREATE_EVENT_START',
    CREATE_EVENT_SUCCESS: 'CREATE_EVENT_SUCCESS',
    CREATE_EVENT_FAILURE: 'CREATE_EVENT_FAILURE',
    FETCH_EVENTS_START: 'FETCH_EVENT_START',
    FETCH_DEVICE_EVENTS_START: 'FETCH_DEVICE_EVENTS_START',
    FETCH_ANIMAL_EVENTS_START: 'FETCH_ANIMAL_EVENTS_START',
    FETCH_CAMP_EVENTS_START: 'FETCH_CAMP_EVENTS_START',
    FETCH_FARM_EVENTS_START: 'FETCH_FARM_EVENT_START',
    FETCH_PUBLIC_EVENTS_START: 'FETCH_PUBLIC_EVENTS_START',
    FETCH_EVENTS_SUCCESS: 'FETCH_EVENT_SUCCESS',
    FETCH_EVENTS_FAILURE: 'FETCH_EVENT_FAILURE',
    SELECT_EVENT: 'SELECT_EVENT',
    CLEAR_EVENTS: 'CLEAR_EVENTS'
}

export default eventActionTypes;