import React from 'react';
import './swipe-up-button.styles.scss'
import { Button } from '@material-ui/core';

const SwipeUpButton = ({callback}) =>{

    return(
        <div className='swipe-up-button' onClick={callback}>
          <Button className='menu-button' onClick={callback}><span className="button-content">MENU</span></Button>
        </div>
    )

}

export default SwipeUpButton;