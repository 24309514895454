import { Button } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { soldAnimalsAsArray } from '../../../redux/animals/animal.selectors';
import { signOutStart } from '../../../redux/users/user.actions';
import { selectCurrentUser } from '../../../redux/users/user.selectors';
import CustomButton from '../../shared/custom-button/custom-button.component';
import ProfileAgent from '../profile-agent/profile-agent.component';
import UserDetails from '../profile-details/user-details.component';
import './profile-info.styles.scss';

function ProfileInfo({ history, user, signOut, soldAnimals }) {
  const logout = () => {
    signOut();
    history.push('/profile/logout');
  };

  return (
    <div>
      <UserDetails user={user} />
      <ProfileAgent />
      <Button
        onClick={() =>
          (window.location.href =
            "mailto:Accounts@ANMLFARM.com?subject=Supplier%20Request&amp;body=Good%20day,%20I'm%20interested%20in%20becoming%20a%20supplier!")
        }
      >
        Become a Seller
      </Button>
      <CustomButton onClick={() => history.push(`/profile/edit/${user.id}`)}>
        EDIT PROFILE
      </CustomButton>
      <CustomButton onClick={logout}>LOGOUT</CustomButton>
    </div>
  );
}

const mapStateToProps = createStructuredSelector({
  user: selectCurrentUser,
  soldAnimals: soldAnimalsAsArray,
});

const mapDispatchToProps = (dispatch) => ({
  signOut: () => dispatch(signOutStart()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ProfileInfo));
