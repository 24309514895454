import { omit } from "lodash";
import notificationActionTypes from "./notification.action-types";

const INITIAL_STATE = {
    error: undefined,
    processing: false,
    selectedNotification: '',
    channelEventListener: null,
    messageListener: null,
    animalNotifications: {
    },
    campNotifications: {
    },
    farmNotifications: {
    },
    followingNotifications: {
    },
    visibleNotifications: {
        // 1: {
        //     title: 'THANK YOU!!',
        //     body: 'Because of our early adopters and word-of-mouth, we have gained a lot of new users! To say thank you for this, we have extended our first loyal customers trial period to the 1st of November! Congratulations and, again, thank you very much! Have a blessed month on us!',
        //     image: 'https://www.gifcen.com/wp-content/uploads/2021/05/celebration-gif-19.gif'
        // },
        1: {
            title: 'Welcome!',
            htmlLink: "https://www.anmlfarm.com/static/WELCOME.html"
        },
        // 1: {
        //     title: 'How To?',
        //     htmlLink: "https://www.anmlfarm.com/static/HOWTO.html"
        // },
        // 3 : {
        //     title: 'Beginners Kursus',
        //     body: `Hierdie is 'n Beginner Meatmaster keurders-kursus en word onder die beskerming van die Meatmaster Telersgenootskap aangebied. Dit sal baie leersaam wees vir enige meatmaster boer`,
        //     // htmlBody: `<img style='padding:20' src='/assets/events/NicoBeginners2.jfif'>`,
        //     image: 'https://firebasestorage.googleapis.com/v0/b/anmlfarm-web.appspot.com/o/image%2Fjfif%2FNicoBeginners2.jfif?alt=media&token=483e6f0f-d4f6-4d5d-96b6-062b5c220550'
        // },
        2 : {
            title: 'Thank You for logging in today',
            body: 'Click on MENU below to create a farm or buy something, perhaps. I hope you have an amazing day!',
            image: '/android-chrome-512x512.png'
        },
        3: {
            title: 'Feedback',
            body: 'Please give us feedback on how we can improve your experience on ANML FARM. We would love to hear from you!',
            image: 'https://www.impactbnd.com/hubfs/8%20smart%20ways%20to%20collect%20customer%20feedback%20%5Binfographic%5D.jpg',
            onClickRedirect: '/feedback'
        }
    }
    
}

const notificationsReducer = (state = INITIAL_STATE, action) => {
    const {type, payload} = action;
    switch(type) {

        case notificationActionTypes.SELECT_NOTIFICATION:
            return {
                ...state,
                selectedNotification: payload
            }

        case notificationActionTypes.CLEAR_NOTIFICATIONS:
            return {
                ...state,
                selectedNotification: '',
                visibleNotifications: {}
            }

        case notificationActionTypes.DELETE_NOTIFICATION_START:
            return {
                ...state,
                processing: false,
                visibleNotifications: {
                    ...omit(state.visibleNotifications, payload.id),
                }
            };

        case notificationActionTypes.CREATE_NOTIFICATION_START:
            return {
                ...state,
                processing: true
            }

        case notificationActionTypes.CREATE_NOTIFICATION_FAILURE:
            return {
                ...state,
                error: payload,
                processing: false
            }

        case notificationActionTypes.FETCH_NOTIFICATIONS_START:
            return {
                ...state,
                processing: true
            }

        case notificationActionTypes.FETCH_NOTIFICATIONS_FAILURE:
            return {
                ...state,
                error: payload,
                processing: false
            }

        case notificationActionTypes.CREATE_NOTIFICATION_SUCCESS:
            return {
                ...state,
                processing: false,
                visibleNotifications: {
                    ...state.visibleNotifications,
                    [payload.notificationId]: payload
                },
                selectedNotification: payload?.notificationId ?? state.selectedNotification
            }

        case notificationActionTypes.FETCH_NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                processing: false,
                visibleNotifications: {
                    ...state.visibleNotifications,
                    ...payload
                }
            }

        default:
            return state;
    }
}

export default notificationsReducer;