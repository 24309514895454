import React, { useState } from 'react';
import './menu-drawer.styles.scss';

import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import DrawerListItem from '../drawer-list-item/drawer-list-item.component';

import home from './images/home.png';
import library from './images/library.png';
import profile from './images/profile.png';
import animal from './images/animal.png';
import community from './images/community.png';
import report from './images/report.png';
import cart from './images/cart.png';
import mail from './images/mail.png';
import inspect from './images/inspect.png';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import SwipeUpButton from '../swipe-up-button/swipe-up-button.component';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  selectAdmin,
  selectCurrentUser,
} from '../../../redux/users/user.selectors';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

const listItems = [
  {
    id: 1,
    text: 'Home',
    linkToLocal: `/home`,
    iconImageUrl: home,
    admin: false,
  },
  {
    id: 1,
    text: 'Assistant',
    linkToLocal: `/annie-mel`,
    iconImageUrl: community,
    admin: false,
  },
  {
    id: 2,
    text: 'Library',
    linkToLocal: `/library`,
    iconImageUrl: library,
    admin: false,
  },
  {
    id: 3,
    text: 'My Farms',
    linkToLocal: `/farms/`,
    iconImageUrl: animal,
    admin: false,
  },
  {
    id: 4,
    text: 'Market',
    linkToLocal: '/products',
    iconImageUrl: cart,
    admin: false,
  },
  {
    id: 5,
    text: 'My Profile',
    linkToLocal: '/profile/info',
    iconImageUrl: profile,
  },
  {
    id: 6,
    text: 'Contact Us',
    linkToLocal: '/feedback',
    iconImageUrl: mail,
    admin: false,
  },
  {
    id: 7,
    text: 'Inspection',
    linkToLocal: '/inspections',
    iconImageUrl: inspect,
    admin: true,
  },
  {
    id: 8,
    text: 'Dashboard',
    linkToLocal: '/dashboard',
    iconImageUrl: report,
    admin: true,
  },
];

const MenuDrawer = ({ admin, user }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = () => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setDrawerOpen(true);
  };
  const showListItems = admin
    ? listItems
    : listItems.filter((item) => !item.admin);

  return (
    <div className="menu-drawer">
      <div className="drawer-button">
        <SwipeUpButton callback={toggleDrawer(true)} />
      </div>
      <Drawer
        anchor="bottom"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
      >
        <div
          className="drawer-list"
          role="presentation"
          style={{
            width: 'auto',
          }}
        >
          <ClickAwayListener onClickAway={() => setDrawerOpen(false)}>
            <List>
              {showListItems.length > 0 && user ? (
                showListItems.map(({ id, admin, ...otherInfo }) => (
                  <DrawerListItem
                    key={id}
                    id={id}
                    admin={admin}
                    {...otherInfo}
                  />
                ))
              ) : (
                <DrawerListItem
                  text={'Sign In'}
                  iconImageUrl={profile}
                  linkToLocal={'/profile/signin'}
                />
              )}
            </List>
          </ClickAwayListener>
        </div>
      </Drawer>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  admin: selectAdmin,
  user: selectCurrentUser,
});

export default connect(mapStateToProps)(MenuDrawer);
