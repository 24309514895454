import { createSelector } from 'reselect';
import { convertObjectToArray } from '../../utils/array/mapper';

export const selectOrders = (state) => state.orders;

export const selectCurrentOrders = createSelector<any, any>(
  [selectOrders],
  (o) => o.orders
);

export const selectAllOrdersAsArray = createSelector<any, any>(
  [selectCurrentOrders],
  (orders) => convertObjectToArray(orders)
);

export const selectAgentOrders = createSelector<any, any>(
  [selectOrders],
  (orders) => orders.agentOrders
);

export const selectCurrentAgentOrdersAsArray = createSelector<any, any>(
  [selectAgentOrders],
  (orders) => convertObjectToArray(orders)
);

export const selectHistoricOrdersAsArray = createSelector<any, any>(
  [selectAllOrdersAsArray],
  (orders) =>
    orders.filter(
      (o) => o.paymentSuccess === true || o.paymentSuccess === false
    )
);

export const selectActiveOrdersAsArray = createSelector<any, any>(
  [selectAllOrdersAsArray],
  (orders) =>
    orders.filter(
      (o) => o.paymentSuccess === null || o.paymentSuccess === undefined
    )
);
