const orderActionTypes = {
  CLEAR_ORDERS: 'CLEAR_ORDERS',
  START_ORDER_FETCH: 'START_ORDER_FETCH',
  ORDER_FETCH_SUCCESS: 'ORDER_FETCH_SUCCESS',
  ORDER_FETCH_FAILED: 'ORDER_FETCH_FAILED',

  START_ORDER_CREATE: 'START_ORDER_CREATE',
  ORDER_CREATE_SUCCESS: 'ORDER_CREATE_SUCCESS',
  ORDER_CREATE_FAILED: 'ORDER_CREATE_FAILED',

  START_ORDER_DELETE: 'START_ORDER_DELETE',
  ORDER_DELETE_SUCCESS: 'ORDER_DELETE_SUCCESS',
  ORDER_DELETE_FAILED: 'ORDER_DELETE_FAILED',

  UPDATE_ORDER_START: 'UPDATE_ORDER_START',
  UPDATE_ORDER_SUCCESS: 'UPDATE_ORDER_SUCCESS',
  UPDATE_ORDER_FAILURE: 'UPDATE_ORDER_FAILURE',

  FETCH_AGENT_ORDERS_START: 'FETCH_AGENT_ORDERS_START',
  FETCH_AGENT_ORDERS_SUCCESS: 'FETCH_AGENT_ORDERS_SUCCESS',
  FETCH_AGENT_ORDERS_FAILURE: 'FETCH_AGENT_ORDERS_FAILURE',
};

export default orderActionTypes;
