import React from "react";
import { Button } from "@material-ui/core";

interface SubmitProps {
  children?: any;
  submit?: any;
  [key: string]: any;
}

const ButtonSubmit = ({ children, submit, ...otherProps }: SubmitProps) => {
  const handleSubmit = (evt) => {
    if (submit) submit();
  };

  return (
    <Button
      style={{ width: '100%' }}
      variant="contained"
      color="primary"
      type="submit"
      onClick={handleSubmit}
      {...otherProps}
    >
      {children}
    </Button>
  );
};

export default ButtonSubmit;
