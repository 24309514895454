import eventActionTypes from "./event.action-types";

const INITIAL_STATE = {
    error: undefined,
    processing: false,
    selectedEvent: '',
    visibleEvents: {
    }
    
}

const eventsReducer = (state = INITIAL_STATE, action) => {
    const {type, payload} = action;
    switch(type) {

        case eventActionTypes.SELECT_EVENT:
            return {
                ...state,
                selectedEvent: payload
            }

        case eventActionTypes.CLEAR_EVENTS:
            return INITIAL_STATE;

        case eventActionTypes.CREATE_EVENT_START:
            return {
                ...state,
                processing: true
            }

        case eventActionTypes.CREATE_EVENT_FAILURE:
            return {
                ...state,
                error: payload,
                processing: false
            }

        case eventActionTypes.FETCH_EVENTS_START:
            return {
                ...state,
                processing: true
            }

        case eventActionTypes.FETCH_EVENTS_FAILURE:
            return {
                ...state,
                error: payload,
                processing: false
            }

        case eventActionTypes.CREATE_EVENT_SUCCESS:
            return {
                ...state,
                processing: false,
                visibleEvents: {
                    ...state.visibleEvents,
                    [payload.eventId]: payload
                },
                selectedEvent: payload?.eventId ?? state.selectedEvent
            }

        case eventActionTypes.FETCH_EVENTS_SUCCESS:
            return {
                ...state,
                processing: false,
                visibleEvents: {
                    ...state.visibleEvents,
                    ...payload
                }
            }

        default:
            return state;
    }
}

export default eventsReducer;