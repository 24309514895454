import React from 'react';

import MainPage from '../main-page/main-page.component';
import SignIn from '../../components/profile/credential-management/sign-in/sign-in.component';
import Signup from '../../components/profile/credential-management/sign-up/sign-up.component';
import ForgotPassword from '../../components/profile/credential-management/forgot-password/forgot-password.component';
import { Route } from 'react-router';
import ProfileInfo from '../../components/profile/profile-info/profile-info.component';
import Onboarding from '../../page-collections/onboarding/onboarding.component';
import ProfileEdit from '../../components/profile/profile-edit/profile-edit.component';
import PrivacyPolicy from '../../components/agreements/privacy-policy/privacy-policy.component';
import TermsAndConditions from '../../components/agreements/terms-and-conditions/terms-and-conditions.component';
import ProfileLogout from '../../components/profile/profile-logout/profile-logout.component';

const ProfilePage = ({ match }) => {
  return (
    <MainPage>
      <Route path={`${match.path}/signin`} component={SignIn} />
      <Route path={`${match.path}/signup`} component={Signup} />
      <Route
        path={`${match.path}/forgot-password`}
        component={ForgotPassword}
      />
      <Route path={`${match.path}/onboarding`} component={Onboarding} />
      <Route path={`${match.path}/logout`} component={ProfileLogout} />
      {/* <Route path={`${match.path}/subscription`} component={AnimalFilterDetails}/> */}
      <Route
        path={`${match.path}/terms-and-conditions`}
        component={TermsAndConditions}
      />
      <Route path={`${match.path}/privacy-policy`} component={PrivacyPolicy} />
      {/* <Route path={`${match.path}/edit`} component={AnimalFilterDetails}/> */}
      {/* <Route path={`${match.path}`} component={ProfileInfo} /> */}
      <Route path={`${match.path}/info`} component={ProfileInfo} />
      <Route path={`${match.path}/edit/:userId`} component={ProfileEdit} />
    </MainPage>
  );
};

export default ProfilePage;
