import { all, call, put, takeLatest } from "redux-saga/effects";
import { firestore } from "../../firebase/firebase.utils";
import { handleError } from "../../utils/errors/errorHandler";
import imageActionTypes from "../images/image.action-types";
import userActionTypes from "../users/user.types";
import errorActionTypes from "./error.action-types";
import {
  createErrorSuccess,
  createErrorFailure,
  clearErrors,
} from "./error.actions";

export function* createNewErrorAsync(action) {
  const errorDetails = action.payload;
  if (!errorDetails) return;
  try {
    const collectionRef = firestore.collection("errors");
    const addedErrorRef = yield collectionRef.add({
      timestamp: new Date(),
      errorCode: errorDetails.code ?? 'ERROR',
      errorMessage: errorDetails.error?.message?.toString().substr(0, 150)??'ERROR',
      errorStack: errorDetails.error?.stack?.toString().substr(0, 150)??'ERROR',
      errorUserId: errorDetails.user?.uid??'ERROR',
      errorUserName: errorDetails.user?.displayName??'ERROR',
      errorUserEmail: errorDetails.user?.email.toLowerCase()??'ERROR',
    });
    handleError(errorDetails);
    yield put(
      createErrorSuccess({ errorId: addedErrorRef.id, ...errorDetails })
    );
  } catch (error) {
    handleError(error);
    yield put(createErrorFailure(error));
  }
}

export function* onErrorCreate() {
  yield takeLatest(errorActionTypes.LOG_ERROR_START, createNewErrorAsync);
}

export function* clearErrorsData() {
  yield put(clearErrors());
}

export function* onUserSignout() {
  yield takeLatest(userActionTypes.SIGN_OUT_SUCCESS, clearErrorsData);
}


export function* onImageFailure() {
  yield takeLatest(imageActionTypes.IMAGE_UPLOAD_FAILED, createNewErrorAsync);
}

export function* errorSagas() {
  yield all([
    call(onErrorCreate), 
    call(onUserSignout),
    call(onImageFailure)
  ]);
}
