const documentActionTypes = {
  CLEAR_DOCUMENTS: 'CLEAR_DOCUMENTS',

  DOCUMENT_FETCH_SUCCESS: 'DOCUMENT_FETCH_SUCCESS',
  DOCUMENT_FETCH_FAILED: 'DOCUMENT_FETCH_FAILED',

  DOCUMENT_ANIMAL_FETCH_START: 'DOCUMENT_ANIMAL_FETCH_START',
  DOCUMENT_ANIMAL_FETCH_SUCCESS: 'DOCUMENT_ANIMAL_FETCH_SUCCESS',
  DOCUMENT_ANIMAL_FETCH_FAILURE: 'DOCUMENT_ANIMAL_FETCH_FAILURE',

  DOCUMENT_FARM_FETCH_START: 'DOCUMENT_FARM_FETCH_START',
  DOCUMENT_FARM_FETCH_SUCCESS: 'DOCUMENT_FARM_FETCH_SUCCESS',
  DOCUMENT_FARM_FETCH_FAILURE: 'DOCUMENT_FARM_FETCH_FAILURE',

  START_DOCUMENT_UPLOAD: 'START_DOCUMENT_UPLOAD',
  DOCUMENT_UPLOAD_SUCCESS: 'DOCUMENT_UPLOAD_SUCCESS',
  DOCUMENT_UPLOAD_FAILED: 'DOCUMENT_UPLOAD_FAILED',

  DELETE_DOCUMENT_START: 'DELETE_DOCUMENT_START',
  DELETE_DOCUMENT_SUCCESS: 'DELETE_DOCUMENT_SUCCESS',
  DELETE_DOCUMENT_FAILURE: 'DELETE_DOCUMENT_FAILURE',
};

export default documentActionTypes;
