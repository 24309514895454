import React, { useEffect, useMemo } from 'react';
import {
  googleSignInStart,
  emailSignUpStart,
} from '../../../../redux/users/user.actions';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { selectCurrentUser } from '../../../../redux/users/user.selectors';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Box, Container, Grid, Typography, Button } from '@material-ui/core';
import TextField from '../../../shared/react-hook-form/text-field/text-field';
import SubmitButton from '../../../shared/react-hook-form/submit-button/ButtonSubmit';
import {
  GriDisplayFlexEnd,
  GridPadding10,
  MainColor,
  Bold,
} from '../../../../styles/shared/shared.styles';
import { Icon } from '@iconify/react';
import facebookFill from '@iconify/icons-eva/facebook-fill';
import Divider from '../../../shared/divider/divider.component';
import { useForm } from "react-hook-form";
import GoogleImage from '../google-logo.jpg';

const Signup = ({
  user,
  googleSignInStart,
  signUp,
  facebookSignInStart,
  history,
  error,
}) => {
  
  const { register, handleSubmit } = useForm();

  const isFacebookApp = useMemo(() => {
    var ua = navigator.userAgent || navigator.vendor || window['opera'];
    return (ua.indexOf("FBAN") > -1) || (ua.indexOf("FBAV") > -1);
}, [])

  useEffect(() => {
    if (user != null) {
      history.push('/');
    }
  }, [user, history]);

  const onSubmit = (values) => {
    // emailSignInStart(values);
    if (values.password !== values.confirmPassword) {
      alert("Passwords don't match");
      return;
    }
    signUp({
      email: values.email,
      password: values.password,
      displayName: values.displayName,
    });
    history.push('/profile/onboarding');
  };

  return (
    <Box mt={6}>
      <Container maxWidth="sm">
        <GridPadding10>
          <GriDisplayFlexEnd>
            Already have an account? &nbsp;
            <Link underline="none" variant="subtitle2" to="/profile/signin">
              <Bold>
                <MainColor>Sign in</MainColor>
              </Bold>
            </Link>
          </GriDisplayFlexEnd>
        </GridPadding10>
        {user?.error?.message ?? error?.message}
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="h4" gutterBottom>
            Sign Up
          </Typography>
        </Box>
        <Box py={2}>
          <Grid container>
          {!isFacebookApp && <Grid item xs={6}>
              <Box mr={1}>
                <Button
                  fullWidth
                  size="large"
                  color="inherit"
                  variant="outlined"
                  onClick={googleSignInStart}
                >
                  <img style={{ height: '24px' }} src={GoogleImage} alt="" />
                </Button>
              </Box>
            </Grid>}
            <Grid item xs={6}>
              <Box ml={1}>
                <Button
                  fullWidth
                  size="large"
                  color="inherit"
                  variant="outlined"
                  disabled
                  onClick={facebookSignInStart}
                >
                  <Icon icon={facebookFill} color="#1877F2" height={24} />
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Divider>OR</Divider>
          <form onSubmit={handleSubmit(onSubmit)}>
            <span>Sign up with your email and password</span>
            <Box m={1}>
              <Box py={1}>
                <TextField label="Display Name" name="displayName" register={register}/>
              </Box>
              <Box py={1}>
                <TextField label="Email Address" name="email" type="email" register={register}/>
              </Box>
              <Box py={1}>
                <TextField label="Password" name="password" type="password" register={register}/>
              </Box>
              <Box py={1}>
                <TextField
                  label="Confirm Password"
                  name="confirmPassword"
                  type="password"
                  register={register}
                />
              </Box>
              <Box py={2}>
                <SubmitButton type="submit">Sign Up</SubmitButton>
              </Box>
            </Box>
          </form>
      </Container>
    </Box>
  );
};

const mapStateToProps = createStructuredSelector({
  user: selectCurrentUser,
});

const mapDispatchToProps = (dispatch) => ({
  googleSignInStart: () => dispatch(googleSignInStart()),
  signUp: (values) => dispatch(emailSignUpStart(values)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Signup));
