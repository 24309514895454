import { lazy, Suspense, useEffect } from 'react';
import './App.css';
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { checkUserSession } from './redux/users/user.actions';
import { createStructuredSelector } from 'reselect';
import { selectCurrentUser } from './redux/users/user.selectors';
import LoadingFullScreen from './components/shared/loading/loading-fullscreen.component';
import Maintenance from './components/maintenance/maintenance.component';


// import HomePage from './pages/home-page/home-page.component';
// import EntryPage from './pages/entry-page/entry-page.component';
// import Onboarding from './page-collections/onboarding/onboarding.component';
// import LibraryPage from './pages/library-page/library-page.component';
// import AnimalPage from './pages/animal-page/animal-page.component';
// import CampPage from './pages/camp-page/camp-page.component';
// import FarmPage from './pages/farm-page/farm-page.component';
// import ToolsPage from './pages/tools-page/tools-page.component';
// import ProductPage from './pages/product-page/product-page.component';
import ProfilePage from './pages/profile-page/profile-page.component';
import SignInAndSignUpPage from './pages/sign-in-and-sign-up/sign-in-and-sign-up.component';
// import FeedbackPage from './pages/feedback-page/feedback-page.component';
// import DashboardPage from './pages/dashboard-page/dashboard-page.component';

const maintenance = false;

const HomePage = lazy(() => import('./pages/home-page/home-page.component'));
const AssistantPage = lazy(() => import('./pages/assistant-page/assistant-page.component'));
const EntryPage = lazy(() => import('./pages/entry-page/entry-page.component'));
const Onboarding = lazy(() =>
  import('./page-collections/onboarding/onboarding.component')
);
const LibraryPage = lazy(() =>
  import('./pages/library-page/library-page.component')
);
const AnimalPage = lazy(() =>
  import('./pages/animal-page/animal-page.component')
);
const CampPage = lazy(() => import('./pages/camp-page/camp-page.component'));
const FarmPage = lazy(() => import('./pages/farm-page/farm-page.component'));
const ToolsPage = lazy(() => import('./pages/tools-page/tools-page.component'));
const ProductPage = lazy(() =>
  import('./pages/product-page/product-page.component')
);
// const ProfilePage = lazy(() =>
//   import('./pages/profile-page/profile-page.component')
// );
// const SignInAndSignUpPage = lazy(() =>
//   import('./pages/sign-in-and-sign-up/sign-in-and-sign-up.component')
// );
const FeedbackPage = lazy(() =>
  import('./pages/feedback-page/feedback-page.component')
);
const DashboardPage = lazy(() =>
  import('./pages/dashboard-page/dashboard-page.component')
);
const InspectionPage = lazy(() =>
  import('./pages/inspection-page/inspection-page.component')
);

const App = ({ currentUser, checkUserSession }) => {
  useEffect(() => {
    checkUserSession();
  }, [checkUserSession]);

  return (
    <div className="app">
      <Suspense fallback={<LoadingFullScreen />}>
        {maintenance && <Maintenance />}
        {!maintenance && (
          <Switch>
            <Route
              path="/signin"
              render={() =>
                currentUser == null ? (
                  <SignInAndSignUpPage />
                ) : (
                  <Redirect to="/" />
                )
              }
            />
            <Route path="/products" component={ProductPage} />
            <Route path="/tools" component={ToolsPage} />
            <Route path="/profile" component={ProfilePage} />
            <Route path="/library" component={LibraryPage} />
            <Route path="/farms" component={FarmPage} />
            <Route path="/camps" component={CampPage} />
            <Route path="/animals" component={AnimalPage} />
            <Route path="/onboarding" component={Onboarding} />
            <Route path="/home" component={HomePage} />
            <Route path="/annie-mel" component={AssistantPage} />
            <Route path="/anniemel" component={AssistantPage} />
            <Route path="/assistant" component={AssistantPage} />
            <Route path="/feedback" component={FeedbackPage} />
            <Route path="/dashboard" component={DashboardPage} />
            <Route path="/inspections" component={InspectionPage} />
            <Route path="/" component={EntryPage} />
          </Switch>
        )}
      </Suspense>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
});

const mapDispacthToProps = (dispatch) => ({
  checkUserSession: () => dispatch(checkUserSession()),
});

export default connect(mapStateToProps, mapDispacthToProps)(App);
