import breedActionTypes from './breed.action-types'

export const clearBreeds = () => ({
    type: breedActionTypes.CLEAR_BREEDS
})

export const startBreedCreate = breedInfo => ({
    type: breedActionTypes.START_BREED_CREATE,
    payload: breedInfo
})

export const breedCreateSuccess = breedInfo => ({
    type: breedActionTypes.BREED_CREATE_SUCCESS,
    payload: breedInfo
})

export const breedCreateFailed = error => ({
    type: breedActionTypes.BREED_CREATE_FAILED,
    payload: error
})

export const startBreedUpdate = breedInfo => ({
    type: breedActionTypes.START_BREED_UPDATE,
    payload: breedInfo
})

export const breedUpdateSuccess = breedInfo => ({
    type: breedActionTypes.BREED_UPDATE_SUCCESS,
    payload: breedInfo
})

export const breedUpdateFailed = error => ({
    type: breedActionTypes.BREED_UPDATE_FAILED,
    payload: error
})

export const startBreedFetch = () => ({
    type: breedActionTypes.START_BREED_FETCH
})

export const breedFetchSuccess = breedInfo => ({
    type: breedActionTypes.BREED_FETCH_SUCCESS,
    payload: breedInfo
})

export const breedFetchFailed = error => ({
    type: breedActionTypes.BREED_FETCH_FAILED,
    payload: error
})

export const deleteBreedStart = (breedInfo) => ({
    type: breedActionTypes.DELETE_BREED_START,
    payload: breedInfo,
  });
  
  export const deleteBreedSuccess = (breedInfo) => ({
    type: breedActionTypes.DELETE_BREED_SUCCESS,
    payload: breedInfo,
  });
  
  export const deleteBreedFailure = (error) => ({
    type: breedActionTypes.DELETE_BREED_FAILURE,
    payload: error,
  });