import { createSelector } from 'reselect';
import { convertObjectToArray } from '../../utils/array/mapper';
import {
  calculateCurrentAge,
  convertStringToDateTimeSeconds,
} from '../../utils/date/calculations';
import {
  selectCurrentCamp,
  selectVisibleCampsAsArray,
} from '../camps/camp.selectors';
import { selectVisibleFarmsAsArray } from '../farms/farm.selectors';
import { selectCurrentUser } from '../users/user.selectors';

export const animalSelector = (state) => state.animals;

export const selectSelectedAnimalId = createSelector<any, any>(
  [animalSelector],
  (animals) => animals.selectedAnimalId
);

export const farmAnimalsSelector = createSelector<any, any>(
  [animalSelector],
  (animalDetails) => animalDetails.visibleAnimals
);

export const farmAnimalsAsArray = createSelector<any, any>(
  [farmAnimalsSelector],
  (animals) => convertObjectToArray(animals)
);

export const farmAnimalsAliveSelector = createSelector<any, any>(
  [farmAnimalsAsArray],
  (animalDetails) => animalDetails ? animalDetails?.filter((a) => !a.animalEnd && !a.deleted) : []
);

export const animalsForSale = createSelector<any, any>(
  [animalSelector],
  (animalDetails) => animalDetails.animalsForSale
);

export const soldAnimalsSelector = createSelector<any, any>(
  [animalSelector],
  (animalDetails) => animalDetails.soldAnimals
);

export const purchasedAnimalsSelector = createSelector<any, any>(
  [animalSelector],
  (animalDetails) => animalDetails.purchasedAnimals
);

export const animalsForSaleAsArray = createSelector<any, any>([animalsForSale], (animals) =>
  convertObjectToArray(animals)
);

export const soldAnimalsAsArray = createSelector<any, any>(
  [soldAnimalsSelector],
  (animals) => convertObjectToArray(animals)
);

export const purchasedAnimalsAsArray = createSelector<any, any>(
  [purchasedAnimalsSelector],
  (animals) => convertObjectToArray(animals)
);

export const animalLoading = createSelector<any, any>(
  [animalSelector],
  (animalDetails) => animalDetails.processing ?? false
);

export const selectCurrentSelectedAnimal = createSelector<any, any>(
  [farmAnimalsSelector, animalSelector],
  (animals, animalDetails) => animals[animalDetails.selectedAnimalId]
);

export const selectCurrentSelectedAnimalAge = createSelector<any, any>(
  [selectCurrentSelectedAnimal],
  (animal) => calculateCurrentAge(animal.animalBirthday)
);

export const selectCurrentSelectedAnimalWeightsAsArray = createSelector<any, any>(
  [selectCurrentSelectedAnimal],
  (animal) => {
    let returnWeights = animal?.weightData
      ? Object.keys(animal.weightData)
          .map((key) => animal.weightData[key])
          .filter((w) => w.weightDate?.length > 0)
      : [];
    returnWeights.sort(
      (a, b) =>
        convertStringToDateTimeSeconds(a.weightDate) -
        convertStringToDateTimeSeconds(b.weightDate)
    );
    return returnWeights;
  }
);

export const selectCurrentSelectedAnimalMedicationsAsArray = createSelector<any, any>(
  [selectCurrentSelectedAnimal],
  (animal) => {
    let returnMedication = animal?.medicationData
      ? Object.keys(animal.medicationData)
          .map((key) => animal.medicationData[key])
          .filter((m) => m.medication?.length > 0)
      : [];
    returnMedication.sort(
      (a, b) =>
        convertStringToDateTimeSeconds(b.medicationDate) -
        convertStringToDateTimeSeconds(a.medicationDate)
    );
    return returnMedication;
  }
);

export const selectCurrentSelectedAnimalMilkAsArray = createSelector<any, any>(
  [selectCurrentSelectedAnimal],
  (animal) => {
    let returnMilk = animal?.milkData
      ? Object.keys(animal.milkData)
          .map((key) => animal.milkData[key])
          .filter((m) => m.milkDate?.length > 0)
      : [];
    returnMilk.sort(
      (a, b) =>
        convertStringToDateTimeSeconds(a.milkDate) -
        convertStringToDateTimeSeconds(b.milkDate)
    );
    return returnMilk;
  }
);

export const selectCurrentSelectedAnimalHeatAsArray = createSelector<any, any>(
  [selectCurrentSelectedAnimal],
  (animal) => {
    let returnHeat = animal?.heatData
      ? Object.keys(animal.heatData)
          .map((key) => animal.heatData[key])
          .filter((h) => h.heatDate?.length > 0)
      : [];
    returnHeat.sort(
      (a, b) =>
        convertStringToDateTimeSeconds(b.heatDate) -
        convertStringToDateTimeSeconds(a.heatDate)
    );
    return returnHeat;
  }
);

export const selectCurrentSelectedAnimalSellsAsArray = createSelector<any, any>(
  [selectCurrentSelectedAnimal],
  (animal) => {
    let returnSellHistory = animal?.sellHistory
      ? Object.keys(animal.sellHistory)
          .map((key) => animal.sellHistory[key])
          .filter((s) => s.sellDate?.length > 0)
      : [];
    returnSellHistory.sort(
      (a, b) =>
        convertStringToDateTimeSeconds(a.sellDate) -
        convertStringToDateTimeSeconds(b.sellDate)
    );
    return returnSellHistory;
  }
);

export const selectCurrentSelectedAnimalCampHistoryAsArray = createSelector<any, any>(
  [selectCurrentSelectedAnimal],
  (animal) => {
    let returnCampHistory = animal?.campHistory
      ? Object.keys(animal.campHistory)
          .map((key) => animal.campHistory[key])
          .filter((c) => c.movedOn?.length > 0)
      : [];
    returnCampHistory.sort(
      (a, b) =>
        convertStringToDateTimeSeconds(b.dateTime) -
        convertStringToDateTimeSeconds(a.dateTime)
    );
    return returnCampHistory;
  }
);

export const selectCurrentSelectedAnimalFeedAsArray = createSelector<any, any>(
  [selectCurrentSelectedAnimal],
  (animal) => {
    let returnFeed = animal?.animalFeed
      ? Object.keys(animal.animalFeed)
          .map((key) => animal.animalFeed[key])
          .filter((f) => f.feedDate?.length > 0)
      : [];
    returnFeed.sort(
      (a, b) =>
        convertStringToDateTimeSeconds(a?.feedDate) -
        convertStringToDateTimeSeconds(b?.feedDate)
    );
    return returnFeed;
  }
);

export const selectCurrentSelectedAnimalSellHistoryAsArray = createSelector<any, any>(
  [selectCurrentSelectedAnimal],
  (animal: any) => {
    let returnFeed = animal?.sellHistory
      ? Object.keys(animal.sellHistory)
          .map((key) => animal.sellHistory[key])
          .filter((s) => s.sellDate?.length > 0)
      : [];
    returnFeed.sort(
      (a, b) =>
        convertStringToDateTimeSeconds(a?.sellDate) -
        convertStringToDateTimeSeconds(b?.sellDate)
    );
    return returnFeed;
  }
);

export const selectAnimalAccumulatedFeedQuantity = createSelector<any, any>(
  [selectCurrentSelectedAnimalFeedAsArray],
  (feed : any[]) => {
    return feed
      ? feed
          .map((f) => f.quantity)
          .reduce((total: number, quantity: number) => parseFloat(total.toString()) + parseFloat(quantity ? quantity.toString() : '0'), 0)
      : null;
  }
);

export const selectAnimalAccumulatedFeedCost = createSelector<any, any>(
  [selectCurrentSelectedAnimalFeedAsArray],
  (feed: any[]) => {
    return feed
      ? feed.map((f) => f.cost).reduce((total:number, cost:number) => parseFloat(total?.toString()) + parseFloat(cost ? cost.toString() : '0'), 0)
      : null;
  }
);

export const selectAnimalAccumulatedMedicationCost = createSelector<any,any>(
  [selectCurrentSelectedAnimalMedicationsAsArray],
  (medication: any[]) => {
    return medication
      ? medication.map((m) => m.cost).reduce((total:number, cost:number) => parseFloat(total.toString()) + parseFloat(cost ? cost.toString() : '0'), 0)
      : null;
  }
);

export const selectAllAnimalsAsArray = createSelector<any, any>(
  [farmAnimalsSelector],
  (animals) => convertObjectToArray(animals).filter((a) => !a.deleted)
);

export const selectCurrentSelectedAnimalMotherAsArray = createSelector<any, any>(
  [selectCurrentSelectedAnimal, selectAllAnimalsAsArray],
  (animal, animals) =>
    animal && animals
      ? animals.filter(
          (f) =>
            clean(f.tagNumber ?? 'a16z9') === clean(animal.animalMother) ||
            clean(f.displayName) === clean(animal.animalMother)
        )
      : []
);

export const selectCurrentSelectedAnimalFatherAsArray = createSelector<any, any>(
  [selectCurrentSelectedAnimal, selectAllAnimalsAsArray],
  (animal, animals) =>
    animal && animals
      ? animals.filter(
          (f) =>
            clean(f.tagNumber ?? 'a16z9') === clean(animal.animalFather) ||
            clean(f.displayName) === clean(animal.animalFather)
        )
      : []
);

export const selectCurrentSelectedAnimalSiblingsAsArray = createSelector<any, any>(
  [selectCurrentSelectedAnimal, selectAllAnimalsAsArray],
  (animal, animals) =>
    animal && animals
      ? animals.filter(
          (f) =>
            ((f.animalFather && (clean(f.animalFather) === clean(animal.animalFather))) ||
            (f.animalMother && (clean(f.animalMother) === clean(animal.animalMother)))) &&
            f.id !== (animal?.id ?? animal?.animalId) &&
            !f.animalEnd &&
            !f.deleted
        )
      : []
);

export const selectCurrentSelectedAnimalChildrenAsArray = createSelector<any, any>(
  [selectCurrentSelectedAnimal, selectAllAnimalsAsArray],
  (animal, animals) =>
    animal && animals
      ? animals.filter(
          (f) =>
            (clean(f.animalFather) === clean(animal.displayName) ||
            clean(f.animalMother) === clean(animal.displayName) ||
            clean(f.animalFather) === clean(animal.tagNumber ?? 'a16z9') ||
            clean(f.animalMother) === clean(animal.tagNumber ?? 'a16z9')) &&
            !f.animalEnd &&
            !f.deleted
        )
      : []
);

export const selectCurrentSelectedAnimalDeadChildrenAsArray = createSelector<any, any>(
  [selectCurrentSelectedAnimal, selectAllAnimalsAsArray],
  (animal, animals) =>
    animal && animals
      ? animals.filter(
          (f) =>
            (clean(f.animalFather) === clean(animal.displayName) ||
            clean(f.animalMother) === clean(animal.displayName) ||
            clean(f.animalFather) === clean(animal.tagNumber ?? 'a16z9') ||
            clean(f.animalMother) === clean(animal.tagNumber ?? 'a16z9')) &&
            f.animalEnd &&
            f.animalEnd.reason === 'death' &&
            !f.deleted
        )
      : []
);

export const selectCurrentCampAnimals = createSelector<any, any>(
  [selectAllAnimalsAsArray, selectCurrentCamp],
  (animals, camp) =>
    animals.filter((animal) => (animal.campId === (camp?.id ?? camp?.campId)) && !animal.deleted && !animal.animalEnd)
);

export const selectCurrentCampAnimalsAsArray = createSelector<any, any>(
  [selectCurrentCampAnimals],
  (animals) => convertObjectToArray(animals)
);

export const selectCurrentActiveCampAnimalsAsArray = createSelector<any, any>(
  [selectCurrentCampAnimalsAsArray],
  (animals) => animals.filter((a) => !a.deleted && !a.animalEnd)
);

export const selectUserCanModifyAnimal = createSelector<any, any>(
  //selectActiveSubscriptionsAsArray
  [
    selectCurrentUser,
    selectCurrentSelectedAnimal,
    selectVisibleCampsAsArray,
    selectVisibleFarmsAsArray,
  ],
  (user, animal, camps, farms) => {
    if (!user || !animal || !camps || !farms) return false;
    const animalCamp = camps.filter(
      (c) => animal.campId === (c.id ?? c.campId)
    );
    if (!animalCamp || animalCamp.lenth === 0) return false;
    const animalFarms = farms.filter(
      (f) => animalCamp[0]?.farmId === (f.id ?? f.farmId)
    );
    const userAnimalModifyFarms = animalFarms.filter(
      (f) =>
        f.workingUsers?.includes(user.id ?? user.uid) ||
        f.managingUsers?.includes(user.id ?? user.uid) ||
        f.creatingUserId === (user.id ?? user.uid)
    );

    if (!userAnimalModifyFarms || userAnimalModifyFarms?.length === 0)
      return false;

    return true;
  }
);

export const selectUserCanEditAnimal = createSelector<any, any>(
  //selectActiveSubscriptionsAsArray
  [
    selectCurrentUser,
    selectCurrentSelectedAnimal,
    selectVisibleCampsAsArray,
    selectVisibleFarmsAsArray,
  ],
  (user, animal, camps, farms) => {
    if (!user || !animal || !camps || !farms) return false;
    const animalCamp = camps.filter(
      (c) => animal?.campId === (c.id ?? c.campId)
    );
    if (!animalCamp || animalCamp.lenth === 0) return false;
    const animalFarms = farms.filter(
      (f) => animalCamp[0]?.farmId === (f.id ?? f.farmId)
    );
    const userAnimalModifyFarms = animalFarms.filter(
      (f) =>
        f.managingUsers?.includes(user?.id ?? user.uid) ||
        f.creatingUserId === (user?.id ?? user.uid)
    );

    if (!userAnimalModifyFarms || userAnimalModifyFarms?.length === 0)
      return false;

    return true;
  }
);

const clean = (string) => {
  return string?.replace(/\s+/g, '').toLowerCase();
}
