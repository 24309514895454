import transactionActionTypes from './transaction.types';

export const getTransactionsStart = (userId) => ({
  type: transactionActionTypes.GET_TRANSACTIONS_START,
  payload: userId,
});

export const getTransactionsSuccess = (transactions) => ({
  type: transactionActionTypes.GET_TRANSACTIONS_SUCCESS,
  payload: transactions,
});

export const getTransactionsFailure = (error) => ({
  type: transactionActionTypes.GET_TRANSACTIONS_FAILURE,
  payload: error,
});

export const createTransactionStart = (transactionDetails) => ({
  type: transactionActionTypes.CREATE_TRANSACTION_START,
  payload: transactionDetails,
});

export const createTransactionSuccess = (transactionDetails) => ({
  type: transactionActionTypes.CREATE_TRANSACTION_SUCCESS,
  payload: transactionDetails,
});

export const createTransactionFailure = (error) => ({
  type: transactionActionTypes.CREATE_TRANSACTION_FAILURE,
  payload: error,
});

export const editTransactionStart = (transactionId) => ({
  type: transactionActionTypes.EDIT_TRANSACTION_START,
  payload: transactionId,
});

export const editTransactionSuccess = (transactionDetails) => ({
  type: transactionActionTypes.EDIT_TRANSACTION_SUCCESS,
  payload: transactionDetails,
});

export const editTransactionFailure = (error) => ({
  type: transactionActionTypes.EDIT_TRANSACTION_FAILURE,
  payload: error,
});

export const deleteTransactionStart = (transactionId) => ({
  type: transactionActionTypes.DELETE_TRANSACTION_START,
  payload: transactionId,
});

export const deleteTransactionSuccess = (transactionId) => ({
  type: transactionActionTypes.DELETE_TRANSACTION_SUCCESS,
  payload: transactionId,
});

export const deleteTransactionFailure = (error) => ({
  type: transactionActionTypes.DELETE_TRANSACTION_FAILURE,
  payload: error,
});
