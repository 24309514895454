import React from 'react';
import { CustomButtonDefault } from './custom-button.styles';

interface CustomButtonProps {
  children: React.ReactNode;
  isGoogleSignIn?: boolean;
  [key: string]: any;
}
const CustomButton = ({ children, isGoogleSignIn, ...otherProps }: CustomButtonProps) => (
  <CustomButtonDefault
    className={`${
      isGoogleSignIn ? 'google-sign-in' : ''
    } custom-button ${children}`}
    {...otherProps}
  >
    {children}
  </CustomButtonDefault>
);

export default CustomButton;
