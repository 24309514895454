import React from 'react';
import { TreeViewHeading } from '../../../styles/product-page/product-page.styles';
import {
  MainColor,
  Margin10LeftRight,
} from '../../../styles/shared/shared.styles';

const TreeViewTitle = ({ item, title }) => {
  return (
    <TreeViewHeading>
      <MainColor>{title}</MainColor>
      <Margin10LeftRight>{item?.id}</Margin10LeftRight>
    </TreeViewHeading>
  );
};

export default TreeViewTitle;
