const notificationActionTypes = {
    CREATE_NOTIFICATION_START: 'CREATE_NOTIFICATION_START',
    CREATE_NOTIFICATION_SUCCESS: 'CREATE_NOTIFICATION_SUCCESS',
    CREATE_NOTIFICATION_FAILURE: 'CREATE_NOTIFICATION_FAILURE',
    DELETE_NOTIFICATION_START: 'DELETE_NOTIFICATION_START',
    DELETE_NOTIFICATION_SUCCESS: 'DELETE_NOTIFICATION_SUCCESS',
    DELETE_NOTIFICATION_FAILURE: 'DELETE_NOTIFICATION_FAILURE',
    FETCH_NOTIFICATIONS_START: 'FETCH_NOTIFICATION_START',
    FETCH_DEVICE_NOTIFICATIONS_START: 'FETCH_DEVICE_NOTIFICATIONS_START',
    FETCH_ANIMAL_NOTIFICATIONS_START: 'FETCH_ANIMAL_NOTIFICATIONS_START',
    FETCH_CAMP_NOTIFICATIONS_START: 'FETCH_CAMP_NOTIFICATIONS_START',
    FETCH_FARM_NOTIFICATIONS_START: 'FETCH_FARM_NOTIFICATION_START',
    FETCH_PUBLIC_NOTIFICATIONS_START: 'FETCH_PUBLIC_NOTIFICATIONS_START',
    FETCH_NOTIFICATIONS_SUCCESS: 'FETCH_NOTIFICATION_SUCCESS',
    FETCH_NOTIFICATIONS_FAILURE: 'FETCH_NOTIFICATION_FAILURE',
    SELECT_NOTIFICATION: 'SELECT_NOTIFICATION',
    CLEAR_NOTIFICATIONS: 'CLEAR_NOTIFICATIONS',
    CLEAR_NOTIFICATIONS_SUCCESS: 'CLEAR_NOTIFICATIONS_SUCCESS'
}

export default notificationActionTypes;