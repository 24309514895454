import React from 'react';
import { withRouter } from 'react-router-dom';
import './drawer-list-item.styles.scss';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

const DrawerListItem = ({ id, history, iconImageUrl, text, linkToLocal }) => {
  const onClickHandler = (e) => {
    if (linkToLocal) {
      history.push(linkToLocal);
    }
  };

  return (
    <div className="list-item" onClick={onClickHandler}>
      <ListItem button key={id}>
        <ListItemIcon className="list-item-icon">
          <img src={iconImageUrl} alt={text} />
        </ListItemIcon>
        <ListItemText primary={text} className="list-item-text" />
      </ListItem>
    </div>
  );
};

export default withRouter(DrawerListItem);
