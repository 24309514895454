import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { firestore } from '../../firebase/firebase.utils';
import {
  deleteSubscriptionSuccess,
  subscriptionUpdateSuccess,
  subscriptionCreateFailed,
  subscriptionCreateSuccess,
  subscriptionFetchFailed,
  subscriptionFetchSuccess,
  subscriptionUpdateFailed,
  clearSubscriptions,
  fetchAgentSubscriptionsFailure,
  fetchAgentSubscriptionsSuccess,
} from './subscription.actions';
import farmActionTypes from '../farms/farm.action-types';
import { convertObjectToArray } from '../../utils/array/mapper';
import {
  convertCollectionsSnapshotToMap,
  getCurrentUser,
} from '../../firebase/firebase.functions';
import subscriptionActionTypes from './subscription.action-types';
import userActionTypes from '../users/user.types';
import { handleError } from '../../utils/errors/errorHandler';

export function* createNewFarmSubscriptionAsync(action) {
  const farmInfo = action.payload;
  if (!farmInfo) return;
  if (!farmInfo?.subscriptionId || farmInfo?.subscriptionId?.length < 6) {
    try {
      let subscriptionInfo: any = {
        farmId: farmInfo?.farmId ?? farmInfo.id,
        createdDate: new Date(),
        trialStartDate:
          new Date() < new Date('1 Sept 2021')
            ? new Date('1 Sept 2021')
            : new Date(),
        active: true,
        isTest: process.env.NODE_ENV === ('development' || 'test'),
      };
      let trialEndDate = new Date(subscriptionInfo.trialStartDate);
      trialEndDate.setDate(trialEndDate.getDate() + 30);
      subscriptionInfo.trialEndDate = trialEndDate;
      const collectionRef = firestore.collection('subscriptions');
      const addedSubscriptionRef = yield collectionRef.add({
        ...subscriptionInfo,
      });
      yield put(
        subscriptionCreateSuccess({
          id: addedSubscriptionRef.id,
          subscriptionId: addedSubscriptionRef.id,
          ...subscriptionInfo,
        })
      );
    } catch (error) {
      handleError(error);
      yield put(subscriptionCreateFailed(error));
    }
  }
}

export function* fetchFarmSubscription(action) {
  const farmInfo = action.payload;
  if (!farmInfo.subscriptionId) {
    yield call(createNewFarmSubscriptionAsync, farmInfo);
  }
  const subscriptionId = farmInfo.subscriptionId;
  try {
    const subscriptionRef = firestore.doc(`subscriptions/${subscriptionId}`);
    const snapShot = yield subscriptionRef.get();
    if (snapShot.exists) {
      const subscriptionDoc = yield snapShot.data();
      yield put(
        subscriptionFetchSuccess({
          [subscriptionId]: {
            id: subscriptionId,
            subscriptionId,
            ...subscriptionDoc,
          },
        })
      );
    } else {
      yield put(
        deleteSubscriptionSuccess({
          [subscriptionId]: { id: subscriptionId, subscriptionId },
        })
      );
    }
  } catch (error) {
    handleError(error);
    console.log('Error fetching farm subscription', error);
    yield put(subscriptionFetchFailed(error));
  }
}

export function* fetchAllFarmSubscriptions(action) {
  const userAuth = yield getCurrentUser();
  if (!userAuth) return;
  const fetchedFarm = convertObjectToArray(action.payload).find(
    (f) => f.subscriptionId
  );
  if (fetchedFarm) {
    yield call(fetchFarmSubscription, { payload: fetchedFarm });
  }
  const unsubFarm = convertObjectToArray(action.payload).find(
    (f) => !f.subscriptionId && f.creatingUserId === userAuth.uid
  );
  if (unsubFarm) {
    yield call(createNewFarmSubscriptionAsync, { payload: unsubFarm });
  }
}

export function* fetchAgentSubscriptionsAsync({ payload }) {
  try {
    const collectionRef = firestore
      .collection('subscriptions')
      .where('agentCode', '==', payload);
    const snapshot = yield collectionRef.get();
    const collectionsMap = yield call(
      convertCollectionsSnapshotToMap,
      snapshot
    );
    yield put(fetchAgentSubscriptionsSuccess(collectionsMap));
  } catch (error) {
    handleError(error);
    yield put(fetchAgentSubscriptionsFailure(error));
  }
}

export function* onFarmFetchSuccess() {
  yield takeEvery(
    farmActionTypes.FETCH_FARMS_SUCCESS,
    fetchAllFarmSubscriptions
  );
}

export function* updateSubscriptionAsync(action) {
  const subscription = action.payload;
  if (!subscription) return;
  try {
    const subscriptionRef = firestore.doc(
      `subscriptions/${subscription?.id ?? subscription?.subscriptionId}`
    );
    const updatedSubscriptionRef = yield subscriptionRef.get();
    if (updatedSubscriptionRef.exists) {
      const updatedSubscriptionDoc = yield updatedSubscriptionRef.data();
      const updatedSubscriptionDetails = { ...subscription };
      delete updatedSubscriptionDetails.id;
      yield subscriptionRef.update(updatedSubscriptionDetails);
      yield put(
        subscriptionUpdateSuccess({
          id: subscription?.id,
          subscriptionId: subscription?.id ?? subscription?.orderId,
          isTest: process.env.NODE_ENV === ('development' || 'test'),
          ...updatedSubscriptionDoc,
          ...subscription,
        })
      );
    }
  } catch (error) {
    handleError(error);
    yield put(subscriptionUpdateFailed(error));
  }
}

export function* onFarmCreateSuccess() {
  yield takeEvery(
    farmActionTypes.CREATE_FARM_SUCCESS,
    createNewFarmSubscriptionAsync
  );
}
export function* onSubscriptionUpdate() {
  yield takeEvery(
    subscriptionActionTypes.START_SUBSCRIPTION_UPDATE,
    updateSubscriptionAsync
  );
}

export function* clearSubscriptionsData() {
  yield put(clearSubscriptions());
}

export function* onUserSignout() {
  yield takeLatest(userActionTypes.SIGN_OUT_SUCCESS, clearSubscriptionsData);
}

export function* onAgentSubscriptionsFetch() {
  yield takeLatest(
    subscriptionActionTypes.FETCH_AGENT_SUBSCRIPTIONS_START,
    fetchAgentSubscriptionsAsync
  );
}

export function* subscriptionSagas() {
  yield all([
    call(onFarmFetchSuccess),
    call(onFarmCreateSuccess),
    call(onSubscriptionUpdate),
    call(onUserSignout),
    call(onAgentSubscriptionsFetch),
  ]);
}
