import breedActionTypes from './breed.action-types'

const INITIAL_STATE = {
    processing: null,
    selectedBreed: '',
    breeds: {},
    error: null
}

const breedsReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;
    switch(type) {
        case breedActionTypes.CLEAR_BREEDS:
            return INITIAL_STATE;

        case breedActionTypes.BREED_FETCH_SUCCESS:
            return {
                ...state,
                breeds: {
                    ...payload
                },
                processing: false
            }

        default:
            return state;
    }
}

export default breedsReducer;