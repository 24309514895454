const reminderActionTypes = {
    CLEAR_NOTIFICATIONS: 'CLEAR_NOTIFICATIONS',
    CLEAR_REMINDERS: 'CLEAR_REMINDERS',
    SET_SELECTED_REMINDER: 'SET_SELECTED_REMINDER',
    CREATE_REMINDER_START: 'CREATE_REMINDER_START',
    DELETE_REMINDER_START: 'DELETE_REMINDER_START',
    DELETE_REMINDER_SUCCESS: 'DELETE_REMINDER_SUCCESS',
    DELETE_REMINDER_FAILURE: 'DELETE_REMINDER_FAILURE',
    UPDATE_REMINDER_START: 'UPDATE_REMINDER_START',
    UPDATE_MESSAGE_LISTENER: 'UPDATE_MESSAGE_LISTENER_START',
    UPDATE_REMINDER_SUCCESS: 'UPDATE_REMINDER_SUCCESS',
    UPDATE_REMINDER_FAILURE: 'UPDATE_REMINDER_FAILURE',
    CREATE_REMINDER_SUCCESS: 'CREATE_REMINDER_SUCCESS',
    CREATE_REMINDER_FAILURE: 'CREATE_REMINDER_FAILURE',
    FETCH_REMINDER_START: 'FETCH_REMINDER_START',
    FETCH_REMINDER_SUCCESS: 'FETCH_REMINDER_SUCCESS',
    FETCH_REMINDER_FAILURE: 'FETCH_REMINDER_FAILURE',
  };
  
  export default reminderActionTypes;
  