import React from 'react';
import { Bold, Margin10TopBottom } from '../../../styles/shared/shared.styles';

const UserDescription = ({ user }) => {
  return (
    <>
      <h3>
        <Bold>Bio</Bold>
      </h3>
      <Margin10TopBottom as="p">{user?.bio}</Margin10TopBottom>
    </>
  );
};

export default UserDescription;
