import styled from 'styled-components';
import { Grid, ListItem } from '@material-ui/core';
import { TabPanel } from '@material-ui/lab';

export const CartListInfo = styled(Grid)`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;

export const CartListItem = styled(ListItem)`
  border-bottom: 1px solid #eeeeee;
`;

export const TabPanelCustom = styled(TabPanel)`
  padding: 24px 0 !important;
`;

export const TreeViewHeading = styled(Grid)`
  display: flex;
  @media (max-width: 480px) {
    font-size: 14px;
  }
`;
