import styled from 'styled-components';
import { Grid } from '@material-ui/core';

export const GridMaintenance = styled(Grid)`
  position: fixed;
  min-width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  text-align: center;
`;

export const ImageMaintenance = styled.img`
  width: 75%;
  margin: auto;
`;
