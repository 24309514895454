import {
  auth,
  firestore,
  googleProvider,
  facebookProvider,
  storage,
  vapidKey,
  messaging,
} from "./firebase.utils";
import { getStorage, ref, uploadBytes, uploadString, getDownloadURL  } from "firebase/storage";
import { signInWithRedirect, signInWithPopup, createUserWithEmailAndPassword as createEmail, signInWithEmailAndPassword as authEmail} from "firebase/auth";

export const getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = auth.onAuthStateChanged((userAuth) => {
      unsubscribe();
      resolve(userAuth);
    }, reject);
  });
};

export const signInWithGoogle = async () =>
  await signInWithRedirect(auth, googleProvider);


export const signInWithGooglePopup = async () =>
  await signInWithPopup(auth, googleProvider);

export const signInWithFacebook = async () =>
  await signInWithRedirect(auth, facebookProvider);

export const signInWithEmailAndPassword = async ({ email, password }) => {
  return await authEmail(auth, email, password);
};
export const signOut = () => auth.signOut();

export const createUserWithEmailAndPassword = async (email, password) => {
  return await createEmail(auth, email, password);
};

export const updateFirebaseUserField = async (
  userAuth,
  fieldKey,
  fieldValue
) => {
  if (!userAuth) return;
  const userRef = firestore.doc(`users/${userAuth.uid}`);
  const snapShot = await userRef.get();
  if (snapShot.exists) {
    try {
      await userRef.update({
        [fieldKey]: fieldValue,
      });
      return true;
    } catch (error: any) {
      console.log("ERROR CREATING USER", error.message);
      return false;
    }
  }
};

export const createUserProfileDocument = async (userAuth, additionalData) => {
  if (!userAuth) return;

  const userRef = firestore.doc(`users/${userAuth.uid}`);
  const snapShot = await userRef.get();

  if (!snapShot.exists) {
    const { photoURL, uid, emailVerified, email, displayName } = userAuth;
    try {
      await userRef.set({
        photoURL,
        uid,
        emailVerified,
        email: email.toLowerCase(),
        displayName,
        createDate: new Date(),
        hasDoneOnboarding: false,
        hasDoneProfile: false,
        hasDoneTerms: false,
        ...additionalData,
      });
    } catch (error: any) {
      console.log("ERROR CREATING USER", error.message);
    }
  }

  return userRef;
};

export const addCollectionAndDocuments = async (
  collectionKey,
  objectsToAdd
) => {
  const collectionRef = firestore.collection(collectionKey);
  const batch = firestore.batch();
  objectsToAdd.forEach((element) => {
    const newDocRef = collectionRef.doc();
    batch.set(newDocRef, element);
  });
  return await batch.commit();
};

export const addSingleDocument = async (collectionKey, objectToAdd) => {
  return (dispatch) => {
    firestore
      .collection(collectionKey)
      .add(objectToAdd)
      .then((resp) => {
        console.log("SUCCESS", resp)
      })
      .catch((error) => {
        console.log("ERROR", error)
      });
  };
};

export const convertCollectionsSnapshotToMap = (collections) => {
  const transformedCollection = collections.docs
    .filter((a) => !a.deleted)
    .map((doc) => {
      return {
        id: doc.id,
        ...doc.data(),
      };
    });
  return transformedCollection.reduce((acc, col) => {
    acc[col.id] = col;
    return acc;
  }, {});
};

export const uploadFileToStorage = async (data, path, mimeType) => {
  try {
    const storage = getStorage();
    // const storeRef = await storage.ref();
    const metadata = {
      contentType: mimeType,
    };
    const storageRef = ref(storage, path);
    // const fileRef = await storeRef.child(path);
    let snapshot: any = null;

    if (data.includes("base64")) {
        snapshot = await uploadString(storageRef, data, 'data_url');
    } else {
      snapshot = await uploadBytes(storageRef, data, metadata);
    }
    const url = await getDownloadURL(storageRef);
    return {...snapshot,url};
  } catch (error) {
    throw error;
  }
};


export const getToken = (setTokenFound) => {
  if (messaging) {
    return messaging
      .getToken({ vapidKey })
      .then((currentToken) => {
        if (currentToken) {
          if (setTokenFound) {
            setTokenFound(currentToken);
            return currentToken;
          } else return currentToken;
        } else {
          console.log(
            "No registration token available. Request permission to generate one."
          );
          setTokenFound(null);
          if (setTokenFound) {
            setTokenFound(null);
          } else return null;
        }
      })
      .catch((err) => {
        console.log("An error occurred while retrieving token. ", err);
      });
  }
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    if (messaging) {
      messaging.onMessage((payload) => {
        resolve(payload);
      });
    }
  });
