import campActionTypes from './camp.action-types';

export const setProcessingStart = () => ({
  type: campActionTypes.SET_PROCESSING_START,
});

export const selectCamp = (campId) => ({
  type: campActionTypes.SELECT_CAMP,
  payload: campId,
});

export const clearCamps = () => ({
  type: campActionTypes.CLEAR_CAMPS,
});

export const createCampStart = (campData) => ({
  type: campActionTypes.CREATE_CAMP_START,
  payload: campData,
});

export const createCampSuccess = (campDetails) => ({
  type: campActionTypes.CREATE_CAMP_SUCCESS,
  payload: campDetails,
});

export const createCampFailure = (error) => ({
  type: campActionTypes.CREATE_CAMP_FAILURE,
  payload: error,
});

export const deleteCampStart = (campDetails) => ({
  type: campActionTypes.DELETE_CAMP_START,
  payload: campDetails,
});

export const deleteCampSuccess = (campDetails) => ({
  type: campActionTypes.DELETE_CAMP_SUCCESS,
  payload: campDetails,
});

export const deleteCampFailure = (error) => ({
  type: campActionTypes.DELETE_CAMP_FAILURE,
  payload: error,
});

export const fetchCampSuccess = (campDetails) => ({
  type: campActionTypes.FETCH_CAMP_SUCCESS,
  payload: campDetails,
});

export const fetchSelectedCampSuccess = (campDetails) => ({
  type: campActionTypes.FETCH_SELECTED_CAMP_SUCCESS,
  payload: campDetails,
});

export const fetchCampFailure = (error) => ({
  type: campActionTypes.FETCH_CAMP_FAILURE,
  payload: error,
});

export const updateCampStart = (campDetails) => ({
  type: campActionTypes.UPDATE_CAMP_START,
  payload: campDetails,
});

export const updateCampSuccess = (campDetails) => ({
  type: campActionTypes.UPDATE_CAMP_SUCCESS,
  payload: campDetails,
});

export const updateCampImagesSuccess = (campDetails) => ({
  type: campActionTypes.UPDATE_CAMP_IMAGES_SUCCESS,
  payload: campDetails,
});

export const updateCampFailure = (error) => ({
  type: campActionTypes.UPDATE_CAMP_FAILURE,
  payload: error,
});

export const deleteLatestCampFeedStart = (animalsDetails, campDetails) => ({
  type: campActionTypes.DELETE_LATEST_CAMP_FEED_START,
  payload: { animalsDetails, campDetails },
});

export const addCampFeedSuccess = (campDetails, feedDetails) => ({
  type: campActionTypes.ADD_CAMP_FEED_SUCCESS,
  payload: { campDetails, feedDetails },
});

export const addCampFeedFailure = (error) => ({
  type: campActionTypes.ADD_CAMP_FEED_FAILURE,
  payload: error,
});

export const restoreCampStart = (camp) => ({
  type: campActionTypes.RESTORE_CAMP_START,
  payload: camp,
});

export const restoreCampSuccess = (camp) => ({
  type: campActionTypes.RESTORE_CAMP_SUCCESS,
  payload: camp,
});

export const restoreCampFailure = (error) => ({
  type: campActionTypes.RESTORE_CAMP_FAILURE,
  payload: error,
});

export const addCampFeedStart = (animalsDetails, campDetails, feedInfo) => ({
  type: campActionTypes.ADD_CAMP_FEED_START,
  payload: { animalsDetails, campDetails, feedInfo },
});

export const fetchAllCampSuccess = (camps) => ({
  type: campActionTypes.FETCH_ALL_CAMPS_SUCCESS,
  payload: camps,
});
