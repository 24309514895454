import promptActionTypes from './prompt.action-types';

export const createPromptStart = (promptDetails) => ({
  type: promptActionTypes.CREATE_PROMPT_START,
  payload: promptDetails,
});

export const createPromptSuccess = (promptDetails) => ({
  type: promptActionTypes.CREATE_PROMPT_SUCCESS,
  payload: promptDetails,
});

export const createPromptFailure = (error) => ({
  type: promptActionTypes.CREATE_PROMPT_FAILURE,
  payload: error,
});
