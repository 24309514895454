import { omit } from 'lodash';
import orderActionTypes from './order.action-types';

const INITIAL_STATE = {
  processing: null,
  selectedOrder: '',
  orders: {},
  agentOrders: {},
  error: null,
};

const ordersReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case orderActionTypes.CLEAR_ORDERS:
      return INITIAL_STATE;

    case orderActionTypes.START_ORDER_CREATE:
      return {
        ...state,
        processing: true,
      };

    case orderActionTypes.ORDER_CREATE_FAILED:
      return {
        ...state,
        processing: false,
      };

    case orderActionTypes.ORDER_CREATE_SUCCESS:
    case orderActionTypes.UPDATE_ORDER_SUCCESS:
      return {
        ...state,
        orders: {
          ...state.orders,
          [payload.orderId ?? payload.id]: payload,
        },
        processing: false,
      };

    case orderActionTypes.ORDER_FETCH_SUCCESS:
      return {
        ...state,
        orders: {
          ...state.orders,
          ...payload,
        },
        processing: false,
      };

    case orderActionTypes.FETCH_AGENT_ORDERS_SUCCESS:
      return {
        ...state,
        processing: false,
        agentOrders: payload,
      };

    case orderActionTypes.ORDER_DELETE_SUCCESS:
      return {
        ...state,
        processing: false,
        orders: {
          ...omit(state.visibleCamps, payload?.orderId ?? payload?.id),
        },
      };

    default:
      return state;
  }
};

export default ordersReducer;
