import { all, call } from 'redux-saga/effects';
import { animalSagas } from './animals/animal.sagas';
import { campSagas } from './camps/camp.sagas';
import { farmSagas } from './farms/farm.sagas';
import { imageSagas } from './images/image.sagas';
import { userSagas } from './users/user.sagas';
import { deviceSagas } from './devices/device.sagas';
import { eventSagas } from './events/event.sagas';
import { notificationSagas } from './notifications/notification.sagas';
import { reminderSagas } from './reminders/reminder.sagas';
import { documentSagas } from './documents/document.sagas';
import { productSagas } from './products/product.sagas';
import { cartSagas } from './cart/cart.sagas';
import { orderSagas } from './orders/order.sagas';
import { subscriptionSagas } from './subscriptions/subscription.sagas';
import { feedbackSagas } from './feedback/feedback.sagas';
import { errorSagas } from './errors/error.sagas';
import { breedSagas } from './breeds/breed.sagas';
import { transactionSagas } from './transactions/transaction.sagas';
import { promptSagas } from './prompt/prompt.sagas';

export default function* rootSaga() {
  yield all([
    call(userSagas),
    call(farmSagas),
    call(breedSagas),
    call(campSagas),
    call(animalSagas),
    call(imageSagas),
    call(deviceSagas),
    call(eventSagas),
    call(notificationSagas),
    call(reminderSagas),
    call(documentSagas),
    call(productSagas),
    call(cartSagas),
    call(orderSagas),
    call(subscriptionSagas),
    call(feedbackSagas),
    call(promptSagas),
    call(errorSagas),
    call(transactionSagas),
  ]);
}
