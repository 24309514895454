import UserActionTypes from './user.types';

export interface UserState {
  currentUser?: any;
  currentAgent?: any;
  repCode?: string | null;
  visibleUsers?: {
    [userId: string]: any;
  };
  processing?: boolean;
  error?: any;
  message?: string | null;
}

const INITIAL_STATE: UserState = {
  processing: false,
  visibleUsers: {},
  currentUser: null,
  currentAgent: null,
  repCode: null,
  error: null,
  message: null,
};

const userReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {

    case UserActionTypes.EMAIL_SIGN_IN_START:
    case UserActionTypes.FACEBOOK_SIGN_IN_START:
    case UserActionTypes.GOOGLE_SIGN_IN_START:
    case UserActionTypes.EMAIL_SIGN_UP_START:
    case UserActionTypes.PROFILE_UPDATE_START:
      return {
        ...state,
        processing: true,
      };

    case UserActionTypes.SIGN_IN_SUCCESS:
      return {
        ...state,
        currentUser: payload,
        repCode: state.repCode ?? payload?.repCode,
        error: null,
      };

    case UserActionTypes.SIGN_OUT_SUCCESS:
      return INITIAL_STATE;

    case UserActionTypes.EMAIL_SIGN_UP_FAILED:
    case UserActionTypes.PROFILE_UPDATE_FAILED:
    case UserActionTypes.PASSWORD_RESET_FAILURE:
    case UserActionTypes.SIGN_IN_FAILED:
    case UserActionTypes.SIGN_OUT_FAILED:
      return {
        ...state,
        processing: false,
        error: payload,
      };

    case UserActionTypes.PASSWORD_RESET_SUCCESS:
      return {
        ...state,
        error: null,
        message: payload,
        processing: false,
      };

    case UserActionTypes.SET_PROFILE_COMPLETE:
      return {
        ...state,
        processing: false,
        currentUser: {
          ...state.currentUser,
          hasDoneProfile: true,
        },
      };

    case UserActionTypes.SET_TERMS_ACCEPTED:
      return {
        ...state,
        processing: false,
        currentUser: {
          ...state.currentUser,
          hasDoneTerms: true,
        },
      };

    case UserActionTypes.SET_ONBOARDING_DONE:
      return {
        ...state,
        processing: false,
        currentUser: {
          ...state.currentUser,
          hasDoneOnboarding: true,
        },
      };

    case UserActionTypes.PROFILE_UPDATE_SUCCESS:
      return {
        ...state,
        processing: false,
        currentUser: {
          ...state.currentUser,
          ...payload,
        },
        repCode: state.repCode ?? payload?.repCode,
        error: null,
      };

    case UserActionTypes.CHECK_USER_EMAIL_EXISTS:
      return {
        ...state,
        processing: true,
      };

    case UserActionTypes.VISIBLE_USER_FETCH_FAILED:
      return {
        ...state,
        error: payload,
        processing: false,
      };

    case UserActionTypes.VISIBLE_USER_FETCH_SUCCESS:
      return {
        ...state,
        processing: false,
        visibleUsers: {
          ...state.visibleUsers,
          ...payload,
        },
      };
    case UserActionTypes.FETCH_AGENT_SUCCESS:
      return {
        ...state,
        processing: false,
        currentAgent: payload,
      };

    case UserActionTypes.REP_UPDATE_START:
      return {
        ...state,
        processing: false,
        currentUser: {
          ...state.currentUser,
          repCode: payload,
        },
        repCode: payload,
      };

    default:
      return state;
  }
};

export default userReducer;
