import { eventChannel } from '@redux-saga/core';
import { all, call, put, takeLatest, take } from 'redux-saga/effects';
import { convertCollectionsSnapshotToMap } from '../../firebase/firebase.functions';
import { firestore } from '../../firebase/firebase.utils';
import { handleError } from '../../utils/errors/errorHandler';
import campActionTypes from '../camps/camp.action-types';
import { createCampFailure, createCampSuccess, selectCamp } from '../camps/camp.actions';
import userActionTypes from '../users/user.types';
import animalActionTypes from './animal.action-types';
import { Animal, MedicationData, WeightData } from '../../common'

import {
  addAnimalFeedFailure,
  addAnimalFeedSuccess,
  addAnimalHeatFailure,
  addAnimalHeatSuccess,
  addAnimalMedicationFailure,
  addAnimalMedicationSuccess,
  addAnimalMilkFailure,
  addAnimalMilkSuccess,
  addAnimalWeightFailure,
  addAnimalWeightSuccess,
  createAnimalFailure,
  createAnimalSuccess,
  deleteAnimalFailure,
  deleteAnimalSuccess,
  deleteLatestAnimalCampHistoryFailure,
  deleteLatestAnimalCampHistorySuccess,
  endAnimalFailure,
  endAnimalSuccess,
  fetchAnimalFailure,
  fetchAnimalFamilyStart,
  fetchAnimalSuccess,
  fetchCampAnimalSuccess,
  setProcessingStart,
  updateAnimalFailure,
  updateAnimalSuccess,
  clearAnimals,
  sellAnimalFailure,
  sellAnimalSuccess,
  fetchAnimalsForSaleSuccess,
  fetchAnimalsForSaleFailure,
  soldAnimalFailure,
  soldAnimalSuccess,
  fetchPurchasedAnimalsSuccess,
  fetchPurchasedAnimalsFailure,
  fetchSoldAnimalsFailure,
  fetchSoldAnimalsSuccess,
  undoAnimalSellFailure,
  undoAnimalSellSuccess,
  assignAnimalCampAndFarmSuccess,
} from './animal.actions';

export function* createNewAnimalAsync(action) {
  const animalDetails: Animal = action.payload;
  if (!animalDetails) return;
  try {
    const collectionRef = firestore.collection('animals');
    let addedAnimalRef: Animal | null = null;
    try {
      let updatedAnimalDetails: Animal = { ...animalDetails };
      delete updatedAnimalDetails.animalImages;
      addedAnimalRef = yield collectionRef.add(updatedAnimalDetails);
    } catch (error: any) {
      handleError(error, action);
      yield put(createAnimalFailure(error));
    } finally {
      yield put(
        createAnimalSuccess({
          animalId: addedAnimalRef?.id ?? addedAnimalRef?.animalId,
          ...animalDetails,
        })
      );
    }
  } catch (error: any) {
    handleError(error);
    yield put(createAnimalFailure(error));
  }
}

export function* updateAnimalDetailsAsync(action) {
  const animalDetails: Animal = action.payload;
  if (!animalDetails) return;
  try {
    const animalRef = firestore.doc(
      `animals/${animalDetails?.id ?? animalDetails?.animalId}`
    );
    const updatedAnimalRef = yield animalRef.get();
    if (updatedAnimalRef.exists) {
      const updatedAnimalDetails = { ...animalDetails };
      delete updatedAnimalDetails.id;
      try {
        delete updatedAnimalDetails.animalImages;
        yield animalRef.update(updatedAnimalDetails);
      } catch (error: any) {
        yield put(updateAnimalFailure(error));
      } finally {
        yield put(
          updateAnimalSuccess({
            animalId: animalDetails?.id ?? animalDetails?.animalId,
            ...animalDetails,
          })
        );
      }
    } else {
      yield put(deleteAnimalSuccess(animalDetails));
    }
  } catch (error: any) {
    handleError(error, action);
    yield put(updateAnimalFailure(error));
  }
}

export function* assignAnimalFarmAndCampAsync(action) {
  const animalDetails: Animal = action.payload;
  if (!animalDetails) return;
  try {

    if(animalDetails.transferringUserId){
      
      const newAnimalDetails = { 
        ...animalDetails, 
        creatingUserId: animalDetails.transferringUserId,
        campId: animalDetails.transferringCampId,
        farmId: animalDetails.transferringFarmId
      };
      delete animalDetails.transferringUserId;
      delete animalDetails.transferringCampId;
      delete animalDetails.transferringFarmId;
      
      delete newAnimalDetails.transferringUserId;
      delete newAnimalDetails.transferringCampId;
      delete newAnimalDetails.transferringFarmId;

      if(animalDetails.price) {
        animalDetails.cost = animalDetails.price;
        delete animalDetails.price;
      }
      if(animalDetails.buyerEmail)
        animalDetails.buyerEmail = 'Received';

      const collectionRef = firestore.collection('animals');
      const newAnimalRef = yield collectionRef.add(newAnimalDetails);
      yield put(
        createAnimalSuccess({
          animalId: newAnimalRef?.id ?? newAnimalRef?.animalId,
          ...newAnimalDetails,
        })
      );
    }

    const animalRef = firestore.doc(
      `animals/${animalDetails?.id ?? animalDetails?.animalId}`
    );
    const updatedAnimalRef = yield animalRef.get();
    if (updatedAnimalRef.exists) {
      const updatedAnimalDetails = { ...animalDetails };
      delete updatedAnimalDetails.id;
      try {
        delete updatedAnimalDetails.animalImages;
        yield animalRef.set(updatedAnimalDetails);
      } catch (error: any) {
        handleError(error);
        yield put(updateAnimalFailure(error));
      } finally {
        yield put(
          assignAnimalCampAndFarmSuccess({
            animalId: animalDetails?.id ?? animalDetails?.animalId ?? animalRef.id,
            ...animalDetails,
          })
        );
      }
    } else {
      yield put(deleteAnimalSuccess(animalDetails));
    }
  } catch (error: any) {
    handleError(error);
    yield put(updateAnimalFailure(error));
  }
}

export function* deleteAnimalDetailsAsync(action) {
  const animalDetails: Animal = action.payload;
  if (!animalDetails) return;
  try {
    const animalRef = firestore.doc(
      `animals/${animalDetails?.id ?? animalDetails?.animalId}`
    );
    const updatedAnimalRef = yield animalRef.get();
    if (updatedAnimalRef.exists) {
      const updatedAnimalDetails = { deleted: true };
      yield animalRef.update(updatedAnimalDetails);
      yield put(
        deleteAnimalSuccess({
          animalId: animalDetails?.id ?? animalDetails?.animalId,
          ...animalDetails,
          ...updatedAnimalDetails,
        })
      );
    }
  } catch (error: any) {
    handleError(error);
    yield put(deleteAnimalFailure(error));
  }
}

export function* addAnimalWeightAsync(action) {
  const { animalDetails, weightDetails }: { animalDetails: Animal, weightDetails: WeightData } = action.payload;
  if (!animalDetails || !weightDetails) return;
  try {
    const animalRef = firestore.doc(
      `animals/${animalDetails?.id ?? animalDetails?.animalId}`
    );
    const snapShot = yield animalRef.get();
    if (snapShot.exists) {
      const animalDoc = yield snapShot.data();
      const weightData = {
        ...animalDoc.weightData,
        [new Date(weightDetails.weightDate).toISOString()]: weightDetails,
      };
      yield animalRef.update({ weightData });
      yield put(addAnimalWeightSuccess(animalDetails, weightData));
    }
  } catch (error: any) {
    handleError(error);
    yield put(addAnimalWeightFailure(error));
  }
}

export function* addAnimalMedicationAsync(action) {
  const { animalDetails, medicationDetails }: { animalDetails: Animal, medicationDetails: MedicationData } = action.payload;
  if (!animalDetails || !medicationDetails) return;

  try {
    const animalRef = firestore.doc(
      `animals/${animalDetails?.id ?? animalDetails?.animalId}`
    );
    const snapShot = yield animalRef.get();
    if (snapShot.exists) {
      const animalDoc = yield snapShot.data();
      const medicationData = {
        ...animalDoc.medicationData,
        [medicationDetails.dateTime.toISOString()]: medicationDetails,
      };
      yield animalRef.update({ medicationData });
      yield put(addAnimalMedicationSuccess(animalDetails, medicationData));
    }
  } catch (error: any) {
    handleError(error);
    yield put(addAnimalMedicationFailure(error));
  }
}

export function* addAnimalMilkAsync(action) {
  const { animalDetails, milkDetails } = action.payload;
  if (!animalDetails || !milkDetails) return;

  try {
    const animalRef = firestore.doc(
      `animals/${animalDetails?.id ?? animalDetails?.animalId}`
    );
    const snapShot = yield animalRef.get();
    if (snapShot.exists) {
      const animalDoc = yield snapShot.data();
      const milkData = {
        ...animalDoc.milkData,
        [milkDetails.dateTime.toISOString()]: milkDetails,
      };
      yield animalRef.update({ milkData });
      yield put(addAnimalMilkSuccess(animalDetails, milkData));
    }
  } catch (error: any) {
    handleError(error);
    yield put(addAnimalMilkFailure(error));
  }
}

export function* addAnimalHeatAsync(action) {
  const { animalDetails, heatDetails } = action.payload;
  if (!animalDetails || !heatDetails) return;

  try {
    const animalRef = firestore.doc(
      `animals/${animalDetails?.id ?? animalDetails?.animalId}`
    );
    const snapShot = yield animalRef.get();
    if (snapShot.exists) {
      const animalDoc = yield snapShot.data();
      const heatData = {
        ...animalDoc.heatData,
        [heatDetails.dateTime.toISOString()]: heatDetails,
      };
      yield animalRef.update({ heatData });
      yield put(addAnimalHeatSuccess(animalDetails, heatData));
    }
  } catch (error: any) {
    handleError(error, action);
    yield put(addAnimalHeatFailure(error));
  }
}
export function* sellAnimalAsync(action) {
  const { animalDetails, sellDetails } = action.payload;
  if (!animalDetails || !sellDetails) return;
  try {
    const animalRef = firestore.doc(
      `animals/${animalDetails?.id ?? animalDetails?.animalId}`
    );
    const snapShot = yield animalRef.get();
    if (snapShot.exists) {
      const sellData = { ...sellDetails };
      yield animalRef.update({ sellData });
      yield put(sellAnimalSuccess(animalDetails, sellData));
    }
  } catch (error: any) {
    handleError(error, action);
    yield put(sellAnimalFailure(error));
  }
}

export function* soldAnimalAsync(action) {
  const { animalDetails, purchaseDetails } = action.payload;
  if (!animalDetails || !purchaseDetails) return;
  try {
    const animalRef = firestore.doc(
      `animals/${animalDetails?.id ?? animalDetails?.animalId}`
    );
    const snapShot = yield animalRef.get();
    if (snapShot.exists) {
      const animalDoc = yield snapShot.data();
      yield delete animalDoc.sellData;
      yield delete animalDetails.sellData;
      const sellHistory = {
        ...animalDetails.sellHistory,
        [animalDetails?.creatingUserId]: purchaseDetails,
      };
      
      const soldAnimalDetails = {
        ...animalDetails,
        ...purchaseDetails,
        sellHistory,
        sellerName: animalDetails.animalOwner,
        sellerEmail: animalDetails.animalOwnerEmail ?? animalDetails.sellerEmail,
        animalOwner: purchaseDetails.buyerName ?? purchaseDetails.buyerEmail,
        animalOwnerEmail: purchaseDetails.buyerEmail,
        buyerEmail: purchaseDetails.buyerEmail.toLowerCase(),
      };
      yield animalRef.set(soldAnimalDetails);

      const soldCamp = yield firestore
      .collection("camps")
      .where("displayName", "==", 'Sold')
      .where('farmId', "==", animalDetails?.farmId)
      .get();
      const soldCampMap = yield call(
        convertCollectionsSnapshotToMap,
        soldCamp
      );
      if(soldCampMap.length > 0){
        soldAnimalDetails.campId = soldCampMap[Object.keys(soldCampMap)[0]].id ?? soldCampMap[Object.keys(soldCampMap)[0]].campId;
      } else
      {
        const campCollectionRef = firestore.collection("camps");
        let addedCampRef: any = {} as any;
        let updatedCampDetails = {};
        try {
          updatedCampDetails = { 
            displayName: 'Sold',
            farmId: animalDetails?.farmId,
            creatingUserId: animalDetails?.creatingUserId,
            campPurose: 'Keep track of sold animals',
            createdAt: new Date().toISOString(),
            campImage: 'https://www.nicepng.com/png/detail/92-928255_sign-in-for-address-logo-sold.png',
            campImages: ['https://www.nicepng.com/png/detail/92-928255_sign-in-for-address-logo-sold.png']
           };
          addedCampRef = yield campCollectionRef.add(updatedCampDetails);
          soldAnimalDetails.campId = addedCampRef.id;
        } catch (error) {
          yield put(createCampFailure(error));
        } finally {
          yield put(
            createCampSuccess({
              campId: addedCampRef?.id ?? addedCampRef?.campId,
              ...updatedCampDetails,
            })
          );
        }
      }
      yield animalRef.set(soldAnimalDetails);
      yield put(soldAnimalSuccess(animalDetails, sellHistory));
    }
  } catch (error: any) {
    handleError(error, action);
    yield put(soldAnimalFailure(error));
  }
}

export function* addAnimalFeedAsync(action) {
  const { animalDetails, feedDetails } = action.payload;
  if (!animalDetails || !feedDetails) return;
  try {
    const animalRef = firestore.doc(
      `animals/${animalDetails?.id ?? animalDetails?.animalId}`
    );
    const snapShot = yield animalRef.get();
    if (snapShot.exists) {
      const animalDoc = yield snapShot.data();
      const animalFeed = {
        ...animalDoc.animalFeed,
        [feedDetails.dateTime.toISOString()]: feedDetails,
      };
      yield animalRef.update({ animalFeed });
      yield put(addAnimalFeedSuccess(animalDetails, animalFeed));
    }
  } catch (error: any) {
    handleError(error, action);
    yield put(addAnimalFeedFailure(error));
  }
}

export function* endAnimalAsync(action) {
  const { animalDetails, endDetails } = action.payload;
  if (!animalDetails || !endDetails) return;
  try {
    const animalRef = firestore.doc(
      `animals/${animalDetails?.id ?? animalDetails?.animalId}`
    );
    const snapShot = yield animalRef.get();
    if (snapShot.exists) {
      const animalEnd = { ...endDetails };
      Object.keys(animalEnd).forEach(
        (a) => animalEnd[a] === '' && delete animalEnd[a]
      );
      yield animalRef.update({ animalEnd });
      yield put(endAnimalSuccess(animalDetails, animalEnd));
    }
  } catch (error: any) {
    handleError(error, action);
    yield put(endAnimalFailure(error));
  }
}

export function* deleteLatestAnimalWeightAsync(action) {
  const { animalDetails, weightDataArray } = action.payload;
  if (!animalDetails || !weightDataArray) return;
  try {
    const animalRef = firestore.doc(
      `animals/${animalDetails.id ?? animalDetails.animalId}`
    );
    const snapShot = yield animalRef.get();

    const weightData = weightDataArray.reduce(
      (key, value) => ({
        ...key,
        [new Date(value.weightDate).toISOString()]: value,
      }),
      {}
    );

    if (snapShot.exists) {
      yield animalRef.update({ weightData });
      yield put(addAnimalWeightSuccess(animalDetails, weightData));
    }
  } catch (error: any) {
    handleError(error);
    yield put(addAnimalWeightFailure(error));
  }
}

export function* deleteLatestAnimalMedicationAsync(action) {
  const { animalDetails }: { animalDetails: Animal } = action.payload;
  if (!animalDetails) return;

  try {
    const animalRef = firestore.doc(
      `animals/${animalDetails.id ?? animalDetails.animalId}`
    );
    const snapShot = yield animalRef.get();
    const medicationKeysAsArray = Object.keys(animalDetails.medicationData);
    var popval = medicationKeysAsArray.sort().pop();
    const medicationData = { ...animalDetails.medicationData };
    delete medicationData[popval ?? ''];
    if (snapShot.exists) {
      yield animalRef.update({ medicationData });
      yield put(addAnimalMedicationSuccess(animalDetails, medicationData));
    }
  } catch (error: any) {
    handleError(error);
    yield put(addAnimalMedicationFailure(error));
  }
}

export function* deleteLatestAnimalMilkAsync(action) {
  const { animalDetails } = action.payload;
  if (!animalDetails) return;

  try {
    const animalRef = firestore.doc(
      `animals/${animalDetails.id ?? animalDetails.animalId}`
    );
    const snapShot = yield animalRef.get();
    const milkKeysAsArray = Object.keys(animalDetails.milkData);
    milkKeysAsArray.sort().pop();
    const milkData = Object.keys(animalDetails.milkData)
      .filter((key) => milkKeysAsArray.includes(key))
      .reduce((milkObject, key) => {
        return {
          ...milkObject,
          [key]: animalDetails.milkData[key],
        };
      }, {});
    if (snapShot.exists) {
      yield animalRef.update({ milkData });
      yield put(addAnimalMilkSuccess(animalDetails, milkData));
    }
  } catch (error: any) {
    handleError(error);
    yield put(addAnimalMilkFailure(error));
  }
}

export function* deleteLatestAnimalHeatAsync(action) {
  const { animalDetails } = action.payload;
  if (!animalDetails) return;

  try {
    const animalRef = firestore.doc(
      `animals/${animalDetails.id ?? animalDetails.animalId}`
    );
    const snapShot = yield animalRef.get();
    const heatKeysAsArray = Object.keys(animalDetails.heatData);
    heatKeysAsArray.sort().pop();
    const heatData = Object.keys(animalDetails.heatData)
      .filter((key) => heatKeysAsArray.includes(key))
      .reduce((heatObject, key) => {
        return {
          ...heatObject,
          [key]: animalDetails.heatData[key],
        };
      }, {});
    if (snapShot.exists) {
      yield animalRef.update({ heatData });
      yield put(addAnimalHeatSuccess(animalDetails, heatData));
    }
  } catch (error: any) {
    handleError(error);
    yield put(addAnimalHeatFailure(error));
  }
}

export function* deleteLatestAnimalFeedAsync(action) {
  const { animalDetails } = action.payload;
  if (!animalDetails) return;

  try {
    const animalRef = firestore.doc(
      `animals/${animalDetails.id ?? animalDetails.animalId}`
    );
    const snapShot = yield animalRef.get();
    const feedKeysAsArray = Object.keys(animalDetails.animalFeed);
    feedKeysAsArray.sort().pop();
    const animalFeed = Object.keys(animalDetails.animalFeed)
      .filter((key) => feedKeysAsArray.includes(key))
      .reduce((feedObject, key) => {
        return {
          ...feedObject,
          [key]: animalDetails.animalFeed[key],
        };
      }, {});
    if (snapShot.exists) {
      yield animalRef.update({ animalFeed });
      yield put(addAnimalFeedSuccess(animalDetails, animalFeed));
    }
  } catch (error: any) {
    handleError(error);
    yield put(addAnimalHeatFailure(error));
  }
}

export function* deleteLatestAnimalCampHistoryAsync(action) {
  const { animalDetails, campId } = action.payload;
  if (!animalDetails || !campId) return;

  try {
    const animalRef = firestore.doc(
      `animals/${animalDetails.id ?? animalDetails.animalId}`
    );
    const snapShot = yield animalRef.get();
    const campHistoryKeysAsArray = Object.keys(animalDetails.campHistory);
    campHistoryKeysAsArray.sort().pop();
    const campHistory = Object.keys(animalDetails.campHistory)
      .filter((key) => campHistoryKeysAsArray.includes(key))
      .reduce((campHistoryObject, key) => {
        return {
          ...campHistoryObject,
          [key]: animalDetails.campHistory[key],
        };
      }, {});
    if (snapShot.exists) {
      yield animalRef.update({ campHistory, campId });
      yield put(
        deleteLatestAnimalCampHistorySuccess(animalDetails, campHistory)
      );
    }
  } catch (error: any) {
    handleError(error, action);
    yield put(deleteLatestAnimalCampHistoryFailure(error));
  }
}

export function* transferAnimalCampAsync(action) {
  const { animalDetails, campDetails } = action.payload;
  if (!animalDetails || !campDetails) return;

  try {
    const animalRef = firestore.doc(
      `animals/${animalDetails.id ?? animalDetails.animalId}`
    );
    const snapShot = yield animalRef.get();
    if (snapShot.exists) {
      const animalDoc = yield snapShot.data();
      const campId = campDetails.id ?? campDetails.campId;
      const campHistory = {
        ...animalDoc.campHistory,
        [campDetails.dateTime.toISOString()]: { ...campDetails },
      };
      yield animalRef.update({
        campId,
        campHistory,
      });
      yield put(updateAnimalSuccess({ ...animalDetails, campId, campHistory }));
    }
  } catch (error: any) {
    handleError(error);
    yield put(updateAnimalFailure(error));
  }
}

export function* fetchCampAnimals(action) {
  yield put(setProcessingStart());
  const campId = action.payload;
  if (!campId) return;
  try {
    const collectionRef = firestore
      .collection('animals')
      .where('campId', '==', campId);
    const snapshot = yield collectionRef.get();
    const collectionsMap = yield call(
      convertCollectionsSnapshotToMap,
      snapshot
    );
    yield put(fetchCampAnimalSuccess(collectionsMap));

    const animalChannel = eventChannel((emit) =>
      collectionRef.onSnapshot(emit)
    );

    try {
      while (true) {
        const data = yield take(animalChannel);
        const dataMap = yield call(convertCollectionsSnapshotToMap, data);
        yield put(fetchCampAnimalSuccess(dataMap));
      }
    } catch (err) {
      yield put(fetchAnimalFailure(err));
    }
  } catch (error: any) {
    handleError(error);
    yield put(fetchAnimalFailure(error.message));
  }
}

export function* fetchPurchasedAnimals(action) {
  const user = action.payload;
  try {
    const collectionRef = firestore
      .collection('animals')
      .where('buyerEmail', '==', user?.email?.toLowerCase());
    const snapshot = yield collectionRef.get();
    const collectionsMap = yield call(
      convertCollectionsSnapshotToMap,
      snapshot
    );
    yield put(fetchPurchasedAnimalsSuccess(collectionsMap));
  } catch (error: any) {
    handleError(error);
    yield put(fetchPurchasedAnimalsFailure(error.message));
  }
}

export function* fetchSoldAnimals(action) {
  const user = action.payload;
  const userId = user?.id ?? user?.uid;
  try {
    const collectionRef = firestore
      .collection('animals')
      .where(`sellHistory.${userId}.seller`, '==', userId);
    const snapshot = yield collectionRef.get();
    const collectionsMap = yield call(
      convertCollectionsSnapshotToMap,
      snapshot
    );
    yield put(fetchSoldAnimalsSuccess(collectionsMap));
  } catch (error: any) {
    handleError(error);
    yield put(fetchSoldAnimalsFailure(error.message));
  }
}

export function* undoAnimalSellAsync(action) {
  const { animalDetails } = action.payload;
  if (!animalDetails) return;
  try {
    const animalRef = firestore.doc(
      `animals/${animalDetails.id ?? animalDetails.animalId}`
    );
    const snapShot = yield animalRef.get();
    yield delete animalDetails.sellData;
    if (snapShot.exists) {
      yield animalRef.set(animalDetails);
      yield put(undoAnimalSellSuccess(animalDetails));
    }
  } catch (error: any) {
    handleError(error);
    yield put(undoAnimalSellFailure(error));
  }
}

export function* fetchCurrentAnimal(action) {
  yield put(setProcessingStart());
  const animalId = action.payload;
  if (!animalId) return;
  try {
    const animalRef = firestore.doc(`animals/${animalId}`);
    const snapShot = yield animalRef.get();
    if (snapShot.exists) {
      const animalDoc = yield snapShot.data();

      yield put(
        fetchAnimalSuccess({
          [animalId]: { ...animalDoc, id: animalId, animalId: animalId },
        })
      );
      yield put(
        fetchAnimalFamilyStart({
          [animalId]: { ...animalDoc, id: animalId, animalId: animalId },
        })
      );
      yield put(selectCamp(animalDoc.campId));
    }
  } catch (error: any) {
    handleError(error);
    yield put(fetchAnimalFailure(error.message));
  }
}

export function* fetchAnimalMotherAsync(action) {
  const animalDetails = action.payload;
  if (!animalDetails) return;
  if (!animalDetails.animalMother) return;
  try {
    let collectionRef = firestore
      .collection('animals')
      .where('tagNumber', '==', animalDetails.animalMother);
    let snapshot = yield collectionRef.get();
    if (snapshot.empty) {
      collectionRef = firestore
        .collection('animals')
        .where('displayName', '==', animalDetails.animalMother);

      snapshot = yield collectionRef.get();
    }
    const collectionsMap = yield call(
      convertCollectionsSnapshotToMap,
      snapshot
    );
    yield put(fetchAnimalSuccess(collectionsMap));
  } catch (error: any) {
    handleError(error);
    yield put(fetchAnimalFailure(error.message));
  }
}

export function* fetchAnimalFatherAsync(action) {
  const animalDetails = action.payload;
  if (!animalDetails) return;
  if (!animalDetails.animalFather) return;
  try {
    let collectionRef = firestore
      .collection('animals')
      .where('tagNumber', '==', animalDetails.animalFather);
    let snapshot = yield collectionRef.get();
    if (snapshot.empty) {
      collectionRef = firestore
        .collection('animals')
        .where('displayName', '==', animalDetails.animalFather);

      snapshot = yield collectionRef.get();
    }
    const collectionsMap = yield call(
      convertCollectionsSnapshotToMap,
      snapshot
    );
    yield put(fetchAnimalSuccess(collectionsMap));
  } catch (error: any) {
    handleError(error);
    yield put(fetchAnimalFailure(error.message));
  }
}

export function* fetchAnimalSiblingsAsync(action) {
  const animalDetails = action.payload;
  if (!animalDetails) return;
  if (!animalDetails.animalFather && !animalDetails.animalMother) return;
  try {
    if (animalDetails.animalMother) {
      const collectionMotherRef = firestore
        .collection('animals')
        .where('animalMother', '==', animalDetails.animalMother);
      const motherSnapshot = yield collectionMotherRef.get();
      const motherCollectionsMap = yield call(
        convertCollectionsSnapshotToMap,
        motherSnapshot
      );
      yield put(fetchAnimalSuccess(motherCollectionsMap));
    }
    if (animalDetails.animalFather) {
      const collectionFatherRef = firestore
        .collection('animals')
        .where('animalFather', '==', animalDetails.animalFather);
      const fatherSnapshot = yield collectionFatherRef.get();
      const fatherCollectionsMap = yield call(
        convertCollectionsSnapshotToMap,
        fatherSnapshot
      );
      yield put(fetchAnimalSuccess(fatherCollectionsMap));
    }
  } catch (error: any) {
    handleError(error);
    yield put(fetchAnimalFailure(error.message));
  }
}

export function* fetchAnimalFamily(action) {
  const animalDetails = action.payload;
  if (!animalDetails) return;
  yield call(fetchAnimalMotherAsync, animalDetails);
  yield call(fetchAnimalFatherAsync, animalDetails);
  yield call(fetchAnimalSiblingsAsync, animalDetails);
}

export function* fetchAnimalsForSale() {
  try {
    const collectionRef = firestore.collection('animals').orderBy(`sellData`);
    const snapshot = yield collectionRef.get();
    const collectionsMap = yield call(
      convertCollectionsSnapshotToMap,
      snapshot
    );
    yield put(fetchAnimalsForSaleSuccess(collectionsMap));
  } catch (error: any) {
    handleError(error);
    yield put(fetchAnimalsForSaleFailure(error.message));
  }
}

export function* onCampSelected() {
  yield takeLatest(campActionTypes.SELECT_CAMP, fetchCampAnimals);
}

export function* onAnimalCreate() {
  yield takeLatest(animalActionTypes.CREATE_ANIMAL_START, createNewAnimalAsync);
}
export function* onAnimalSelected() {
  yield takeLatest(animalActionTypes.SET_SELECTED_ANIMAL, fetchCurrentAnimal);
}

export function* onAnimalFieldUpdate() {
  yield takeLatest(
    animalActionTypes.UPDATE_ANIMAL_START,
    updateAnimalDetailsAsync
  );
}

export function* onAssignFarmAndCamp() {
  yield takeLatest(
    animalActionTypes.ASSIGN_FARM_AND_CAMP_START,
    assignAnimalFarmAndCampAsync
  );
}

export function* onAnimalWeightAdd() {
  yield takeLatest(
    animalActionTypes.ADD_ANIMAL_WEIGHT_START,
    addAnimalWeightAsync
  );
}

export function* onAnimalMedicationAdd() {
  yield takeLatest(
    animalActionTypes.ADD_ANIMAL_MEDICATION_START,
    addAnimalMedicationAsync
  );
}

export function* onAnimalMilkAdd() {
  yield takeLatest(animalActionTypes.ADD_ANIMAL_MILK_START, addAnimalMilkAsync);
}

export function* onAnimalHeatAdd() {
  yield takeLatest(animalActionTypes.ADD_ANIMAL_HEAT_START, addAnimalHeatAsync);
}

export function* onAnimalSell() {
  yield takeLatest(animalActionTypes.SELL_ANIMAL_START, sellAnimalAsync);
}

export function* onAnimalSold() {
  yield takeLatest(animalActionTypes.SOLD_ANIMAL_START, soldAnimalAsync);
}

export function* onAnimalEnd() {
  yield takeLatest(animalActionTypes.END_ANIMAL_START, endAnimalAsync);
}

export function* onAnimalFeedAdd() {
  yield takeLatest(animalActionTypes.ADD_ANIMAL_FEED_START, addAnimalFeedAsync);
}

export function* onAnimalWeightLatestDelete() {
  yield takeLatest(
    animalActionTypes.DELETE_LATEST_ANIMAL_WEIGHT_START,
    deleteLatestAnimalWeightAsync
  );
}

export function* onAnimalMedicationLatestDelete() {
  yield takeLatest(
    animalActionTypes.DELETE_LATEST_ANIMAL_MEDICATION_START,
    deleteLatestAnimalMedicationAsync
  );
}

export function* onAnimalMilkLatestDelete() {
  yield takeLatest(
    animalActionTypes.DELETE_LATEST_ANIMAL_MILK_START,
    deleteLatestAnimalMilkAsync
  );
}

export function* onAnimalHeatLatestDelete() {
  yield takeLatest(
    animalActionTypes.DELETE_LATEST_ANIMAL_HEAT_START,
    deleteLatestAnimalHeatAsync
  );
}

export function* onAnimalFeedLatestDelete() {
  yield takeLatest(
    animalActionTypes.DELETE_LATEST_ANIMAL_FEED_START,
    deleteLatestAnimalFeedAsync
  );
}

export function* onAnimalCampHistoryLatestDelete() {
  yield takeLatest(
    animalActionTypes.DELETE_LATEST_ANIMAL_CAMP_HISTORY_START,
    deleteLatestAnimalCampHistoryAsync
  );
}

export function* onAnimalCampTransfer() {
  yield takeLatest(
    animalActionTypes.TRANSFER_ANIMAL_CAMP_START,
    transferAnimalCampAsync
  );
}

export function* onPurchasedAnimalsFetch() {
  yield takeLatest(userActionTypes.SIGN_IN_SUCCESS, fetchPurchasedAnimals);
}

export function* onAnimalFamilyFetch() {
  yield takeLatest(
    animalActionTypes.FETCH_ANIMAL_FAMILY_START,
    fetchAnimalFamily
  );
}

export function* onAnimalDelete() {
  yield takeLatest(
    animalActionTypes.DELETE_ANIMAL_START,
    deleteAnimalDetailsAsync
  );
}

export function* clearAnimalsData() {
  yield put(clearAnimals());
}

export function* onUserSignout() {
  yield takeLatest(userActionTypes.SIGN_OUT_SUCCESS, clearAnimalsData);
}

export function* onAnimalsForSaleFetch() {
  yield takeLatest(userActionTypes.SIGN_IN_SUCCESS, fetchAnimalsForSale);
}

export function* onSoldAnimalsFetch() {
  yield takeLatest(userActionTypes.SIGN_IN_SUCCESS, fetchSoldAnimals);
}

export function* onAnimalSellUndo() {
  yield takeLatest(
    animalActionTypes.UNDO_ANIMAL_SELL_START,
    undoAnimalSellAsync
  );
}

export function* animalSagas() {
  yield all([
    call(onCampSelected),
    call(onAnimalSelected),
    call(onAnimalCreate),
    call(onAnimalWeightAdd),
    call(onAnimalMedicationAdd),
    call(onAnimalMilkAdd),
    call(onAnimalHeatAdd),
    call(onAnimalSell),
    call(onAnimalFeedAdd),
    call(onAnimalEnd),
    call(onAnimalWeightLatestDelete),
    call(onAnimalMedicationLatestDelete),
    call(onAnimalMilkLatestDelete),
    call(onAnimalHeatLatestDelete),
    call(onAnimalFeedLatestDelete),
    call(onAnimalCampHistoryLatestDelete),
    call(onAnimalCampTransfer),
    call(onAnimalFieldUpdate),
    call(onAnimalDelete),
    call(onUserSignout),
    call(onAnimalsForSaleFetch),
    call(onAnimalSold),
    call(onPurchasedAnimalsFetch),
    call(onSoldAnimalsFetch),
    call(onAnimalSellUndo),
    call(onAssignFarmAndCamp),
  ]);
}
