import { Button, IconButton, Snackbar } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  getToken,
  onMessageListener,
} from "../../../firebase/firebase.functions";
import { createNotificationStart } from "../../../redux/notifications/notification.actions";
import { updateTokenStart } from "../../../redux/users/user.actions";
import { selectCurrentUser } from "../../../redux/users/user.selectors";

function NotificationPopups({
  updateUserToken,
  currentUser,
  createNotificationStart,
}) {
  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({ title: "", body: "" } as
    | any
    | null);
  const [isTokenFound, setTokenFound] = useState(false);

  useEffect(() => {
    try {
      getToken(setTokenFound);

      if (updateUserToken && currentUser && isTokenFound)
        updateUserToken(currentUser, isTokenFound);

      const channel = new BroadcastChannel("sw-messages");
      channel.addEventListener("message", (event) => {
        const payload = event?.data;
        if (isTokenFound) {
          setShow(true);
          setNotification({
            title: payload.notification.title,
            body: payload.notification.body,
          });
          createNotificationStart(payload);
        }
      });

      onMessageListener()
        .then((payload: any) => {
          if (isTokenFound) {
            setShow(true);
            setNotification({
              title: payload.notification.title,
              body: payload.notification.body,
            });
            createNotificationStart(payload);
          }
        })
        .catch((err) => console.log("failed: ", err));
    } catch (err) {
      console.log("failed: ", err);
    }
  }, [updateUserToken, currentUser, isTokenFound, createNotificationStart]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShow(false);
    setNotification(null);
  };

  return (
    <div>
      {notification && (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={show}
          autoHideDuration={6000}
          onClose={handleClose}
          message={
            <>
              <h2>{notification.title}</h2>
              <span>{notification.body}</span>
            </>
          }
          key={notification.title}
          action={
            <OpenClose open={handleClose} close={handleClose} />
          }
        />
      )}
    </div>
  );
}

const OpenClose = ({ open, close }) => (
  <>
    <Button variant="contained" color="primary" onClick={open}>
      OK
    </Button>
    <IconButton onClick={close}>
      <Close />
    </IconButton>
  </>
);

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
});

const mapDispacthToProps = (dispatch) => ({
  updateUserToken: (user, token) => dispatch(updateTokenStart(user, token)),
  createNotificationStart: (notificationDetails) =>
    dispatch(createNotificationStart(notificationDetails)),
});

export default connect(mapStateToProps, mapDispacthToProps)(NotificationPopups);
