import { all, call, put, takeLatest } from "redux-saga/effects";
import { convertCollectionsSnapshotToMap } from "../../firebase/firebase.functions";
import { firestore } from "../../firebase/firebase.utils";
import { handleError } from "../../utils/errors/errorHandler";
import animalActionTypes from "../animals/animal.action-types";
import userActionTypes from "../users/user.types";
import {
  clearDevices,
  fetchDeviceFailure,
  fetchDeviceSuccess,
} from "./device.actions";

export function* fetchAnimalDevices(action) {
  const animalId = action.payload;
  if (!animalId) return;
  try {
    const collectionRef = firestore
      .collection("devices")
      .where("animalIds", "array-contains", animalId);
    const snapshot = yield collectionRef.get();
    const collectionsMap = yield call(
      convertCollectionsSnapshotToMap,
      snapshot
    );
    yield put(fetchDeviceSuccess(collectionsMap));
  } catch (error) {
    handleError(error);
    yield put(fetchDeviceFailure(error.message));
  }
}

export function* onAnimalSelected() {
  yield takeLatest(animalActionTypes.SET_SELECTED_ANIMAL, fetchAnimalDevices);
}

export function* clearDevicesData() {
  yield put(clearDevices());
}

export function* onUserSignout() {
  yield takeLatest(userActionTypes.SIGN_OUT_SUCCESS, clearDevicesData);
}

export function* deviceSagas() {
  yield all([call(onAnimalSelected), call(onUserSignout)]);
}
