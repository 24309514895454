import { createTheme } from '@material-ui/core/styles';
import shape from './shape';
import palette from './palette';
import typography from './typography';
import breakpoints from './breakpoints';
import shadows, { customShadows } from './shadows';

// ----------------------------------------------------------------------

const isLight = true;

const themeOptions = {
  palette: isLight
    ? { ...palette.light, mode: 'light' }
    : { ...palette.dark, mode: 'dark' },
  shape,
  typography,
  breakpoints,
  shadows: isLight ? shadows.light : shadows.dark,
  customShadows: isLight ? customShadows.light : customShadows.dark,
};

const theme = createTheme(themeOptions);

export default theme;
