import subscriptionActionTypes from './subscription.action-types';

export const clearSubscriptions = () => ({
  type: subscriptionActionTypes.CLEAR_SUBSCRIPTIONS,
});

export const startSubscriptionCreate = (subscriptionInfo) => ({
  type: subscriptionActionTypes.START_SUBSCRIPTION_CREATE,
  payload: subscriptionInfo,
});

export const subscriptionCreateSuccess = (subscriptionInfo) => ({
  type: subscriptionActionTypes.SUBSCRIPTION_CREATE_SUCCESS,
  payload: subscriptionInfo,
});

export const subscriptionCreateFailed = (error) => ({
  type: subscriptionActionTypes.SUBSCRIPTION_CREATE_FAILED,
  payload: error,
});

export const startSubscriptionUpdate = (subscriptionInfo) => ({
  type: subscriptionActionTypes.START_SUBSCRIPTION_UPDATE,
  payload: subscriptionInfo,
});

export const subscriptionUpdateSuccess = (subscriptionInfo) => ({
  type: subscriptionActionTypes.SUBSCRIPTION_UPDATE_SUCCESS,
  payload: subscriptionInfo,
});

export const subscriptionUpdateFailed = (error) => ({
  type: subscriptionActionTypes.SUBSCRIPTION_UPDATE_FAILED,
  payload: error,
});

export const startSubscriptionFetch = (subscriptionId) => ({
  type: subscriptionActionTypes.START_SUBSCRIPTION_FETCH,
  payload: subscriptionId,
});

export const subscriptionFetchSuccess = (subscriptionInfo) => ({
  type: subscriptionActionTypes.SUBSCRIPTION_FETCH_SUCCESS,
  payload: subscriptionInfo,
});

export const subscriptionFetchFailed = (error) => ({
  type: subscriptionActionTypes.SUBSCRIPTION_FETCH_FAILED,
  payload: error,
});

export const deleteSubscriptionStart = (subscriptionInfo) => ({
  type: subscriptionActionTypes.DELETE_SUBSCRIPTION_START,
  payload: subscriptionInfo,
});

export const deleteSubscriptionSuccess = (subscriptionInfo) => ({
  type: subscriptionActionTypes.DELETE_SUBSCRIPTION_SUCCESS,
  payload: subscriptionInfo,
});

export const deleteSubscriptionFailure = (error) => ({
  type: subscriptionActionTypes.DELETE_SUBSCRIPTION_FAILURE,
  payload: error,
});

export const fetchAgentSubscriptionsStart = (agentCode) => ({
  type: subscriptionActionTypes.FETCH_AGENT_SUBSCRIPTIONS_START,
  payload: agentCode,
});

export const fetchAgentSubscriptionsSuccess = (subscription) => ({
  type: subscriptionActionTypes.FETCH_AGENT_SUBSCRIPTIONS_SUCCESS,
  payload: subscription,
});

export const fetchAgentSubscriptionsFailure = (error) => ({
  type: subscriptionActionTypes.FETCH_AGENT_SUBSCRIPTIONS_FAILURE,
  payload: error,
});
