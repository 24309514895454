import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// import { createBlacklistFilter } from 'redux-persist-transform-filter';

import userReducer from './users/user.reducer';
import farmDetailsReducer from './farms/farm.reducer';
import campReducer from './camps/camp.reducer';
import animalDetailsReducer from './animals/animal.reducer';
import imageReducer from './images/image.reducer';
import deviceReducer from './devices/device.reducer';
import eventsReducer from './events/event.reducer';
import notificationsReducer from './notifications/notification.reducer';
import remindersReducer from './reminders/reminder.reducer';
import documentsReducer from './documents/document.reducer';
import productsReducer from './products/product.reducer';
import cartReducer from './cart/cart-reducer';
import ordersReducer from './orders/order.reducer';
import subscriptionsReducer from './subscriptions/subscription.reducer';
import errorsReducer from './errors/error.reducer';
import breedsReducer from './breeds/breed.reducer';
import transactionsReducer from './transactions/transaction.reducer';

// const farmBlacklistFilter = createBlacklistFilter(
//   'farms',
//   ['farmImage', 'farmImages']
// );

// const animalBlacklistFilter = createBlacklistFilter(
//   'animals',
//   ['animalImage', 'animalImages']
// );

const persistConfig = {
  key: 'root',
  storage,
  whitelist: [
    'user',
    'farms',
    'camps',
    'breeds',
    'animals',
    'devices',
    'documents',
    'products',
    'articles',
    'inspections',
    'cart',
    'orders',
    'subscriptions',
    'reminders',
    'transactions',
  ],
  // transforms: [farmBlacklistFilter],
};

const rootReducer = combineReducers({
  user: userReducer,
  farms: farmDetailsReducer,
  camps: campReducer,
  breeds: breedsReducer,
  animals: animalDetailsReducer,
  images: imageReducer,
  devices: deviceReducer,
  events: eventsReducer,
  notifications: notificationsReducer,
  reminders: remindersReducer,
  documents: documentsReducer,
  products: productsReducer,
  cart: cartReducer,
  orders: ordersReducer,
  subscriptions: subscriptionsReducer,
  transactions: transactionsReducer,
  errors: errorsReducer,
});

export default persistReducer(persistConfig, rootReducer);
