import styled from 'styled-components';
import { Grid, Card, Paper } from '@material-ui/core';
import { CustomButtonDefault } from '../../components/shared/custom-button/custom-button.styles';
import TableCell from '@material-ui/core/TableCell';

export const MainColor = styled.div`
  color: #7fb986;
`;

export const ColorGray = styled.div`
  color: gray;
`;

export const TextCenter = styled(Grid)`
  text-align: center;
`;

export const DisplayFlex = styled.div`
  display: flex;
`;

export const Bold = styled.div`
  font-weight: bold;
`;

export const Margin10TopBottom = styled.div`
  margin: 10px 0;
`;

export const Margin10LeftRight = styled.div`
  margin: 0 10px;
`;

export const PageTitle = styled.div`
  text-align: center;
  color: #7fb986;
  padding: 20px;
  margin: 20px auto;
`;

export const CategoryTitle = styled(PageTitle)`
  color: black;
  padding: 0px;
  margin: 5px auto;
`;

export const GridTextCenter = styled(Grid)`
  text-align: center;
`;

export const CardFullWidth = styled(Card)`
  width: 100%;
  margin: 10px 0;
`;

export const GridPadding20 = styled(Grid)`
  padding: 20px;
`;

export const GridPadding10 = styled(Grid)`
  padding: 10px;
`;

export const PaddingLeft24 = styled(Grid)`
  padding-left: 24px;
`;

export const GriDisplayFlex = styled(Grid)`
  display: flex;
`;

export const GriDisplayFlexEnd = styled(Grid)`
  display: flex;
  justify-content: flex-end;
`;

export const GridRowAlignCenter = styled(Grid)`
  display: flex;
  align-items: center;
`;

export const GriDisplayFlexCenter = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const GridColumn = styled(Grid)`
  display: flex;
  flex-direction: column;
  padding: 10px;
`;

export const Column = styled(Grid)`
  padding: 10px;
  display: flex;
  flex-direction: column;
`;

export const FlexSpaceBetween = styled(Grid)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const ColumnFlexEnd = styled(Grid)`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
`;

export const TimelinePaper = styled(Paper)`
  padding: 24px;
  background: rgba(145, 158, 171, 0.12);
`;

export const EditButton = styled(CustomButtonDefault)`
  background: #7fb986;
`;

export const DeleteButton = styled(CustomButtonDefault)`
  background-color: red;
`;

export const TableItem = styled(TableCell)`
  padding: 2px 4px;
  border-bottom: none;
  color: #7fb986;
  font-weight: bold;
`;

export const TableItemStatus = styled(TableItem)`
  color: black;
`;

export const AcceptItem = styled(Grid)`
  display: flex;
  flex-direction: column;
  text-align: center;
  border-bottom: 1px solid silver;
  padding: 10px;
`;

export const TableRowBorder = styled(FlexSpaceBetween)`
  padding: 10px 0;
  border-bottom: 1px solid #eaeaea;
`;

export const TableRowNoBorder = styled(FlexSpaceBetween)`
  ${'' /* padding: 10px 0; */}
`;
