import { all, call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { firestore } from "../../firebase/firebase.utils";
import {
  breedFetchFailed,
  breedFetchSuccess,
  clearBreeds,
} from "./breed.actions";
import farmActionTypes from "../farms/farm.action-types";
import { convertCollectionsSnapshotToMap } from "../../firebase/firebase.functions";
import userActionTypes from "../users/user.types";
import breedActionTypes from "./breed.action-types";
import { handleError } from "../../utils/errors/errorHandler";

export function* fetchAllBreeds(action) {
  try {
    const collectionRef = firestore.collection("breeds");
    const snapshot = yield collectionRef.get();
    const collectionsMap = yield call(
      convertCollectionsSnapshotToMap,
      snapshot
    );
    yield put(breedFetchSuccess(collectionsMap));
  } catch (error) {
    handleError(error);
    yield put(breedFetchFailed(error));
  }
}

export function* onFarmFetchSuccess() {
  yield takeEvery(farmActionTypes.FETCH_FARMS_SUCCESS, fetchAllBreeds);
}

export function* onBreedFetchStart() {
  yield takeEvery(breedActionTypes.START_BREED_FETCH, fetchAllBreeds);
}

export function* clearBreedsData() {
  yield put(clearBreeds());
}

export function* onUserSignout() {
  yield takeLatest(userActionTypes.SIGN_OUT_SUCCESS, clearBreedsData);
}

export function* onUserSignin() {
  yield takeLatest(userActionTypes.SIGN_IN_SUCCESS, fetchAllBreeds);
}

export function* breedSagas() {
  yield all([
    call(onFarmFetchSuccess),
    call(onBreedFetchStart),
    call(onUserSignout),
    call(onUserSignin),
  ]);
}
