import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { updateUserStart } from '../../../redux/users/user.actions';
import CustomButton from '../../shared/custom-button/custom-button.component';
import FormInput from '../../shared/form-input/form-input.component';
import { selectCurrentUser } from '../../../redux/users/user.selectors';
import { MainContainer } from '../../../styles/info.pages/info.page.styles';

class ProfileEdit extends React.Component<any> {
  state = {
    error: null,
    userDetails: {},
  };

  componentDidMount() {
    this.setState({
      userDetails: {
        ...this.props.user,
      },
    });
  }

  handleSubmission = async (event) => {
    event.preventDefault();

    const { user, editUser, history } = this.props;
    if (!user) return;
    editUser({
      ...this.state.userDetails,
      repCode: ((this.state.userDetails as any)?.repCode ?? '')
        .toString()
        .toUpperCase()
        .replace(' ', ''),
    });
    history.push(`/profile/info/${user?.userId ?? user?.id}`);
  };

  handleChange = async (event) => {
    var { name, value } = event.target;
    if (name === 'repCode')
      value = value.toString().toUpperCase().replace(' ', '');
    this.setState({
      userDetails: {
        ...this.state.userDetails,
        [name]: value,
      },
    });
  };

  render() {
    if (!this.props.user) return <div>PLEASE LOG IN TO CREATE A FARM</div>;
    const {
      error,
      userDetails
    } = this.state;

    const { displayName, email, occupation, bio, repCode } = userDetails as any;

    return (
      <MainContainer maxWidth="sm">
        <h2>Edit Profile</h2>
        <span className="error-message">{(error as any)?.message}</span>
        <form className="sign-up-form" onSubmit={this.handleSubmission}>
          <FormInput
            type="text"
            name="displayName"
            label="Display Name"
            value={displayName ?? ''}
            onChange={this.handleChange}
            handleChange={this.handleChange}
            required
          />
          <FormInput
            type="email"
            name="email"
            value={email ?? ''}
            onChange={this.handleChange}
            handleChange={this.handleChange}
            label="Email"
            required
          />
          <FormInput
            type="text"
            name="occupation"
            value={occupation ?? ''}
            onChange={this.handleChange}
            handleChange={this.handleChange}
            label="Occupation"
            required
          />
          <FormInput
            type="text"
            name="repCode"
            value={repCode?.toString().toUpperCase().replace(' ', '') ?? ''}
            onChange={this.handleChange}
            handleChange={this.handleChange}
            label="Agent Code"
          />
          <FormInput
            type="text"
            name="bio"
            value={bio ?? ''}
            onChange={this.handleChange}
            handleChange={this.handleChange}
            label="Bio"
            required
          />
          <CustomButton type="submit">EDIT PROFILE</CustomButton>
        </form>
      </MainContainer>
    );
  }
}

const mapUserToProps = createStructuredSelector({
  user: selectCurrentUser,
});

const mapDispatchToProps = (dispatch) => ({
  editUser: (userDetails) => dispatch(updateUserStart(userDetails)),
});

export default connect(
  mapUserToProps,
  mapDispatchToProps
)(withRouter(ProfileEdit));
