import { Icon } from '@iconify/react';
import arrowIosDownwardFill from '@iconify/icons-eva/arrow-ios-downward-fill';
import {
  Accordion,
  Typography,
  AccordionSummary,
  AccordionDetails,
  Box,
} from '@material-ui/core';
import MotionInView from '../animate/MotionInView';
import { varFadeIn } from '../animate/FadeIn';

export default function AccordionItem({ icon, title, children, expanded=false}) {
  const expandedprops = {expanded: expanded ?? false }
  if(!expanded) delete expandedprops.expanded;
  return (
    <MotionInView variants={varFadeIn} mb={2}>
      <Accordion {...expandedprops}>
        <AccordionSummary
          expandIcon={
            <Icon icon={arrowIosDownwardFill} width={20} height={20} />
          }
        >
          <>
            <Box mr={1}>{icon ?? null}</Box>
            <Typography variant="subtitle1">{title}</Typography>
          </>
        </AccordionSummary>
        <AccordionDetails>
          <Box width="100%">{children}</Box>
        </AccordionDetails>
      </Accordion>
    </MotionInView>
  );
}
