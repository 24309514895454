// import { signOut } from "../../firebase/firebase.functions";

export function handleError(error:any, context:any) {
    switch(error.code)
    {
        case 'permission-denied':
            window.alert('Your session has expired. Please log in again to perform this action.');
            window.location.href = '/profile/logout';
            break;

        default:
            console.log('ERROR',error);
            console.log('CONTEXT',context);
            break;

    }

    return error;
}