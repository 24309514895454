import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { firestore } from '../../firebase/firebase.utils';
import documentActionTypes from './document.action-types';
import {
  clearDocuments,
  deleteFileFailure,
  deleteFileSuccess,
  documentAnimalFetchFailer,
  documentAnimalFetchSuccess,
  documentFarmFetchFailer,
  documentFarmFetchSuccess,
  documentFetchFailed,
  documentFetchSuccess,
  documentUploadFailed,
  documentUploadSuccess,
} from './document.actions';
import {
  convertCollectionsSnapshotToMap,
  uploadFileToStorage,
} from '../../firebase/firebase.functions';
import UserActionTypes from '../users/user.types';
import { handleError } from '../../utils/errors/errorHandler';

export function* createNewDocumentAsync(action) {
  
  const data = action.payload.data;
  let fileInfo = { ...action.payload };
  delete fileInfo.data;
  try {
    const collectionRef = firestore.collection('documents');
    const addedDocumentRef = yield collectionRef.add(fileInfo);
    if (data) {
      try {
        let mimeType =
          fileInfo.type ?? data.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0];
        let fileType =
          fileInfo.path?.split('.')?.pop() ?? data.match(/[^:/]\w+(?=;|,)/)[0];
        if (!mimeType || !fileType) return;

        let documentUploadResp = yield uploadFileToStorage(
          data,
          `${mimeType}/${addedDocumentRef.id}.${fileType}`,
          mimeType
        );
        let documentURL = documentUploadResp.url;
        yield addedDocumentRef.update({
          linkUrl: documentURL,
          preview: documentURL,
        });
        fileInfo.linkUrl = documentURL;
        fileInfo.preview = documentURL;
      } catch (error) {
        yield addedDocumentRef.update({ data });
      }
    }
    yield put(
      documentUploadSuccess({ documentId: addedDocumentRef.id, ...fileInfo })
    );
  } catch (error:any) {
    handleError(error, action);
    yield put(documentUploadFailed(error));
  }
}

export function* fetchFarmDocumentsAsync(action) {
  const farmId = action.payload;
  if (!farmId) return;
  try {
    const collectionRef = firestore
      .collection('documents')
      .where('farmIds', 'array-contains', farmId);
    const snapshot = yield collectionRef.get();
    const collectionsMap = yield call(
      convertCollectionsSnapshotToMap,
      snapshot
    );
    yield put(documentFarmFetchSuccess(collectionsMap));
  } catch (error:any) {
    handleError(error, action);
    yield put(documentFarmFetchFailer(error.message));
  }
}

export function* fetchAnimalDocumentsAsync(action) {
  const animalId = action.payload;
  if (!animalId) return;
  try {
    const collectionRef = firestore
      .collection('documents')
      .where('animalIds', 'array-contains', animalId);
    const snapshot = yield collectionRef.get();
    const collectionsMap = yield call(
      convertCollectionsSnapshotToMap,
      snapshot
    );
    yield put(documentAnimalFetchSuccess(collectionsMap));
  } catch (error:any) {
    handleError(error, action);
    yield put(documentAnimalFetchFailer(error));
  }
}

export function* fetchUserDocuments(action) {
  const user = action.payload;
  const userId = user.id ?? user.uid;
  if (!userId) return;
  try {
    const collectionRef = firestore
      .collection('documents')
      .where('userIds', 'array-contains', userId);
    const snapshot = yield collectionRef.get();
    const collectionsMap = yield call(
      convertCollectionsSnapshotToMap,
      snapshot
    );
    yield put(documentFetchSuccess(collectionsMap));
  } catch (error:any) {
    handleError(error, action);
    yield put(documentFetchFailed(error.message));
  }
}

export function* deleteDocumentDetailsAsync(action) {
  const file = action.payload;
  if (!file) return;
  try {
    const fileRef = firestore.doc(`documents/${file.id ?? file.documentId}`);
    const updatedFileRef = yield fileRef.get();
    if (updatedFileRef.exists) {
      const updatedFileDetails = { deleted: true };
      delete updatedFileRef.id;
      yield fileRef.update(updatedFileDetails);
      yield put(
        deleteFileSuccess({
          id: file?.id ?? file?.documentId,
          deleted: true,
          ...file,
        })
      );
    }
  } catch (error:any) {
    handleError(error, action);
    yield put(deleteFileFailure(error));
  }
}

export function* onDocumentUploadStart() {
  yield takeEvery(
    documentActionTypes.START_DOCUMENT_UPLOAD,
    createNewDocumentAsync
  );
}

export function* onDocumentDelete() {
  yield takeLatest(
    documentActionTypes.DELETE_DOCUMENT_START,
    deleteDocumentDetailsAsync
  );
}

export function* onUserSelect() {
  yield takeLatest(UserActionTypes.SIGN_IN_SUCCESS, fetchUserDocuments);
}

export function* onFarmDocumentFetch() {
  yield takeLatest(
    documentActionTypes.DOCUMENT_FARM_FETCH_START,
    fetchFarmDocumentsAsync
  );
}

export function* onAnimalDocumentFetch() {
  yield takeLatest(
    documentActionTypes.DOCUMENT_ANIMAL_FETCH_START,
    fetchAnimalDocumentsAsync
  );
}

export function* clearDocumentsData() {
  yield put(clearDocuments());
}

export function* onUserSignout() {
  yield takeLatest(UserActionTypes.SIGN_OUT_SUCCESS, clearDocumentsData);
}

export function* documentSagas() {
  yield all([
    call(onDocumentUploadStart),
    call(onUserSelect),
    call(onUserSignout),
    call(onDocumentDelete),
    call(onFarmDocumentFetch),
    call(onAnimalDocumentFetch),
  ]);
}
