import React from 'react';
import { TextField } from '@material-ui/core';

const TextInputfield = ({ name, register, label, required, ...otherProps }) => {

  const configTextfield = {
    ...otherProps,
    name,
    label,
    fullWidth: true,
  };

  return required?
    <TextField {...configTextfield} {...register(name??label, {required: 'Please Enter ' + label ?? 'Field' })}/>
    :<TextField {...configTextfield} {...register(name??label)}/>;
};

export default TextInputfield;
