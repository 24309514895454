import { all, call, put, takeLatest } from "redux-saga/effects";
import notificationActionTypes from "./notification.action-types";
import { createNotificationSuccess, createNotificationFailure } from "./notification.actions";

export function* createNewNotificationAsync(action) {
    if(!action?.payload?.notification) return;
    const notification = action?.payload?.notification;
    const notificationId = action.payload.data['google.c.a.c_id'] ?? notification.title;
    try{
        yield put(createNotificationSuccess({notificationId, ...notification}));
    } catch(error) {
        yield put(createNotificationFailure(error));
    }
}

export function* onNotificationCreate() {
    yield takeLatest(
        notificationActionTypes.CREATE_NOTIFICATION_START, 
        createNewNotificationAsync
    );
}


export function* notificationSagas() {
    yield all([
        call(onNotificationCreate)
    ])
}