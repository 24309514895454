import { createSelector } from 'reselect';
import { convertObjectToArray } from '../../utils/array/mapper';
import { UserState } from './user.reducer';

const selectUser = (state:any) => state.user as UserState | undefined;

export const selectCurrentUser = createSelector<any, any>(
  [selectUser],
  (user) => user?.currentUser
);

export const selectAdmin = createSelector<any, any>([selectCurrentUser], (user) => {
  if (!user || !user.isAdmin) return false
  else return user.isAdmin
});

export const selectCurrentAgent = createSelector<any, any>(
  [selectUser],
  (user) => user?.currentAgent
);

export const selectUserLoading = createSelector<any, any>(
  [selectUser],
  (user) => user?.processing
);

export const selectRepCode = createSelector<any, any>(
  [selectUser],
  (user) => user?.currentUser?.repCode ?? user?.repCode
);

export const selectVisibleUsers = createSelector<any, any>(
  [selectUser],
  (user) => user?.visibleUsers
);

export const selectVisibleUsersAsArray = createSelector<any,Array<any>>(
  [selectVisibleUsers],
  (users) => convertObjectToArray(users??{})
);

export const selectCurrentUserOnboarded = createSelector<any, any>(
  [selectCurrentUser],
  (currentUser) => currentUser?.hasDoneOnboarding
);

export const selectCurrentUserPrivacy = createSelector<any, any>(
  [selectCurrentUser],
  (currentUser) => currentUser?.hasDonePrivacy
);

export const selectCurrentUserTerms = createSelector<any, any>(
  [selectCurrentUser],
  (currentUser) => currentUser?.hasDoneTerms
);

export const selectUserByEmail = (email) =>
  createSelector<any, any>([selectVisibleUsersAsArray], (users) =>
    users
      ? users.filter((u) => u.email.toLowerCase() === email.toLowerCase())
      : []
  );
