import { createSelector } from 'reselect';
import { convertObjectToArray } from '../../utils/array/mapper';
import { selectCurrentFarm } from '../farms/farm.selectors';

export const selectSubscriptionState = (state) => state.subscriptions;

export const selectSubscriptions = createSelector<any, any>(
  [selectSubscriptionState],
  (s) => s.subscriptions
);

export const selectAllSubscriptionsAsArray = createSelector<any, any>(
  [selectSubscriptions],
  (subscriptions) => convertObjectToArray(subscriptions)
);

export const selectActiveSubscriptionsAsArray = createSelector<any, any>(
  [selectSubscriptions],
  (subscriptions) =>
    subscriptions.filter((o) => o.paymentSuccess && !o.paymentFailure)
);

export const selectSubscriptionByFarmId = function(farmId){
  return createSelector<any, any>([selectAllSubscriptionsAsArray], (allSubs) =>
    allSubs?.find((s) => s.farmId === farmId)
  );
}

 export const selectCurrentFarmIsPastExpiry = createSelector<any, any>(
    [selectAllSubscriptionsAsArray, selectCurrentFarm], 
    (allSubs, farm) => {
      const subscription = allSubs?.find((s) => s.farmId === (farm?.id ?? farm?.farmId));
      if(!subscription) return false;
      
      const expiryDate = new Date(
        subscription?.trialEndDate?.seconds
          ? subscription?.trialEndDate?.seconds * 1000
          : subscription?.trialEndDate
      );

      return expiryDate < new Date() ? true : false;
    }
  );

export const selectFarmIsSubscribedOrTrial = (farmId) =>
  createSelector<any, any>([selectAllSubscriptionsAsArray], (allSubs) => {
    const subscription = allSubs?.find((s) => s.farmId === farmId);
    if (!subscription) return false;

    const expiryDate = new Date(
      subscription?.trialEndDate?.seconds
        ? subscription?.trialEndDate?.seconds * 1000
        : subscription?.trialEndDate
    );

    const isExpired = expiryDate < new Date() ? true : false;
    const isSubscribed =
      subscription?.paymentSuccess &&
      subscription?.paymentProcessedDate &&
      subscription?.active
        ? true
        : false;

    return !isExpired || isSubscribed;
  });

export const selectCurrentFarmIsSubscribedOrTrial = createSelector<any, any>(
  [selectAllSubscriptionsAsArray, selectCurrentFarm],
  (allSubs, farm) => {
    const subscription = allSubs?.find(
      (s) => s.farmId === (farm?.id ?? farm?.farmId)
    );
    if (!subscription) return false;

    const expiryDate = new Date(
      subscription?.trialEndDate?.seconds
        ? subscription?.trialEndDate?.seconds * 1000
        : subscription?.trialEndDate
    );

    const isExpired = expiryDate < new Date() ? true : false;
    const isSubscribed =
      subscription?.paymentSuccess &&
      subscription?.paymentProcessedDate &&
      subscription?.active
        ? true
        : false;

    return !isExpired || isSubscribed;
  }
);

export const selectAgentSubscriptions = createSelector<any, any>(
  [selectSubscriptionState],
  (subscriptions) => subscriptions.agentSubscriptions
);

export const selectCurrentAgentSubscriptionsAsArray = createSelector<any, any>(
  [selectAgentSubscriptions],
  (subscriptions) => convertObjectToArray(subscriptions)
);
