import { takeLatest, put, all, call, takeLeading } from 'redux-saga/effects';

import UserActionTypes from './user.types';
import { facebookProvider, firestore, auth, googleProvider } from '../../firebase/firebase.utils';
import {
  convertCollectionsSnapshotToMap,
  createUserProfileDocument,
  getCurrentUser,
} from '../../firebase/firebase.functions';
import {
  emailSignUpFailed,
  fetchAgentFailure,
  fetchAgentSuccess,
  passwordResetFailure,
  passwordResetSuccess,
  signInFailed,
  signInSuccess,
  signOutFailed,
  signOutStart,
  signOutSuccess,
  updateUserFailure,
  updateUserSuccess,
  visibleUserFetchFailure,
  visibleUserFetchSuccess,
} from './user.actions';
import { handleError } from '../../utils/errors/errorHandler';
import { sendPasswordResetEmail, signInWithRedirect, createUserWithEmailAndPassword as createEmail, signInWithEmailAndPassword as authEmail, signInWithPopup} from "firebase/auth";

export function* getSnapshotFromUserAuth(userAuth) {
  try {
    const userProfileRef = yield call(createUserProfileDocument, userAuth, null);
    const userSnapshot = yield userProfileRef.get();
    yield put(signInSuccess({ id: userSnapshot.id, ...userSnapshot.data() }));
  } catch (error) {
    handleError(error, userAuth);
    yield put(signInFailed(error));
  }
}

export function* signInWithGoogle() {
  try {
    const userRef = yield signInWithPopup(auth, googleProvider);
    //const userRef = yield signInWithRedirect(auth, googleProvider);
    yield getSnapshotFromUserAuth(userRef.user);
    window.location.href = '/farms';
  } catch (error) {
    try{
      // const userRef = yield signInWithPopup(auth, googleProvider);
      const userRef = yield signInWithRedirect(auth, googleProvider);
      yield getSnapshotFromUserAuth(userRef.user);
      window.location.href = '/farms';
    }
    catch(error){
      handleError(error, auth);
      yield put(signInFailed(error));
    }
  }
}

export function* signInWithFacebook() {
  try {
    // const userRef = yield signInWithPopup(auth, facebookProvider);
    const userRef = yield signInWithRedirect(auth, facebookProvider);
    yield getSnapshotFromUserAuth(userRef.user);
    window.location.href = '/';
  } catch (error) {
    handleError(error, auth);
    yield put(signInFailed(error));
  }
}

export function* onGoogleSignInStart() {
  yield takeLatest(UserActionTypes.GOOGLE_SIGN_IN_START, signInWithGoogle);
}

export function* onFacebookSignInStart() {
  yield takeLatest(UserActionTypes.FACEBOOK_SIGN_IN_START, signInWithFacebook);
}

export function* signInWithEmailAndPassword({ payload: { email, password } }) {
  try {
    const userRef = yield authEmail(auth, email.toLowerCase(), password);
    yield getSnapshotFromUserAuth(userRef.user);
    window.location.href = '/';
  } catch (error) {
    handleError(error, email);
    yield put(signInFailed(error));
  }
}

export function* onEmailSignInStart() {
  yield takeLatest<any>(
    UserActionTypes.EMAIL_SIGN_IN_START,
    signInWithEmailAndPassword
  );
}

export function* signUpWithEmailAndPassword({
  payload: { email, password, displayName },
}) {
  try {
    const userRef = yield createEmail(auth, email, password);
    yield getSnapshotFromUserAuth({ ...userRef.user, displayName });
    window.location.href = '/';
  } catch (error) {
    handleError(error, email);
    yield put(emailSignUpFailed(error));
  }
}

export function* onEmailSignUpStart() {
  yield takeLatest<any>(
    UserActionTypes.EMAIL_SIGN_UP_START,
    signUpWithEmailAndPassword
  );
}

export function* resetPasswordAsync({ payload: { email } }) {
  try {
    yield sendPasswordResetEmail(auth, email.toLowerCase());
    yield put(passwordResetSuccess({ message: 'Email send successfully' }));
  } catch (error) {
    handleError(error, email);
    yield put(passwordResetFailure(error));
  }
}

export function* onPasswordResetStart() {
  yield takeLatest<any>(UserActionTypes.PASSWORD_RESET_START, resetPasswordAsync);
}

export function* checkUserAuthenticated() {
  try {
    const userAuth = yield getCurrentUser();
    if (!userAuth) return yield put(signOutStart());
    yield getSnapshotFromUserAuth(userAuth);
  } catch (error) {
    handleError(error, null);
    yield put(signInFailed(error));
  }
}

export function* onUserSessionCheck() {
  yield takeLeading(UserActionTypes.CHECK_USER_SESSION, checkUserAuthenticated);
}

export function* signOutUser() {
  try {
    yield auth.signOut();
    yield put(signOutSuccess());
  } catch (error) {
    handleError(error, null);
    yield put(signOutFailed(error));
  }
}

export function* onSignOutStart() {
  yield takeLatest(UserActionTypes.SIGN_OUT_START, signOutUser);
}

export function* updateUserDetailsAsync(action) {
  const userDetails = action.payload;
  if (!userDetails) return;
  try {
    const userRef = firestore.doc(`users/${userDetails.id}`);
    const updatedUserRef = yield userRef.get();
    if (updatedUserRef.exists) {
      const updatedUserDetails = { ...userDetails };
      delete updatedUserDetails.userId;
      delete updatedUserDetails.id;
      yield userRef.update(updatedUserDetails);
      yield put(updateUserSuccess({ userId: userDetails.id, ...userDetails }));
    }
  } catch (error) {
    handleError(error, action);
    yield put(updateUserFailure(error));
  }
}

export function* addUserTokenAsync(action) {
  const { user, token } = action.payload;
  if (!user || !token) return;
  if (user.messageTokens && user.messageTokens.includes(token)) return;
  try {
    const userRef = firestore.doc(`users/${user?.id ?? user?.uid}`);
    const snapShot = yield userRef.get();
    if (snapShot.exists) {
      const userDoc = yield snapShot.data();
      let messageTokens = userDoc.messageTokens
        ? [...userDoc.messageTokens.splice(0,4)]
        : [];
      if (!messageTokens.includes(token)) messageTokens.unshift(token);
      if(messageTokens.length>5) messageTokens = messageTokens.splice(0,5);
      yield userRef.update({
        messageTokens,
      });
      yield put(
        updateUserSuccess({ userId: snapShot.id, ...user, messageTokens })
      );
    }
  } catch (error:any) {
    handleError(error,action);
    yield put(updateUserFailure(error));
  }
}

export function* fetchAgentAsync({ payload }) {
  try {
    const collectionRef = firestore
      .collection('users')
      .where('repCode', '==', payload);
    const snapshot = yield collectionRef.get();
    const collectionsMap = yield call(
      convertCollectionsSnapshotToMap,
      snapshot
    );
    const agentDetails = Object.values(collectionsMap).at(0) ?? {
      error: 'noAgent',
    };
    yield put(fetchAgentSuccess(agentDetails));
  } catch (error) {
    handleError(error, payload);
    yield put(fetchAgentFailure(error));
  }
}

export function* onUserUpdate() {
  yield takeLatest(
    UserActionTypes.PROFILE_UPDATE_START,
    updateUserDetailsAsync
  );
}

export function* fetchUserByEmail(action) {
  const email = action.payload;
  if (!email) return;
  try {
    const collectionRef = firestore
      .collection('users')
      .where('email', '==', email.toLowerCase());
    const snapshot = yield collectionRef.get();
    const collectionsMap = yield call(
      convertCollectionsSnapshotToMap,
      snapshot
    );
    yield put(visibleUserFetchSuccess(collectionsMap));
  } catch (error:any) {
    handleError(error, action);
    yield put(visibleUserFetchFailure(error.message));
  }
}

export function* onUserEmailSearch() {
  yield takeLatest(UserActionTypes.CHECK_USER_EMAIL_EXISTS, fetchUserByEmail);
}

export function* onTokenUpdate() {
  yield takeLatest(UserActionTypes.TOKEN_UPDATE_START, addUserTokenAsync);
}

export function* onAgentFetch() {
  yield takeLatest<any>(UserActionTypes.FETCH_AGENT_START, fetchAgentAsync);
}

export function* userSagas() {
  yield all([
    call(onGoogleSignInStart),
    call(onFacebookSignInStart),
    call(onEmailSignInStart),
    call(onUserSessionCheck),
    call(onSignOutStart),
    call(onEmailSignUpStart),
    call(onPasswordResetStart),
    call(onUserUpdate),
    call(onUserEmailSearch),
    call(onTokenUpdate),
    call(onAgentFetch),
  ]);
}
