import { all, call, put, takeLatest } from '@redux-saga/core/effects';
import { convertCollectionsSnapshotToMap } from '../../firebase/firebase.functions';
import { firestore } from '../../firebase/firebase.utils';
import { handleError } from '../../utils/errors/errorHandler';
import {
  createTransactionFailure,
  createTransactionSuccess,
  deleteTransactionFailure,
  deleteTransactionSuccess,
  editTransactionFailure,
  editTransactionSuccess,
  getTransactionsFailure,
  getTransactionsSuccess,
} from './transaction.actions';
import transactionActionTypes from './transaction.types';

export function* fetchTransactionsAsync({ payload }) {
  if (!payload) return;
  try {
    const collectionRef = firestore
      .collection('transactions')
      .where('creatingUserId', '==', payload);
    const snapshot = yield collectionRef.get();
    const collectionsMap = yield call(
      convertCollectionsSnapshotToMap,
      snapshot
    );
    yield put(getTransactionsSuccess(collectionsMap));
  } catch (error) {
    handleError(error);
    yield put(getTransactionsFailure(error.message));
  }
}

export function* createNewTransactionAsync(action) {
  const transactionDetails = action.payload;
  if (!transactionDetails) return;
  try {
    const collectionRef = firestore.collection('transactions');
    const addedTransactionRef = yield collectionRef.add(transactionDetails);
    yield put(
      createTransactionSuccess({
        transactionId: addedTransactionRef.id,
        ...transactionDetails,
      })
    );
  } catch (error) {
    handleError(error);
    yield put(createTransactionFailure(error));
  }
}

export function* editTransactionAsync(action) {
  const transactionDetails = action.payload;
  if (!transactionDetails) return;
  try {
    const transactionRef = firestore.doc(
      `transactions/${
        transactionDetails?.id ?? transactionDetails?.transactionId
      }`
    );
    const snapShot = yield transactionRef.get();
    if (snapShot.exists) {
      const updatedTransactionDetails = { ...transactionDetails };
      delete updatedTransactionDetails.id;
      yield transactionRef.update(updatedTransactionDetails);
      yield put(editTransactionSuccess(transactionDetails));
    }
  } catch (error) {
    handleError(error);
    yield put(editTransactionFailure(error.message));
  }
}

export function* deleteTransactionAsync(action) {
  const transactionId = action.payload;
  if (!transactionId) return;
  try {
    const transactionRef = firestore.doc(`transactions/${transactionId}`);
    const updatedTransactionRef = yield transactionRef.get();
    if (updatedTransactionRef.exists) {
      const updatedTransactionDetails = { deleted: true };
      yield transactionRef.update(updatedTransactionDetails);
      yield put(
        deleteTransactionSuccess({
          transactionId: transactionId,
          ...updatedTransactionRef,
          ...updatedTransactionDetails,
        })
      );
    }
  } catch (error) {
    handleError(error);
    yield put(deleteTransactionFailure(error));
  }
}

export function* onTransactionsFetch() {
  yield takeLatest(
    transactionActionTypes.GET_TRANSACTIONS_START,
    fetchTransactionsAsync
  );
}

export function* onTransactionCreate() {
  yield takeLatest(
    transactionActionTypes.CREATE_TRANSACTION_START,
    createNewTransactionAsync
  );
}

export function* onTransactionEdit() {
  yield takeLatest(
    transactionActionTypes.EDIT_TRANSACTION_START,
    editTransactionAsync
  );
}

export function* onTransactionDelete() {
  yield takeLatest(
    transactionActionTypes.DELETE_TRANSACTION_START,
    deleteTransactionAsync
  );
}

export function* transactionSagas() {
  yield all([
    call(onTransactionsFetch),
    call(onTransactionCreate),
    call(onTransactionEdit),
    call(onTransactionDelete),
  ]);
}
