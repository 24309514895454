import {convertObjectToArray} from '../../utils/array/mapper'
export const mapStringToFilters = ({filters}) => {
    const pairs = filters.split('&');

  var array = pairs.map((el) => {
    
    const parts = el.split('=');
    return parts;
  });

  return Object.fromEntries(array);
}

export const replaceCampAnimals = (stateAnimals, campAnimals) => {
  if(!stateAnimals || !campAnimals) return stateAnimals;
  const campAnimalArray = convertObjectToArray(campAnimals);
  if(campAnimalArray?.length===0) return stateAnimals;
  const stateAnimalArray = convertObjectToArray(stateAnimals).filter(a=>a.campId!==campAnimalArray[0].campId);
  return [...stateAnimalArray, ...campAnimalArray].reduce((a,c) => ({...a, [c.id]:c}), {})
}


