import errorActionTypes from './error.action-types';

export const selectError = (errorId) => ({
  type: errorActionTypes.SELECT_ERROR,
  payload: errorId,
});

export const clearErrors = () => ({
  type: errorActionTypes.CLEAR_ERRORS,
});

export const createErrorStart = (errorData) => ({
  type: errorActionTypes.LOG_ERROR_START,
  payload: errorData,
});

export const createErrorSuccess = (errorDetails) => ({
  type: errorActionTypes.LOG_ERROR_SUCCESS,
  payload: errorDetails,
});

export const createErrorFailure = (error) => ({
  type: errorActionTypes.LOG_ERROR_FAILURE,
  payload: error,
});
