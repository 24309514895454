import { all, call, takeLatest, put } from "redux-saga/effects"
import UserActionTypes from "../users/user.types"
import { clearCart } from "./cart-actions"

export function* clearCartAtSignOut() {
    yield put(clearCart())
}

export function* onUserSignout() {
    yield takeLatest(
        UserActionTypes.SIGN_OUT_SUCCESS,
        clearCartAtSignOut
    )
}

export function* cartSagas() {
    yield all([
        call(onUserSignout)
    ])
}