import productActionTypes from './product.action-types';

export const selectProduct = (productId) => ({
  type: productActionTypes.SELECT_PRODUCT,
  payload: productId,
});

export const clearProducts = () => ({
  type: productActionTypes.CLEAR_PRODUCTS,
});

export const fetchProducts = () => ({
  type: productActionTypes.FETCH_PRODUCTS_START,
});

export const fetchProductsSuccess = (productDetails) => ({
  type: productActionTypes.FETCH_PRODUCTS_SUCCESS,
  payload: productDetails,
});

export const fetchProductsFailure = (error) => ({
  type: productActionTypes.FETCH_PRODUCTS_FAILURE,
  payload: error,
});

export const fetchProductStart = (productId) => ({
  type: productActionTypes.FETCH_PRODUCT_START,
  payload: productId,
});

export const fetchProductSuccess = (productDetails) => ({
  type: productActionTypes.FETCH_PRODUCT_SUCCESS,
  payload: productDetails,
});

export const fetchProductFailure = (error) => ({
  type: productActionTypes.FETCH_PRODUCT_FAILURE,
  payload: error,
});

export const createProductStart = (productDetails) => ({
  type: productActionTypes.CREATE_PRODUCT_START,
  payload: productDetails,
});

export const createProductSuccess = (productDetails) => ({
  type: productActionTypes.CREATE_PRODUCT_SUCCESS,
  payload: productDetails,
});

export const createProductFailure = (error) => ({
  type: productActionTypes.CREATE_PRODUCT_FAILURE,
  payload: error,
});

export const editProductStart = (productId) => ({
  type: productActionTypes.EDIT_PRODUCT_START,
  payload: productId,
});

export const editProductSuccess = (productDetails) => ({
  type: productActionTypes.EDIT_PRODUCT_SUCCESS,
  payload: productDetails,
});

export const editProductFailure = (error) => ({
  type: productActionTypes.EDIT_PRODUCT_FAILURE,
  payload: error,
});

export const deleteProductStart = (productId) => ({
  type: productActionTypes.DELETE_PRODUCT_START,
  payload: productId,
});

export const deleteProductSuccess = (productId) => ({
  type: productActionTypes.DELETE_PRODUCT_SUCCESS,
  payload: productId,
});

export const deleteProductFailure = (error) => ({
  type: productActionTypes.DELETE_PRODUCT_FAILURE,
  payload: error,
});

export const updateProductImagesSuccess = (productDetails) => {
  return({
    type: productActionTypes.UPDATE_PRODUCT_IMAGES_SUCCESS,
    payload: productDetails,
  });
};