import { all, call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { firestore } from "../../firebase/firebase.utils";
import imageActionTypes from "./image.action-types";
import farmActionTypes from "../farms/farm.action-types";
import {
  clearImages,
  imageUploadFailed,
  imageUploadSuccess,
  startImageUpload,
} from "./image.actions";
import { uploadFileToStorage} from "../../firebase/firebase.functions";
import animalActionTypes from "../animals/animal.action-types";
import {
  addAnimalImagesSuccess,
  deleteAnimalSuccess,
} from "../animals/animal.actions";
import { updateFarmImagesSuccess } from "../farms/farm.actions";
import campActionTypes from "../camps/camp.action-types";
import { updateCampImagesSuccess } from "../camps/camp.actions";
import { containsUrl } from "../../utils/string/contains";
import userActionTypes from "../users/user.types";
import { handleError } from "../../utils/errors/errorHandler";
import productActionTypes from "../products/product.action-types";
import { Animal } from "../../common";

export function* createNewImageAsync(action) {
  const data = action?.payload?.data;
  const fileInfo = { ...action?.payload };
  delete fileInfo.data;
  try {
    if (data) {
      if (containsUrl(data)) return;
      const collectionRef = firestore.collection("images");

      let uploadInfo = { ...fileInfo, createdAt: new Date() };
      if (uploadInfo.animalDetails) delete uploadInfo.animalDetails;
      if (uploadInfo.campDetails) delete uploadInfo.campDetails;
      if (uploadInfo.farmDetails) delete uploadInfo.farmDetails;
      if (uploadInfo.productDetails) delete uploadInfo.productDetails;

      const addedImageRef = yield collectionRef.add(uploadInfo);

      const mimeTypeMatch = data.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/);
      const mimeType = mimeTypeMatch ? mimeTypeMatch[0] : 'application/image';
      const fileTypeMatch= data.match(/[^:/]\w+(?=;|,)/);
      const fileType = fileTypeMatch ? fileTypeMatch[0] : 'dat' ;
      if (!mimeType || !fileType) return;
      let imageUploadResp = yield uploadFileToStorage(
        data,
        `${mimeType}/${addedImageRef.id}.${fileType}`,
        mimeType
      );
      
      let imageURL = yield imageUploadResp.url;

      yield addedImageRef.update({ linkUrl: imageURL, updatedAt: new Date() });

      yield put(
        imageUploadSuccess({ imageId: addedImageRef.id, ...action.payload })
      );
      try {
        if (fileInfo.animalIds && fileInfo.animalIds?.length > 0) {
          const animalRef = firestore.doc(`animals/${fileInfo.animalIds[0]}`);
          const snapShot = yield animalRef.get();
          if (snapShot.exists) {
            const animalDoc = yield snapShot.data();

            let animalImages =
              fileInfo?.animalDetails?.animalImages ?? animalDoc?.animalImages;
            if (!animalImages) animalImages = [];
            animalImages = animalImages.filter((i) => i !== data);
            animalImages = animalImages.filter((i) => i !== imageURL);
            animalImages.unshift(imageURL);

            yield animalRef.update({ animalImages });
            yield put(
              addAnimalImagesSuccess({
                animalId: snapShot?.id,
                ...animalDoc,
                animalImages,
              })
            );
          } else {
            yield put(
              deleteAnimalSuccess({
                id: fileInfo.animalIds[0],
                animalId: fileInfo.animalIds[0],
              } as Animal)
            );
          }
        }
        //image saga
        if (fileInfo.farmIds && fileInfo.farmIds?.length > 0) {
          const farmRef = firestore.doc(`farms/${fileInfo.farmIds[0]}`);
          const farmShot = yield farmRef.get();
          if (farmShot.exists) {
            const farmDoc = yield farmShot.data();

            let farmImages =
              fileInfo?.farmDetails?.farmImages ?? farmDoc?.farmImages;
            if (!farmImages)
              farmImages = fileInfo?.farmDetails?.farmImage
                ? [fileInfo?.farmDetails?.farmImage]
                : [farmDoc?.farmImage];
            if (!farmImages) farmImages = [];

            farmImages = farmImages.filter((i) => i !== data);
            farmImages = farmImages.filter((i) => i !== imageURL);
            farmImages.unshift(imageURL);
            yield farmRef.update({ farmImages, farmImage: imageURL });
            yield put(
              updateFarmImagesSuccess({
                id: farmShot?.id,
                farmId: farmShot?.id,
                ...farmDoc,
                farmImages,
                farmImage: imageURL,
              })
            );
          }
        }

        if (fileInfo.campIds && fileInfo.campIds?.length > 0) {
          const campRef = firestore.doc(`camps/${fileInfo.campIds[0]}`);
          const campShot = yield campRef.get();
          if (campShot.exists) {
            const campDoc = yield campShot.data();

            let campImages =
              fileInfo?.campDetails?.campImages ?? campDoc?.campImages;
            if (!campImages)
              campImages = fileInfo?.campDetails?.campImage
                ? [fileInfo?.campDetails?.campImage]
                : [campDoc?.campImage];
            if (!campImages) campImages = [];

            campImages = campImages.filter((i) => i !== data);
            campImages = campImages.filter((i) => i !== imageURL);
            campImages.unshift(imageURL);

            yield campRef.update({ campImages, campImage: imageURL });
            yield put(
              updateCampImagesSuccess({
                campId: campShot?.id,
                ...campDoc,
                campImages,
                campImage: imageURL,
              })
            );
          }
        }

        if (fileInfo.productIds && fileInfo.productIds?.length > 0) {
          const productRef = firestore.doc(`products/${fileInfo.productIds[0]}`);
          const productShot = yield productRef.get();
          if (productShot.exists) {
            const productDoc = yield productShot.data();

            let productImages =
              fileInfo?.productDetails?.productImages ?? productDoc?.productImages;
            if (!productImages)
              productImages = fileInfo?.productDetails?.productImage
                ? [fileInfo?.productDetails?.productImage]
                : [productDoc?.productImage];
            if (!productImages) productImages = [];

            productImages = productImages.filter((i) => i !== data);
            productImages = productImages.filter((i) => i !== imageURL);
            productImages.unshift(imageURL);
            yield productRef.update({ productImages, productImage: imageURL });
            yield put(
              updateProductImagesSuccess({
                id: productShot?.id,
                productId: productShot?.id,
                ...productDoc,
                productImages,
                productImage: imageURL,
              })
            );
          }
        }
      } catch (error) {
        console.log("ERROR", error);
        handleError(error, action);
      }
    }
  } catch (error) {
    console.log("ERROR", error);
    yield put(imageUploadFailed(error));
  }
}

export function* createNewImageFromAnimalDataAsync(action) {
  const animalDetails = action.payload;
  if (animalDetails?.animalImages && animalDetails?.animalImages?.length > 0) {
    const animalImage = animalDetails?.animalImages.find(
      (i) => !containsUrl(i)
    );
    if (animalImage)
      yield put(
        startImageUpload({
          animalIds: [animalDetails.animalId ?? animalDetails.id],
          data: animalImage,
          animalDetails,
        })
      );
  }
}

export function* createNewImageFromFarmDataAsync(action) {
  const farmDetails = action.payload;
  if (farmDetails?.farmImages && farmDetails?.farmImages?.length > 0) {
    const farmImage = farmDetails?.farmImages.find((i) => !containsUrl(i));
    if (farmImage)
      yield put(
        startImageUpload({
          farmIds: [farmDetails.farmId ?? farmDetails.id],
          data: farmImage,
          farmDetails,
        })
      );
  }
  else if (farmDetails?.farmImage) {
    if (!containsUrl(farmDetails?.farmImage)) {
      yield put(
        startImageUpload({
          farmIds: [farmDetails.farmId ?? farmDetails.id],
          data: farmDetails?.farmImage,
          farmDetails,
        })
      );
    }
  }
}

export function* createNewImageFromProductDataAsync(action) {
  const productDetails = action.payload;
  if (productDetails?.productImages && productDetails?.productImages?.length > 0) {
    const productImage = productDetails?.productImages.find((i) => !containsUrl(i));
    if (productImage)
      yield put(
        startImageUpload({
          productIds: [productDetails.productId ?? productDetails.id],
          data: productImage,
          productDetails,
        })
      );
  }
  else if (productDetails?.productImage) {
    if (!containsUrl(productDetails?.productImage)) {
      yield put(
        startImageUpload({
          productIds: [productDetails.productId ?? productDetails.id],
          data: productDetails?.productImage,
          productDetails,
        })
      );
    }
  }
}

export function* createNewImageFromCampDataAsync(action) {
  const campDetails = action.payload.campDetails ?? action.payload;
  if (campDetails?.campImages && campDetails?.campImages?.length > 0) {
    const campImage = campDetails?.campImages.find((i) => !containsUrl(i));
    if (campImage)
      yield put(
        startImageUpload({
          campIds: [campDetails.campId ?? campDetails.id],
          data: campImage,
          campDetails: campDetails,
        })
      );
  }
  else if (campDetails?.campImage) {
    const campDetailsContainsUrl = containsUrl(campDetails?.campImage);
    if (!campDetailsContainsUrl) {
      yield put(
        startImageUpload({
          campIds: [campDetails.campId ?? campDetails.id],
          data: campDetails?.campImage,
          campDetails: campDetails,
        })
      );
    }
  }
}

export function* onImageUploadStart() {
  yield takeEvery(imageActionTypes.START_IMAGE_UPLOAD, createNewImageAsync);
}

export function* onAnimalCreateSuccess() {
  yield takeEvery(
    animalActionTypes.CREATE_ANIMAL_SUCCESS,
    createNewImageFromAnimalDataAsync
  );
}

export function* onAnimalUpdateSuccess() {
  yield takeEvery(
    animalActionTypes.UPDATE_ANIMAL_SUCCESS,
    createNewImageFromAnimalDataAsync
  );
}

export function* onFarmCreateSuccess() {
  yield takeEvery(
    farmActionTypes.CREATE_FARM_SUCCESS,
    createNewImageFromFarmDataAsync
  );
}

export function* onFarmUpdateSuccess() {
  yield takeEvery(
    farmActionTypes.UPDATE_FARM_SUCCESS,
    createNewImageFromFarmDataAsync
  );
}
export function* onCampCreateSuccess() {
  yield takeEvery(
    campActionTypes.CREATE_CAMP_SUCCESS,
    createNewImageFromCampDataAsync
  );
}

export function* onCampUpdateSuccess() {
  yield takeEvery(
    campActionTypes.UPDATE_CAMP_SUCCESS,
    createNewImageFromCampDataAsync
  );
}

export function* onProductCreateSuccess() {
  yield takeEvery(
    productActionTypes.CREATE_PRODUCT_SUCCESS,
    createNewImageFromProductDataAsync
  );
}

export function* onProductUpdateSuccess() {
  yield takeEvery(
    productActionTypes.EDIT_PRODUCT_SUCCESS,
    createNewImageFromProductDataAsync
  );
}

export function* clearImagesData() {
  yield put(clearImages());
}

export function* onUserSignout() {
  yield takeLatest(userActionTypes.SIGN_OUT_SUCCESS, clearImagesData);
}

export function* imageSagas() {
  yield all([
    call(onImageUploadStart),
    call(onAnimalUpdateSuccess),
    call(onAnimalCreateSuccess),
    call(onFarmCreateSuccess),
    call(onFarmUpdateSuccess),
    call(onProductCreateSuccess),
    call(onProductUpdateSuccess),
    call(onCampCreateSuccess),
    call(onCampUpdateSuccess),
    call(onUserSignout),
  ]);
}
