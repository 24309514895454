import React from 'react'
import Loading from './loading.component'
import './loading.styles.scss'

export default function LoadingFullScreen() {
    return (
        <div className='full-screen-loading' onClick={()=>{console.log("Loading")}}>
            <Loading />
        </div>
    )
}
