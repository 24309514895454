import UserActionTypes from './user.types';

export const checkUserSession = () => ({
  type: UserActionTypes.CHECK_USER_SESSION,
});

export const setProfileComplete = () => ({
  type: UserActionTypes.SET_PROFILE_COMPLETE,
});

export const setOnboardingDone = () => ({
  type: UserActionTypes.SET_ONBOARDING_DONE,
});

export const googleSignInStart = () => ({
  type: UserActionTypes.GOOGLE_SIGN_IN_START,
});

export const emailSignInStart = (emailAddressAndPassword) => ({
  type: UserActionTypes.EMAIL_SIGN_IN_START,
  payload: emailAddressAndPassword,
});

export const passwordResetStart = (email) => ({
  type: UserActionTypes.PASSWORD_RESET_START,
  payload: email,
});

export const passwordResetSuccess = (success) => ({
  type: UserActionTypes.PASSWORD_RESET_SUCCESS,
  payload: success,
});

export const passwordResetFailure = (error) => ({
  type: UserActionTypes.PASSWORD_RESET_FAILURE,
  payload: error,
});

export const facebookSignInStart = () => ({
  type: UserActionTypes.FACEBOOK_SIGN_IN_START,
});

export const signInSuccess = (user) => ({
  type: UserActionTypes.SIGN_IN_SUCCESS,
  payload: user,
});

export const signInFailed = (error) => ({
  type: UserActionTypes.SIGN_IN_FAILED,
  payload: error,
});

export const emailSignUpStart = ({ email, password, displayName }) => ({
  type: UserActionTypes.EMAIL_SIGN_UP_START,
  payload: { email, password, displayName },
});

export const emailSignUpFailed = (error) => ({
  type: UserActionTypes.EMAIL_SIGN_UP_FAILED,
  payload: error,
});

export const signOutStart = () => ({
  type: UserActionTypes.SIGN_OUT_START,
});

export const signOutSuccess = () => ({
  type: UserActionTypes.SIGN_OUT_SUCCESS,
});

export const signOutFailed = (error) => ({
  type: UserActionTypes.SIGN_OUT_FAILED,
  payload: error,
});

export const updateUserStart = (userDetails) => ({
  type: UserActionTypes.PROFILE_UPDATE_START,
  payload: userDetails,
});

export const updateUserAgent = (agentCode) => ({
  type: UserActionTypes.REP_UPDATE_START,
  payload: agentCode,
});

export const updateTokenStart = (user, token) => ({
  type: UserActionTypes.TOKEN_UPDATE_START,
  payload: { user, token },
});

export const updateUserSuccess = (userDetails) => ({
  type: UserActionTypes.PROFILE_UPDATE_SUCCESS,
  payload: userDetails,
});

export const updateUserFailure = (error) => ({
  type: UserActionTypes.PROFILE_UPDATE_FAILED,
  payload: error,
});

export const visibleUserFetchSuccess = (users) => ({
  type: UserActionTypes.VISIBLE_USER_FETCH_SUCCESS,
  payload: users,
});

export const visibleUserFetchFailure = (error) => ({
  type: UserActionTypes.VISIBLE_USER_FETCH_FAILED,
  payload: error,
});

export const fetchAgentStart = (agentCode) => ({
  type: UserActionTypes.FETCH_AGENT_START,
  payload: agentCode,
});

export const fetchAgentSuccess = (agent) => ({
  type: UserActionTypes.FETCH_AGENT_SUCCESS,
  payload: agent,
});

export const fetchAgentFailure = (error) => ({
  type: UserActionTypes.FETCH_AGENT_FAILURE,
  payload: error,
});
