import deviceActionTypes from "./device.action-types";

const INITIAL_STATE = {
    error: undefined,
    processing: false,
    selectedDevice: '',
    visibleDevices: {
    }
    
}

const devicesReducer = (state = INITIAL_STATE, action) => {
    const {type, payload} = action;
    switch(type) {

        case deviceActionTypes.SELECT_DEVICE:
            return {
                ...state,
                selectedDevice: payload
            }

        case deviceActionTypes.CLEAR_DEVICES:
            return INITIAL_STATE;

        case deviceActionTypes.FETCH_DEVICE_START:
            return {
                ...state,
                processing: true
            }

        case deviceActionTypes.FETCH_DEVICE_FAILURE:
            return {
                ...state,
                error: payload,
                processing: false
            }

        case deviceActionTypes.FETCH_DEVICE_SUCCESS:
            return {
                ...state,
                processing: false,
                visibleDevices: {
                    ...state.visibleDevices,
                    ...payload
                }
            }

        default:
            return state;
    }
}

export default devicesReducer;