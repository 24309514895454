import React from 'react'
import './loading.styles.scss'

export default function Loading() {
    return (
        <div className="loader">
            <img width="100px" height="100px" src='/AF-Loader-02.gif' alt='Loading' />
        </div>
    )
}
