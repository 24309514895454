import React from 'react';
import { Info, InfoBold } from '../../../styles/info.pages/info.page.styles';
import { DisplayFlex } from '../../../styles/shared/shared.styles';

const UserStatus = ({ user }) => {
  return (
    <>
      <DisplayFlex>
        <Info>Owned Farms:</Info>
        {/* <InfoBold></InfoBold> */}
      </DisplayFlex>
      <DisplayFlex>
        <Info>Occupation:</Info>
        <InfoBold>{user?.occupation}</InfoBold>
      </DisplayFlex>
      {user?.repCode && (
        <DisplayFlex>
          <Info>Agent:</Info>
          <InfoBold>{user?.repCode}</InfoBold>
        </DisplayFlex>
      )}
    </>
  );
};

export default UserStatus;
