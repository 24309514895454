import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider } from '@material-ui/core/styles';
import { store, persistor } from './redux/store';
import theme from './theme/create-theme';

ReactDOM.render(
<Provider store={store}>
    <BrowserRouter>
      <PersistGate persistor={persistor}>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </PersistGate>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
try{
  serviceWorkerRegistration.register({
    skipWaiting: true,
    onUpdate: registration => {
      const waitingServiceWorker = registration.waiting
      if(waitingServiceWorker) {
        waitingServiceWorker.postMessage({ type: "SKIP_WAITING"});
      }
    }
  });
} catch {
  console.log('Could not start service worker')
}
reportWebVitals();
