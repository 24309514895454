import deviceActionTypes from './device.action-types';

export const selectDevice = (deviceId) => ({
  type: deviceActionTypes.SELECT_DEVICE,
  payload: deviceId,
});

export const clearDevices = () => ({
  type: deviceActionTypes.CLEAR_DEVICES,
});

export const fetchDeviceSuccess = (deviceDetails) => ({
  type: deviceActionTypes.FETCH_DEVICE_SUCCESS,
  payload: deviceDetails,
});

export const fetchDeviceFailure = (error) => ({
  type: deviceActionTypes.FETCH_DEVICE_FAILURE,
  payload: error,
});
