import React, { useEffect, useMemo, useState } from 'react';
import {
  googleSignInStart,
  emailSignInStart,
  facebookSignInStart,
} from '../../../../redux/users/user.actions';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { Box, Container, Grid, Typography, Button } from '@material-ui/core';
import TextField from '../../../shared/react-hook-form/text-field/text-field';
import SubmitButton from '../../../shared/react-hook-form/submit-button/ButtonSubmit';
import {
  GriDisplayFlexEnd,
  GridPadding10,
  MainColor,
  Bold,
} from '../../../../styles/shared/shared.styles';
import { Icon } from '@iconify/react';
import facebookFill from '@iconify/icons-eva/facebook-fill';
import Divider from '../../../shared/divider/divider.component';
import { useForm } from "react-hook-form";
import GoogleImage from '../google-logo.jpg';
import LoadingFullScreen from '../../../shared/loading/loading-fullscreen.component';


const SignIn = ({
  userLoading,
  currentUser,
  googleSignInStart,
  emailSignInStart,
  facebookSignInStart,
  history,
  error,
}) => {

  const { register, handleSubmit } = useForm();
  const [loading, setLoading ] = useState(userLoading ?? false);

  useEffect(() => {
    if (currentUser != null) {
      history.push('/');
    }
  }, [currentUser, history]);

  const onSubmit = (values) => {
    setLoading(true);
    emailSignInStart(values);
  };

  const isFacebookApp = useMemo(() => {
      var ua = navigator.userAgent || navigator.vendor || window['opera'];
      return (ua.indexOf("FBAN") > -1) || (ua.indexOf("FBAV") > -1);
  }, [])

  return loading ? <LoadingFullScreen /> :
    <Box mt={7}>
      <Container maxWidth="sm">
        <GridPadding10>
          <GriDisplayFlexEnd>
            Don’t have an account? &nbsp;
            <Link underline="none" variant="subtitle2" to="/profile/signup">
              <Bold>
                <MainColor>Sign Up</MainColor>
              </Bold>
            </Link>
          </GriDisplayFlexEnd>
        </GridPadding10>
        {error && <span style={{ color: 'red' }}>{error.message}</span>}
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="h4" gutterBottom>
            Sign in
          </Typography>
        </Box>
        <Box py={2}>
          <Grid container>
          {!isFacebookApp && <Grid item xs={6}>
              <Box mr={1}>
                <Button
                  fullWidth
                  size="large"
                  color="inherit"
                  variant="outlined"
                  onClick={() => { setLoading(true); googleSignInStart(); }}
                >
                  <img style={{ height: '24px' }} src={GoogleImage} alt="" />
                </Button>
              </Box>
            </Grid>}
            <Grid item xs={6}>
              <Box ml={1}>
                <Button
                  fullWidth
                  size="large"
                  color="inherit"
                  variant="outlined"
                  // disabled
                  onClick={() => { setLoading(true); facebookSignInStart(); }}
                >
                  <Icon icon={facebookFill} color="#1877F2" height={24} />
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Divider>OR</Divider>
          <form onSubmit={handleSubmit(onSubmit)}>
            <span>Sign in with your email and password</span>
            <Box m={1}>
              <Box py={1}>
                <TextField label="Email" name="email" type="email" register={register}/>
              </Box>
              <Box py={1}>
                <TextField label="Password" name="password" type="password" register={register}/>
              </Box>
              <Box py={2}>
                <GriDisplayFlexEnd>
                  <Link
                    underline="none"
                    variant="subtitle2"
                    to="/profile/forgot-password"
                  >
                    <Bold>
                      <MainColor>Forgot Password?</MainColor>
                    </Bold>
                  </Link>
                </GriDisplayFlexEnd>
              </Box>
              <Box py={1}>
                <SubmitButton type="submit">Sign in</SubmitButton>
              </Box>
            </Box>
          </form>
      </Container>
    </Box>
  
};

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
  userLoading: state.user.processing, 
  error: state.user.error,
});

const mapDispatchToProps = (dispatch) => ({
  googleSignInStart: () => dispatch(googleSignInStart()),
  emailSignInStart: (emailAddressAndPassword) =>
    dispatch(emailSignInStart(emailAddressAndPassword)),
  facebookSignInStart: () => dispatch(facebookSignInStart()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SignIn));
