import { Button } from "@material-ui/core";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import { updateUserStart } from "../../../redux/users/user.actions";
import { selectCurrentUser, selectUserLoading } from "../../../redux/users/user.selectors";
import LoadingFullScreen from "../../shared/loading/loading-fullscreen.component";
import './privacy-policy.styles.scss'

function PrivacyPolicy({ user, history, editUser, loading }) {
  useEffect(() => {
    if (user?.hasDonePrivacy) history.push(`/`);
  }, [user, history]);

  const handleSubmission = async (event) => {
    event.preventDefault(); 
    if (!user) return;
    editUser({ ...user, hasDonePrivacy: true });
  };

  return (
    <div className="iframe">
      <div className="button-box">
        <div className="button-group">
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmission}
          >
            ACCEPT
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => (window.location.href = "/static/WELCOME.html")}
          >
            DECLINE
          </Button>
        </div>
      </div>
      <iframe
        className="frame"
        // ref={"/static/PRIVACY_POLICY.html"}
        src={"/static/PRIVACY_POLICY.html"}
        title="FRAME"
        width="100%"
        height="100%"
        allowFullScreen={true}
        sandbox="allow-forms
                allow-pointer-lock
                allow-popups
                allow-same-origin
                allow-scripts
                allow-top-navigation"
        referrerPolicy="no-referrer"
        allow="camera *; microphone *; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        frameBorder={0}
      />
        {loading && <LoadingFullScreen />}
    </div>
  );
}

const mapStateToProps = createStructuredSelector({
    loading: selectUserLoading,
    user: selectCurrentUser,
});

const mapDispatchToProps = (dispatch) => ({
  editUser: (userDetails) => dispatch(updateUserStart(userDetails)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PrivacyPolicy));
