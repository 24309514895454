import notificationActionTypes from './notification.action-types';

export const selectNotification = (notificationId) => ({
  type: notificationActionTypes.SELECT_NOTIFICATION,
  payload: notificationId,
});

export const clearNotifications = () => ({
  type: notificationActionTypes.CLEAR_NOTIFICATIONS,
});

export const deleteNotificationStart = (notification) => ({
  type: notificationActionTypes.DELETE_NOTIFICATION_START,
  payload: notification
});

export const fetchPublicNotificationsStart = (fromDate) => ({
  type: notificationActionTypes.FETCH_PUBLIC_NOTIFICATIONS_START,
  payload: fromDate
});

export const fetchDeviceNotificationsStart = (device) => ({
  type: notificationActionTypes.FETCH_DEVICE_NOTIFICATIONS_START,
  payload: device
});

export const createNotificationStart = (notificationData) => ({
  type: notificationActionTypes.CREATE_NOTIFICATION_START,
  payload: notificationData,
});

export const createNotificationSuccess = (notificationDetails) => ({
  type: notificationActionTypes.CREATE_NOTIFICATION_SUCCESS,
  payload: notificationDetails,
});

export const createNotificationFailure = (error) => ({
  type: notificationActionTypes.CREATE_NOTIFICATION_FAILURE,
  payload: error,
});


export const deleteNotificationSuccess = (notificationDetails) => ({
  type: notificationActionTypes.DELETE_NOTIFICATION_SUCCESS,
  payload: notificationDetails,
});

export const deleteNotificationFailure = (error) => ({
  type: notificationActionTypes.DELETE_NOTIFICATION_FAILURE,
  payload: error,
});

export const fetchNotificationSuccess = (notificationDetails) => ({
  type: notificationActionTypes.FETCH_NOTIFICATIONS_SUCCESS,
  payload: notificationDetails,
});

export const fetchNotificationFailure = (error) => ({
  type: notificationActionTypes.FETCH_NOTIFICATIONS_FAILURE,
  payload: error,
});
