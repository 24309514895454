import orderActionTypes from './order.action-types';

export const clearOrders = () => ({
  type: orderActionTypes.CLEAR_ORDERS,
});

export const startOrderCreate = (orderInfo) => ({
  type: orderActionTypes.START_ORDER_CREATE,
  payload: orderInfo,
});

export const orderCreateSuccess = (orderInfo) => ({
  type: orderActionTypes.ORDER_CREATE_SUCCESS,
  payload: orderInfo,
});

export const orderCreateFailed = (error) => ({
  type: orderActionTypes.ORDER_CREATE_FAILED,
  payload: error,
});

export const startOrderFetch = (orderId) => ({
  type: orderActionTypes.START_ORDER_FETCH,
  payload: orderId,
});

export const orderFetchSuccess = (orderInfo) => ({
  type: orderActionTypes.ORDER_FETCH_SUCCESS,
  payload: orderInfo,
});

export const orderFetchFailed = (error) => ({
  type: orderActionTypes.ORDER_FETCH_FAILED,
  payload: error,
});

export const startOrderDelete = (orderId) => ({
  type: orderActionTypes.START_ORDER_DELETE,
  payload: orderId,
});

export const orderDeleteSuccess = (orderInfo) => ({
  type: orderActionTypes.ORDER_DELETE_SUCCESS,
  payload: orderInfo,
});

export const orderDeleteFailed = (error) => ({
  type: orderActionTypes.ORDER_DELETE_FAILED,
  payload: error,
});

export const updateOrderStart = (order) => ({
  type: orderActionTypes.UPDATE_ORDER_START,
  payload: order,
});
export const updateOrderSuccess = (order) => ({
  type: orderActionTypes.UPDATE_ORDER_SUCCESS,
  payload: order,
});

export const updateOrderFailure = (error) => ({
  type: orderActionTypes.UPDATE_ORDER_FAILURE,
  payload: error,
});

export const fetchAgentOrdersStart = (agentCode) => ({
  type: orderActionTypes.FETCH_AGENT_ORDERS_START,
  payload: agentCode,
});

export const fetchAgentOrdersSuccess = (agent) => ({
  type: orderActionTypes.FETCH_AGENT_ORDERS_SUCCESS,
  payload: agent,
});

export const fetchAgentOrdersFailure = (error) => ({
  type: orderActionTypes.FETCH_AGENT_ORDERS_FAILURE,
  payload: error,
});
