import imageActionTypes from "./image.action-types";

const INITIAL_STATE = {
  uploadState: null,
  images: {},
  error: null,
};

const imagesReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case imageActionTypes.CLEAR_IMAGES:
      return INITIAL_STATE;

    case imageActionTypes.IMAGE_UPLOAD_SUCCESS:
      return {
        ...state,
        images: {
          ...state.images,
          [payload.imageId ?? payload.id]: payload,
        },
      };

    case imageActionTypes.IMAGE_FETCH_FAILED:
      return {
        ...state,
        error: payload,
      };

    case imageActionTypes.IMAGE_FETCH_SUCCESS:
      return {
        ...state,
        images: {
          ...state.images,
          ...payload,
        },
      };

    default:
      return state;
  }
};

export default imagesReducer;
