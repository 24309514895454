const productActionTypes = {
  FETCH_PRODUCTS_START: 'FETCH_PRODUCTS_START',
  FETCH_PRODUCTS_SUCCESS: 'FETCH_PRODUCTS_SUCCESS',
  FETCH_PRODUCTS_FAILURE: 'FETCH_PRODUCTS_FAILURE',
  SELECT_PRODUCT: 'SELECT_PRODUCT',
  CLEAR_PRODUCTS: 'CLEAR_PRODUCTS',
  CREATE_PRODUCT_START: 'CREATE_PRODUCT_START',
  CREATE_PRODUCT_SUCCESS: 'CREATE_PRODUCT_SUCCESS',
  CREATE_PRODUCT_FAILURE: 'CREATE_PRODUCT_FAILURE',
  FETCH_PRODUCT_START: 'FETCH_PRODUCT_START',
  FETCH_PRODUCT_SUCCESS: 'FETCH_PRODUCT_SUCCESS',
  FETCH_PRODUCT_FAILURE: 'FETCH_PRODUCT_FAILURE',
  EDIT_PRODUCT_START: 'EDIT_PRODUCT_START',
  EDIT_PRODUCT_SUCCESS: 'EDIT_PRODUCT_SUCCESS',
  EDIT_PRODUCT_FAILURE: 'EDIT_PRODUCT_FAILURE',
  DELETE_PRODUCT_START: 'DELETE_PRODUCT_START',
  DELETE_PRODUCT_SUCCESS: 'DELETE_PRODUCT_SUCCESS',
  DELETE_PRODUCT_FAILURE: 'DELETE_PRODUCT_FAILURE',
  UPDATE_PRODUCT_IMAGES_SUCCESS: 'UPDATE_PRODUCT_IMAGES_SUCCESS'
};

export default productActionTypes;
