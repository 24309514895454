import { all, call, put, takeLatest } from "redux-saga/effects";
import { convertCollectionsSnapshotToMap } from "../../firebase/firebase.functions";
import { firestore } from "../../firebase/firebase.utils";
import { convertObjectToArray } from "../../utils/array/mapper";
import { handleError } from "../../utils/errors/errorHandler";
import animalActionTypes from "../animals/animal.action-types";
import campActionTypes from "../camps/camp.action-types";
import userActionTypes from "../users/user.types";
import reminderActionTypes from "./reminder.action-types";
import {
  clearReminders,
  createReminderFailure,
  createReminderSuccess,
  deleteReminderFailure,
  deleteReminderSuccess,
  fetchReminderFailure,
  fetchReminderSuccess,
  updateReminderFailure,
  updateReminderStart,
  updateReminderSuccess,
} from "./reminder.actions";

export function* createNewReminderAsync(action) {
  const reminderDetails = action.payload;
  if (!reminderDetails) return;
  if (reminderDetails.id !== null && reminderDetails.id !== undefined)
    return yield put(updateReminderStart(reminderDetails));
  try {
    const collectionRef = firestore.collection("reminders");
    const addedReminderRef = yield collectionRef.add(reminderDetails);
    yield put(
      createReminderSuccess({
        reminderId: addedReminderRef?.id ?? addedReminderRef.reminderId,
        ...action.payload,
      })
    );
  } catch (error) {
    handleError(error);
    yield put(createReminderFailure(error));
  }
}

export function* updateReminderDetailsAsync(action) {
  const reminderDetails = action.payload;
  if (!reminderDetails) return;
  try {
    const reminderRef = firestore.doc(
      `reminders/${reminderDetails?.id ?? reminderDetails?.reminderId}`
    );
    const updatedReminderRef = yield reminderRef.get();
    if (updatedReminderRef.exists) {
      const updatedReminderDetails = { ...reminderDetails };
      delete updatedReminderDetails.id;
      yield reminderRef.update(updatedReminderDetails);
      yield put(
        updateReminderSuccess({
          reminderId: reminderDetails?.id ?? reminderDetails?.reminderId,
          ...reminderDetails,
        })
      );
    }
  } catch (error) {
    handleError(error);
    yield put(updateReminderFailure(error));
  }
}

export function* deleteReminderDetailsAsync(action) {
  const reminderDetails = action.payload;
  if (!reminderDetails) return;
  try {
    const reminderRef = firestore.doc(
      `reminders/${reminderDetails?.id ?? reminderDetails?.reminderId}`
    );
    const updatedReminderRef = yield reminderRef.get();
    if (updatedReminderRef.exists) {
      yield put(
        deleteReminderSuccess({
          reminderId: reminderDetails?.id ?? reminderDetails?.reminderId,
          ...reminderDetails,
        })
      );
      yield reminderRef.delete();
    }
  } catch (error) {
    handleError(error);
    yield put(deleteReminderFailure(error));
  }
}

export function* fetchCampReminders(action) {
  const campId = action.payload;
  if (!campId) return;
  try {
    const collectionRef = firestore
      .collection("reminders")
      .where("campIds", "array-contains", campId);
    const snapshot = yield collectionRef.get();
    const collectionsMap = yield call(
      convertCollectionsSnapshotToMap,
      snapshot
    );
    yield put(fetchReminderSuccess(collectionsMap));
  } catch (error) {
    handleError(error);
    yield put(fetchReminderFailure(error.message));
  }
}

export function* fetchUserReminders(action) {
  const user = action.payload;
  if (!user) return;
  try {
    const collectionRef = firestore
      .collection("reminders")
      .where("userIds", "array-contains", user.id ?? user.uid);
    const snapshot = yield collectionRef.get();
    const collectionsMap = yield call(
      convertCollectionsSnapshotToMap,
      snapshot
    );
    yield put(fetchReminderSuccess(collectionsMap));
  } catch (error) {
    handleError(error);
    yield put(fetchReminderFailure(error.message));
  }
}

export function* fetchAnimalReminders(action) {
  const animal = action.payload;
  if (!animal) return;
  const animalId = convertObjectToArray(animal)[0].id;
  try {
    const collectionRef = firestore
      .collection("reminders")
      .where("animalIds", "array-contains", animalId);
    const snapshot = yield collectionRef.get();
    const collectionsMap = yield call(
      convertCollectionsSnapshotToMap,
      snapshot
    );
    yield put(fetchReminderSuccess(collectionsMap));
  } catch (error) {
    handleError(error);
    yield put(fetchReminderFailure(error.message));
  }
}

export function* clearRemindersandNotifications(action) {
  yield put(clearReminders());
}
export function* onCampFetched() {
  yield takeLatest(campActionTypes.FETCH_CAMP_SUCCESS, fetchCampReminders);
}

export function* onAnimalsFetched() {
  yield takeLatest(
    animalActionTypes.FETCH_ANIMAL_SUCCESS,
    fetchAnimalReminders
  );
}

export function* onUserSignedIn() {
  yield takeLatest(userActionTypes.SIGN_IN_SUCCESS, fetchUserReminders);
}

export function* onReminderCreate() {
  yield takeLatest(
    reminderActionTypes.CREATE_REMINDER_START,
    createNewReminderAsync
  );
}

export function* onReminderFieldUpdate() {
  yield takeLatest(
    reminderActionTypes.UPDATE_REMINDER_START,
    updateReminderDetailsAsync
  );
}

export function* onUserSignedOut() {
  yield takeLatest(userActionTypes.SIGN_OUT_SUCCESS, clearReminders);
}

export function* onReminderDelete() {
  yield takeLatest(
    reminderActionTypes.DELETE_REMINDER_START,
    deleteReminderDetailsAsync
  );
}

export function* reminderSagas() {
  yield all([
    call(onAnimalsFetched),
    call(onReminderCreate),
    call(onReminderFieldUpdate),
    call(onReminderDelete),
    call(onUserSignedOut),
    call(onUserSignedIn),
  ]);
}
