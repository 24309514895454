import { all, call, put, takeLatest } from 'redux-saga/effects';
import { convertCollectionsSnapshotToMap } from '../../firebase/firebase.functions';
import { firestore } from '../../firebase/firebase.utils';
import { handleError } from '../../utils/errors/errorHandler';
import campActionTypes from '../camps/camp.action-types';
import subscriptionActionTypes from '../subscriptions/subscription.action-types';
import UserActionTypes from '../users/user.types';
import farmActionTypes from './farm.action-types';
import {
  createFarmSuccess,
  createFarmFailure,
  fetchFarmsSuccess,
  fetchFarmsFailure,
  updateFarmFailure,
  updateFarmSuccess,
  addFarmWorkerSuccess,
  addFarmWorkerFailure,
  acceptInvitationSuccess,
  acceptInvitationFailure,
  deleteFarmSuccess,
  deleteFarmFailure,
  clearFarms,
} from './farm.actions';

export function* fetchOwnedFarmsAsync(user) {
  try {
    const collectionRef = firestore
      .collection('farms')
      .where('creatingUserId', '==', user?.id ?? user?.uid);
    const snapshot = yield collectionRef.get();
    const collectionsMap = yield call(
      convertCollectionsSnapshotToMap,
      snapshot
    );
    yield put(fetchFarmsSuccess(collectionsMap));
  } catch (error) {
    handleError(error);
    yield put(fetchFarmsFailure(error.message));
  }
}

export function* fetchWorkingFarmsAsync(user) {
  try {
    const collectionRef = firestore
      .collection('farms')
      .where('workingUsers', 'array-contains', user?.id ?? user?.uid);
    const snapshot = yield collectionRef.get();
    const collectionsMap = yield call(
      convertCollectionsSnapshotToMap,
      snapshot
    );
    yield put(fetchFarmsSuccess(collectionsMap));
  } catch (error) {
    handleError(error);
    yield put(fetchFarmsFailure(error.message));
  }
}

export function* fetchManagingFarmsAsync(user) {
  try {
    const collectionRef = firestore
      .collection('farms')
      .where('managingUsers', 'array-contains', user?.id ?? user?.uid);
    const snapshot = yield collectionRef.get();
    const collectionsMap = yield call(
      convertCollectionsSnapshotToMap,
      snapshot
    );
    yield put(fetchFarmsSuccess(collectionsMap));
  } catch (error) {
    handleError(error);
    yield put(fetchFarmsFailure(error.message));
  }
}

export function* fetchFollowingFarmsAsync(user) {
  try {
    const collectionRef = firestore
      .collection('farms')
      .where('followingUsers', 'array-contains', user?.id ?? user?.uid);
    const snapshot = yield collectionRef.get();
    const collectionsMap = yield call(
      convertCollectionsSnapshotToMap,
      snapshot
    );
    yield put(fetchFarmsSuccess(collectionsMap));
  } catch (error) {
    handleError(error);
    yield put(fetchFarmsFailure(error.message));
  }
}

export function* fetchInvitedFarmsAsync(user) {
  try {
    const collectionRef = firestore
      .collection('farms')
      .where('invitedUsers', 'array-contains', user?.email);
    const snapshot = yield collectionRef.get();
    const collectionsMap = yield call(
      convertCollectionsSnapshotToMap,
      snapshot
    );
    yield put(fetchFarmsSuccess(collectionsMap));
  } catch (error) {
    handleError(error);
    yield put(fetchFarmsFailure(error.message));
  }
}

export function* fetchAllFarms(action) {
  const user = action.payload;
  if (!user) return;
  yield call(fetchOwnedFarmsAsync, user);
  yield call(fetchManagingFarmsAsync, user);
  yield call(fetchWorkingFarmsAsync, user);
  yield call(fetchFollowingFarmsAsync, user);
  yield call(fetchInvitedFarmsAsync, user);
}

export function* onFetchFarmsStart() {
  yield takeLatest(farmActionTypes.FETCH_FARMS_START, fetchAllFarms);
}

export function* onSignInSuccess() {
  yield takeLatest(UserActionTypes.SIGN_IN_SUCCESS, fetchAllFarms);
}

export function* createNewFarmAsync(action) {
  const farmDetails = action.payload;
  if (!farmDetails) return;
  try {
    const collectionRef = firestore.collection('farms');
    let addedFarmRef: any | null = null;
    try {
      let updatedFarmDetails = { ...farmDetails };
      delete updatedFarmDetails.farmImages;
      delete updatedFarmDetails.farmImage;
      addedFarmRef = yield collectionRef.add(updatedFarmDetails);
    } catch (error) {
      yield put(createFarmFailure(error));
    } finally {
      yield put(
        createFarmSuccess({
          id: addedFarmRef?.id ?? addedFarmRef?.farmId,
          farmId: addedFarmRef?.id ?? addedFarmRef?.farmId,
          ...farmDetails,
        })
      );
    }
  } catch (error) {
    handleError(error);
    yield put(createFarmFailure(error));
  }
}

export function* updateFarmDetailsAsync(action) {
  const farmDetails  = action.payload;
  const farmImage = action.payload.farmImage;
  const farmImages = action.payload.farmImages;
  if (!farmDetails) return;
  try {
    const farmRef = firestore.doc(
      `farms/${farmDetails.id ?? farmDetails.farmId}`
    );
    const updatedFarmRef = yield farmRef.get();
    if (updatedFarmRef.exists) {
      const updatedFarmDetails = { ...farmDetails };
      delete updatedFarmDetails.id;
      delete updatedFarmDetails.farmId;
      delete updatedFarmDetails.farmImage;
      delete updatedFarmDetails.farmImages;
      farmRef.update(updatedFarmDetails);
      yield put(
        updateFarmSuccess({
          id: farmRef.id,
          farmId: farmRef.id,
          ...farmDetails,
          farmImage,
          farmImages
        })
      );
    }
  } catch (error) {
    handleError(error);
    yield put(updateFarmFailure(error));
  }
}

export function* addFarmWorkerAsync(action) {
  const { farmDetails, workerDetails } = action.payload;
  if (!farmDetails || !workerDetails) return;

  try {
    const farmRef = firestore.doc(
      `farms/${farmDetails?.id ?? farmDetails?.farmId}`
    );
    const snapShot = yield farmRef.get();
    if (snapShot.exists) {
      const farmDoc: any= yield snapShot.data();
      let invitedUsers = [];
      if (farmDoc.hasOwnProperty('invitedUsers')) {
        yield (invitedUsers = [...farmDoc.invitedUsers, workerDetails]);
      } else {
        yield (farmDoc['invitedUsers'] = invitedUsers);
        yield (invitedUsers = [...farmDoc.invitedUsers, workerDetails]);
      }
      yield farmRef.update({ invitedUsers });
      yield put(addFarmWorkerSuccess(farmDetails, invitedUsers));
    }
  } catch (error) {
    handleError(error);
    yield put(addFarmWorkerFailure(error));
  }
}

export function* fetchSelectedFarmAsync(action) {
  const farmId = action.payload;
  try {
    const farmRef = firestore.doc(`farms/${farmId}`);
    const snapShot = yield farmRef.get();
    if (snapShot.exists) {
      const farmDoc = yield snapShot.data();
      yield put(
        fetchFarmsSuccess({ [farmId]: { id: farmId, farmId, ...farmDoc } })
      );
    }
  } catch (error:any) {
    handleError(error);
    yield put(fetchFarmsFailure(error.message));
  }
}

export function* fetchSelectedCampFarmAsync(action) {
  const farmId = action?.payload?.farmId;
  if (!farmId) return;
  try {
    const farmRef = firestore.doc(`farms/${farmId}`);
    const snapShot = yield farmRef.get();
    if (snapShot.exists) {
      const farmDoc = yield snapShot.data();
      yield put(
        fetchFarmsSuccess({ [farmId]: { id: farmId, farmId, ...farmDoc } })
      );
    }
  } catch (error) {
    handleError(error);
    yield put(fetchFarmsFailure(error.message));
  }
}

export function* acceptFarmInvitationAsync(action) {
  const { farmDetails, user } = action.payload;
  if (!farmDetails || !user) return;
  try {
    const farmRef = firestore.doc(
      `farms/${farmDetails?.id ?? farmDetails?.farmId}`
    );
    const snapShot = yield farmRef.get();
    if (snapShot.exists) {
      const farmDoc = yield snapShot.data();
      let invitedUsers = farmDoc.invitedUsers ?? [];
      let workingUsers = farmDoc.workingUsers ?? [];

      const userEmail = user?.email?.toLowerCase();
      if (invitedUsers.includes(userEmail)) {
        invitedUsers = invitedUsers.filter((u) => u !== userEmail);
        workingUsers.push(user.uid ?? user.id);
      }

      yield farmRef.update({ workingUsers, invitedUsers });
      yield put(
        acceptInvitationSuccess({
          id: snapShot.id,
          farmId: snapShot.id,
          ...farmDoc,
          workingUsers,
          invitedUsers,
        },user)
      );
      yield call(fetchWorkingFarmsAsync, user);
      yield call(fetchInvitedFarmsAsync, user);
      // yield put(farmUpdateSuccess(farmDoc, workingUsers));
    }
  } catch (error) {
    handleError(error);
    yield put(acceptInvitationFailure(error));
  }
}

export function* deleteInvitedWorkerAsync(action) {
  const farmDetails = action.payload.farmDetails;
  if (!farmDetails) return;
  try {
    const farmRef = firestore.doc(`farms/${farmDetails.id}`);
    const updatedFarmRef = yield farmRef.get();
    if (updatedFarmRef.exists) {
      const updatedFarmDetails = { ...farmDetails };
      delete updatedFarmDetails.id;
      delete updatedFarmDetails.farmId;
      farmRef.update(updatedFarmDetails);
      yield put(updateFarmSuccess({ id: farmRef.id, farmId: farmRef.id, ...farmDetails }));
    }
  } catch (error) {
    handleError(error);
    yield put(updateFarmFailure(error));
  }
}

export function* deleteFarmWorkerAsync(action) {
  const farmDetails = action.payload.farmDetails;
  if (!farmDetails) return;
  try {
    const farmRef = firestore.doc(`farms/${farmDetails.id}`);
    const updatedFarmRef = yield farmRef.get();
    if (updatedFarmRef.exists) {
      const updatedFarmDetails = { ...farmDetails };
      delete updatedFarmDetails.id;
      delete updatedFarmDetails.farmId;
      farmRef.update(updatedFarmDetails);
      yield put(updateFarmSuccess({ id: farmRef.id, farmId: farmRef.id, ...farmDetails }));
    }
  } catch (error) {
    handleError(error);
    yield put(updateFarmFailure(error));
  }
}

export function* deleteFarmDetailsAsync(action) {
  const farmDetails = action.payload;
  if (!farmDetails) return;
  try {
    const farmRef = firestore.doc(
      `farms/${farmDetails?.id ?? farmDetails?.farmId}`
    );
    const updatedFarmRef = yield farmRef.get();
    if (updatedFarmRef.exists) {
      const updatedFarmDetails = { deleted: true };
      delete updatedFarmRef.id;
      yield farmRef.update(updatedFarmDetails);
      yield put(
        deleteFarmSuccess({
          id: farmDetails?.id ?? farmDetails?.farmId,
          farmId: farmDetails?.id ?? farmDetails?.farmId,
          ...farmDetails,
        })
      );
    }
  } catch (error) {
    handleError(error);
    yield put(deleteFarmFailure(error));
  }
}

export function* updateFarmSubscription(action) {
  const subDetails = action.payload;
  if (!subDetails?.farmId) return;
  try {
    const farmRef = firestore.doc(`farms/${subDetails.farmId}`);
    const updatedFarmRef = yield farmRef.get();
    if (updatedFarmRef.exists) {
      const updatedFarmDetails = {
        subscriptionId: subDetails.id ?? subDetails.subscriptionId,
      };
      delete updatedFarmDetails.id;
      delete updatedFarmDetails.farmId;
      farmRef.update(updatedFarmDetails);
      yield put(
        updateFarmSuccess({
          ...updatedFarmRef.data(),
          id: subDetails.farmId,
          farmId: subDetails.farmId,
          ...updatedFarmDetails,
        })
      );
    }
  } catch (error) {
    handleError(error);
    yield put(updateFarmFailure(error));
  }
}

export function* onFarmCreate() {
  yield takeLatest(farmActionTypes.CREATE_FARM_START, createNewFarmAsync);
}

export function* onFarmFieldUpdate() {
  yield takeLatest(farmActionTypes.UPDATE_FARM_START, updateFarmDetailsAsync);
}

export function* onFarmWorkerAdd() {
  yield takeLatest(farmActionTypes.ADD_FARM_WORKER_START, addFarmWorkerAsync);
}

export function* onInvitedWorkerCancel() {
  yield takeLatest(
    farmActionTypes.DELETE_INVITED_WORKER_START,
    deleteInvitedWorkerAsync
  );
}

export function* onFarmWorkerCancel() {
  yield takeLatest(
    farmActionTypes.DELETE_FARM_WORKER_START,
    deleteFarmWorkerAsync
  );
}

export function* onFarmInvitation() {
  yield takeLatest(
    farmActionTypes.ACCEPT_INVITATION_START,
    acceptFarmInvitationAsync
  );
}

export function* onFarmSelected() {
  yield takeLatest(farmActionTypes.SELECT_FARM, fetchSelectedFarmAsync);
}

export function* onCampSelected() {
  yield takeLatest(
    campActionTypes.FETCH_SELECTED_CAMP_SUCCESS,
    fetchSelectedCampFarmAsync
  );
}

export function* onSubscriptionCreated() {
  yield takeLatest(
    subscriptionActionTypes.SUBSCRIPTION_CREATE_SUCCESS,
    updateFarmSubscription
  );
}

export function* onFarmDelete() {
  yield takeLatest(farmActionTypes.DELETE_FARM_START, deleteFarmDetailsAsync);
}

export function* clearFarmsData() {
  yield put(clearFarms());
}

export function* onUserSignout() {
  yield takeLatest(UserActionTypes.SIGN_OUT_SUCCESS, clearFarmsData);
}

export function* farmSagas() {
  yield all([
    call(onFetchFarmsStart),
    call(onSignInSuccess),
    call(onFarmCreate),
    call(onFarmFieldUpdate),
    call(onFarmWorkerAdd),
    call(onInvitedWorkerCancel),
    call(onFarmWorkerCancel),
    call(onFarmInvitation),
    call(onFarmDelete),
    call(onFarmSelected),
    call(onCampSelected),
    call(onSubscriptionCreated),
    call(onUserSignout),
  ]);
}
