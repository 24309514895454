import styled from 'styled-components';

const backgroundColor = 'black';
const textColor = 'white';
const altColor = '#7fb986';

export const CustomButtonDefault = styled.button`
  background-color: ${backgroundColor};
  color: ${textColor};
  width: 100%;
  height: 50px;
  line-height: 50px;
  padding: 0 35px 0 35px;
  letter-spacing: 0.5px;
  font-size: 15px;
  text-transform: uppercase;
  font-weight: bolder;
  cursor: pointer;

  &:hover {
    background-color: ${textColor};
    color: ${backgroundColor};
  }

  &.google-sign-in {
    background-color: ${altColor};
    &:hover {
      background-color: ${textColor};
      color: ${altColor};
      border-color: ${altColor};
    }
  }

  &.Save {
    background-color: blue;
  }
`;
