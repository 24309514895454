import { all, call, put, takeLatest } from 'redux-saga/effects';
import { firestore } from '../../firebase/firebase.utils';
import { handleError } from '../../utils/errors/errorHandler';
import feedbackActionTypes from './feedback.action-types';
import {
  createFeedbackSuccess,
  createFeedbackFailure,
} from './feedback.actions';

export function* createNewFeedbackAsync(action) {
  const feedbackDetails = action.payload;
  if (!feedbackDetails) return;
  try {
    const collectionRef = firestore.collection('feedbacks');
    const addedFeedbackRef = yield collectionRef.add(feedbackDetails);
    yield put(
      createFeedbackSuccess({
        feedbackId: addedFeedbackRef.id,
        ...feedbackDetails,
      })
    );
  } catch (error) {
    handleError(error);
    yield put(createFeedbackFailure(error));
  }
}

export function* onFeedbackCreate() {
  yield takeLatest(
    feedbackActionTypes.CREATE_FEEDBACK_START,
    createNewFeedbackAsync
  );
}

export function* feedbackSagas() {
  yield all([call(onFeedbackCreate)]);
}
