const imageActionTypes = {
  CLEAR_IMAGES: "CLEAR_IMAGES",
  START_IMAGE_FETCH: "START_IMAGE_FETCH",
  IMAGE_FETCH_SUCCESS: "IMAGE_FETCH_SUCCESS",
  IMAGE_FETCH_FAILED: "IMAGE_FETCH_FAILED",

  START_IMAGE_UPLOAD: "START_IMAGE_UPLOAD",
  IMAGE_UPLOAD_SUCCESS: "IMAGE_UPLOAD_SUCCESS",
  IMAGE_UPLOAD_FAILED: "IMAGE_UPLOAD_FAILED",
};

export default imageActionTypes;
