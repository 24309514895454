const deviceActionTypes = {
    ATTACH_DEVICE_START: 'ATTACH_DEVICE_START',
    ATTACH_DEVICE_SUCCESS: 'ATTACH_DEVICE_SUCCESS',
    ATTACH_DEVICE_FAILURE: 'ATTACH_DEVICE_FAILURE',
    DETACH_DEVICE_START: 'DETACH_DEVICE_START',
    DETACH_DEVICE_SUCCESS: 'DETACH_DEVICE_SUCCESS',
    DETACH_DEVICE_FAILURE: 'DETACH_DEVICE_FAILURE',
    FETCH_CAMP_DEVICES_START: 'FETCH_CAMP_DEVICES_START',
    FETCH_CAMP_DEVICES_SUCCESS: 'FETCH_CAMP_DEVICES_SUCCESS',
    FETCH_CAMP_DEVICES_FAILURE: 'FETCH_CAMP_DEVICES_FAILURE',
    FETCH_DEVICE_START: 'FETCH_DEVICE_START',
    FETCH_DEVICE_SUCCESS: 'FETCH_DEVICE_SUCCESS',
    FETCH_DEVICE_FAILURE: 'FETCH_DEVICE_FAILURE',
    SELECT_DEVICE: 'SELECT_DEVICE',
    CLEAR_DEVICES: 'CLEAR_DEVICES'
}

export default deviceActionTypes;