import { eventChannel } from "@redux-saga/core";
import { all, call, put, takeLatest, take } from "redux-saga/effects";
import { convertCollectionsSnapshotToMap } from "../../firebase/firebase.functions";
import { firestore } from "../../firebase/firebase.utils";
import { convertObjectToArray } from "../../utils/array/mapper";
import { handleError } from "../../utils/errors/errorHandler";
import {
  addAnimalFeedSuccess,
  addCampAnimalFeedSuccess,
} from "../animals/animal.actions";
import farmDetailsActionTypes from "../farms/farm.action-types";
import userActionTypes from "../users/user.types";
import campActionTypes from "./camp.action-types";
import {
  fetchCampFailure,
  fetchCampSuccess,
  createCampSuccess,
  createCampFailure,
  updateCampFailure,
  updateCampSuccess,
  addCampFeedSuccess,
  addCampFeedFailure,
  fetchSelectedCampSuccess,
  deleteCampSuccess,
  deleteCampFailure,
  restoreCampSuccess,
  restoreCampFailure,
  clearCamps,
  setProcessingStart,
  fetchAllCampSuccess,
} from "./camp.actions";

export function* createNewCampAsync(action) {
  const campDetails = action.payload;
  if (!campDetails) return;
  try {
    const collectionRef = firestore.collection("camps");
    let addedCampRef = null;
    try {
      let updatedCampDetails = { ...campDetails };
      delete updatedCampDetails.campImages;
      delete updatedCampDetails.campImage;
      addedCampRef = yield collectionRef.add(updatedCampDetails);
    } catch (error) {
      yield put(createCampFailure(error));
    } finally {
      yield put(
        createCampSuccess({
          campId: addedCampRef.id ?? addedCampRef.campId,
          ...campDetails,
        })
      );
    }
  } catch (error) {
    handleError(error);
    yield put(createCampFailure(error));
  }
}

export function* updateCampDetailsAsync(action) {
  const campDetails = action.payload;
  if (!campDetails) return;
  try {
    const campRef = firestore.doc(
      `camps/${campDetails?.id ?? campDetails?.campId}`
    );
    const updatedCampRef = yield campRef.get();
    if (updatedCampRef.exists) {
      const updatedCampDetails = { ...campDetails };
      delete updatedCampDetails.id;
      try {
        delete updatedCampDetails.campImage;
        delete updatedCampDetails.campImages;
        yield campRef.update(updatedCampDetails);
      } catch (error) {
        yield put(updateCampFailure(error));
      } finally {
        yield put(
          updateCampSuccess({
            campId: campDetails?.id ?? campDetails?.campId,
            ...campDetails,
          })
        );
      }
    } else {
      yield put(deleteCampSuccess(campDetails));
    }
  } catch (error) {
    handleError(error);
    yield put(updateCampFailure(error));
  }
}

export function* deleteLatestCampFeedAsync(action) {
  const { animalsDetails, campDetails } = action.payload;
  if (!campDetails) return;
  try {
    const campRef = firestore.doc(
      `camps/${campDetails?.id ?? campDetails?.campId}`
    );
    const snapShot = yield campRef.get();
    const feedKeysAsArray = Object.keys(campDetails.feedData);
    feedKeysAsArray.sort().pop();
    const feedData = Object.keys(campDetails.feedData)
      .filter((key) => feedKeysAsArray.includes(key))
      .reduce((feedObject, key) => {
        return {
          ...feedObject,
          [key]: campDetails.feedData[key],
        };
      }, {});
    if (snapShot.exists) {
      yield campRef.update({ feedData });
      yield put(addCampFeedSuccess(campDetails, feedData));
    }

    for (let i = 0; i < animalsDetails?.length; i++) {
      const animalRef = firestore.doc(
        `animals/${animalsDetails[i].id ?? animalsDetails[i].animalId}`
      );
      const animalFeedShot = yield animalRef.get();
      const animalFeed = Object.keys(animalsDetails[i].animalFeed)
        .filter((key) => feedKeysAsArray.includes(key))
        .reduce((animalFeedObj, key) => {
          return {
            ...animalFeedObj,
            [key]: animalsDetails[i].animalFeed[key],
          };
        }, {});
      if (animalFeedShot.exists) {
        yield animalRef.update({ animalFeed });
        yield put(addAnimalFeedSuccess(animalsDetails[i], animalFeed));
      }
    }
  } catch (error) {
    handleError(error);
    yield put(addCampFeedFailure(error));
  }
}

export function* deleteCampDetailsAsync(action) {
  const campDetails = action.payload;
  if (!campDetails) return;
  try {
    const campRef = firestore.doc(
      `camps/${campDetails?.id ?? campDetails?.campId}`
    );
    const updatedCampRef = yield campRef.get();
    if (updatedCampRef.exists) {
      const updatedCampDetails = { deleted: true };
      delete updatedCampRef.id;
      yield campRef.update(updatedCampDetails);
      yield put(
        deleteCampSuccess({
          campId: campDetails?.id ?? campDetails?.campId,
          ...campDetails,
        })
      );
    }
  } catch (error) {
    handleError(error);
    yield put(deleteCampFailure(error));
  }
}

export function* restoreCampDetailsAsync(action) {
  const campDetails = action.payload;
  if (!campDetails) return;
  try {
    const campRef = firestore.doc(
      `camps/${campDetails?.id ?? campDetails?.campId}`
    );
    const updatedCampRef = yield campRef.get();
    if (updatedCampRef.exists) {
      delete updatedCampRef.id;
      delete campDetails.deleted;
      yield campRef.set(campDetails);
      yield put(
        restoreCampSuccess({
          campId: campDetails?.id ?? campDetails?.campId,
          ...campDetails,
        })
      );
    }
  } catch (error) {
    handleError(error);
    yield put(restoreCampFailure(error));
  }
}

export function* fetchAllCamps(action) {
  yield put(setProcessingStart());
  const user = action.payload;
  if (!user) return;
  try {
    const collectionRef = firestore
      .collection("camps")
      .where("creatingUserId", "==", user?.id ?? user?.uid);
    const snapshot = yield collectionRef.get();
    const collectionsMap = yield call(
      convertCollectionsSnapshotToMap,
      snapshot
    );
    yield put(fetchAllCampSuccess(collectionsMap));

    const campChannel = eventChannel((emit) => collectionRef.onSnapshot(emit));

    try {
      while (true) {
        const data = yield take(campChannel);
        const dataMap = yield call(convertCollectionsSnapshotToMap, data);
        yield put(fetchAllCampSuccess(dataMap));
      }
    } catch (err) {
      yield put(fetchCampFailure(err));
    }
  } catch (error) {
    handleError(error);
    yield put(fetchCampFailure(error.message));
  }
}

export function* fetchFarmCamps(action) {
  yield put(setProcessingStart());
  const farmId = action.payload;
  if (!farmId) return;
  try {
    const collectionRef = firestore
      .collection("camps")
      .where("farmId", "==", farmId);
    const snapshot = yield collectionRef.get();
    const collectionsMap = yield call(
      convertCollectionsSnapshotToMap,
      snapshot
    );
    yield put(fetchCampSuccess(collectionsMap));

    const campChannel = eventChannel((emit) => collectionRef.onSnapshot(emit));

    try {
      while (true) {
        const data = yield take(campChannel);
        const dataMap = yield call(convertCollectionsSnapshotToMap, data);
        yield put(fetchCampSuccess(dataMap));
      }
    } catch (err) {
      yield put(fetchCampFailure(err));
    }
  } catch (error) {
    handleError(error);
    yield put(fetchCampFailure(error.message));
  }
}

export function* fetchSelectedCamp(action) {
  const campId = action.payload;
  if (!campId) return;
  try {
    const campRef = firestore.doc(`camps/${campId}`);
    const snapShot = yield campRef.get();
    if (snapShot.exists) {
      const campDoc = yield snapShot.data();
      yield put(
        fetchSelectedCampSuccess({
          [campId]: { id: campId, campId, ...campDoc },
        })
      );

      const campChannel = eventChannel((emit) => campRef.onSnapshot(emit));

      try {
        while (true) {
          const data = yield take(campChannel);
          fetchSelectedCampSuccess({
            [campId]: { id: campId, campId, ...data },
          });
        }
      } catch (err) {
        yield put(fetchCampFailure(err));
      }
    }
  } catch (error:any) {
    handleError(error);
    yield put(fetchCampFailure(error.message));
  }
}

export function* addCampFeedAsync(action) {
  const { animalsDetails, campDetails, feedInfo } = action.payload;
  if (!feedInfo || !campDetails) return;
  try {
    const campRef = firestore.doc(
      `camps/${campDetails.id ?? campDetails.campId}`
    );
    const snapShot = yield campRef.get();
    if (snapShot.exists) {
      const campDoc = yield snapShot.data();
      const feedData = {
        ...campDoc.feedData,
        [feedInfo.dateTime.toISOString()]: feedInfo,
      };
      yield campRef.update({ feedData });
      yield put(addCampFeedSuccess(campDetails, feedData));
    }
    const allAnimalWeights = animalsDetails.map((animal) => ({
      id: animal.id ?? animal?.animalId,
      weights: convertObjectToArray(animal.weightData),
    }));
    const totalCampAnimalsWeight = allAnimalWeights.reduce((total:number, animal) => {
      const animalWeights = animal.weights;
      if(!animalWeights) return total;
      animalWeights.sort((a, b) => new Date(a.weightDate).getTime() - new Date(b.weightDate).getTime());
      const lastWeight = animalWeights[animalWeights?.length - 1];
      return total + lastWeight.weight;
      }, 0);
      
    for (let i = 0; i < animalsDetails?.length; i++) {
      const animalRef = firestore.doc(
        `animals/${animalsDetails[i]?.id ?? animalsDetails[i]?.animalId}`
      );
      const animalWeights = allAnimalWeights[i].weights;
      if(!animalWeights) continue;
      animalWeights.sort((a, b) => new Date(a.weightDate).getTime() - new Date(b.weightDate).getTime());
      const animalWeight = animalWeights[animalWeights?.length - 1].weight;
      const animalFeedQuantity =
        (animalWeight * feedInfo.quantity) / totalCampAnimalsWeight;
      const animalFeedCost =
        (animalWeight * feedInfo.cost) / totalCampAnimalsWeight;
      const animalSnapShot = yield animalRef.get();
      if (animalSnapShot.exists) {
        const animalDoc = animalSnapShot.data();
        const animalFeed = {
          ...animalDoc.animalFeed,
          [feedInfo.dateTime.toISOString()]: {
            ...feedInfo,
            feed: feedInfo.feed,
            quantity: animalFeedQuantity,
            cost: animalFeedCost,
          },
        };
        yield animalRef.update({ animalFeed });
        yield put(addCampAnimalFeedSuccess(animalDoc, animalFeed));
      }
    }
  } catch (error) {
    handleError(error);
    yield put(addCampFeedFailure(error));
  }
}

export function* onFarmSelected() {
  yield takeLatest(farmDetailsActionTypes.SELECT_FARM, fetchFarmCamps);
}

export function* onCampCreate() {
  yield takeLatest(campActionTypes.CREATE_CAMP_START, createNewCampAsync);
}

export function* onCampFieldUpdate() {
  yield takeLatest(campActionTypes.UPDATE_CAMP_START, updateCampDetailsAsync);
}

export function* onCampSelected() {
  yield takeLatest(campActionTypes.SELECT_CAMP, fetchSelectedCamp);
}

export function* onCampFeedLatestDelete() {
  yield takeLatest(
    campActionTypes.DELETE_LATEST_CAMP_FEED_START,
    deleteLatestCampFeedAsync
  );
}

export function* onCampDelete() {
  yield takeLatest(campActionTypes.DELETE_CAMP_START, deleteCampDetailsAsync);
}

export function* onCampRestore() {
  yield takeLatest(campActionTypes.RESTORE_CAMP_START, restoreCampDetailsAsync);
}

export function* onCampFeedAdd() {
  yield takeLatest(campActionTypes.ADD_CAMP_FEED_START, addCampFeedAsync);
}

export function* clearCampsData() {
  yield put(clearCamps());
}

export function* onUserSignout() {
  yield takeLatest(userActionTypes.SIGN_OUT_SUCCESS, clearCampsData);
}

export function* onAllCampsFetch() {
  yield takeLatest(userActionTypes.SIGN_IN_SUCCESS, fetchAllCamps);
}

export function* campSagas() {
  yield all([
    call(onFarmSelected),
    call(onCampDelete),
    call(onCampCreate),
    call(onCampFieldUpdate),
    call(onCampFeedLatestDelete),
    call(onCampDelete),
    call(onCampFeedAdd),
    call(onCampRestore),
    call(onUserSignout),
    call(onAllCampsFetch),
  ]);
}
