import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { createStructuredSelector } from "reselect";
import { createErrorStart } from "../../../redux/errors/error.actions";
import { selectCurrentUser } from "../../../redux/users/user.selectors";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  logError = () => {
    const {error} = this.state;
    const {user, createErrorStart, history} = this.props;
    createErrorStart({ error, user });
    history.push('/home');
  }

  componentDidCatch=(error, errorInfo)=> {
    this.setState({ error });
    const {user, createErrorStart} = this.props;
    createErrorStart({ error, user });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div onClick={this.logError} style={{textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 20, margin: 20}}>
          <img src="https://i.imgur.com/FOeYt4E.png" alt="error" width="200"/>
          <h2>Oops! Something went wrong. Click HERE to go home</h2>
        </div>
      );
    }

    return this.props.children;
  }
}

const mapStateToProps = createStructuredSelector({
  user: selectCurrentUser
})

const mapDispatchToProps = (dispatch) => ({
  createErrorStart: (error) => dispatch(createErrorStart(error)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ErrorBoundary));
