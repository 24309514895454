import { omit } from 'lodash';
import campActionTypes from './camp.action-types';
import UploadingGif from '../../common/assets/uploading.gif';

const INITIAL_STATE = {
  error: undefined,
  processing: false,
  selectedCamp: '',
  visibleCamps: {},
};

const campsReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case campActionTypes.SET_PROCESSING_START:
      return {
        ...state,
        processing: true,
      };

    case campActionTypes.SELECT_CAMP:
      return {
        ...state,
        selectedCamp: payload,
      };

    case campActionTypes.CLEAR_CAMPS:
      return INITIAL_STATE;

    case campActionTypes.CREATE_CAMP_START:
      return {
        ...state,
        processing: true,
      };

    case campActionTypes.CREATE_CAMP_FAILURE:
      return {
        ...state,
        error: payload,
        processing: false,
      };

    case campActionTypes.FETCH_CAMP_START:
      return {
        ...state,
        processing: true,
      };

    case campActionTypes.FETCH_CAMP_FAILURE:
      return {
        ...state,
        error: payload,
        processing: false,
      };

    case campActionTypes.CREATE_CAMP_SUCCESS:
      return {
        ...state,
        processing: false,
        visibleCamps: {
          ...state.visibleCamps,
          [payload.campId]: {
            ...payload,
            campImage: state.visibleCamps[payload?.campId ?? payload?.id]?.campImage?.includes('http') ? state.visibleCamps[payload?.campId ?? payload?.id]?.campImage : UploadingGif,
            campImages: [UploadingGif]
          },
        },
        selectedCamp: payload?.campId ?? state.selectedCamp,
      };

    case campActionTypes.ADD_CAMP_FEED_SUCCESS:
      return {
        ...state,
        processing: false,
        visibleCamps: {
          ...state.visibleCamps,
          [payload.campDetails?.id ?? payload.campDetails?.campId]: {
            ...state.visibleCamps[
              payload.campDetails?.id ?? payload.campDetails?.campId
            ],
            feedData: payload.feedDetails,
          },
        },
      };

    case campActionTypes.DELETE_CAMP_SUCCESS:
      return {
        ...state,
        processing: false,
        visibleCamps: {
          ...omit(state.visibleCamps, payload.campId),
        },
        selectedCamp: state.visibleCamps[0] ? state.visibleCamps[0]?.id : '',
      };

    case campActionTypes.RESTORE_CAMP_SUCCESS:
      return {
        ...state,
        processing: false,
        visibleCamps: {
          ...state.visibleCamps,
          [payload.campId]: payload,
        },
        // selectedCamp: payload?.campId ?? state.selectedCamp,
      };

    case campActionTypes.FETCH_CAMP_SUCCESS:
    case campActionTypes.FETCH_SELECTED_CAMP_SUCCESS:
      return {
        ...state,
        processing: false,
        visibleCamps: {
          ...state.visibleCamps,
          ...payload,
        },
      };

    case campActionTypes.FETCH_ALL_CAMPS_SUCCESS:
      return {
        ...state,
        processing: false,
        visibleCamps: {
          ...state.visibleCamps,
          ...payload,
        },
      };

    case campActionTypes.UPDATE_CAMP_SUCCESS:
      return {
        ...state,
        processing: false,
        visibleCamps: {
          ...state.visibleCamps,
          [payload?.campId ?? payload?.id]: {
              ...payload,
              campImage: state.visibleCamps[payload?.campId ?? payload?.id]?.campImage ?? UploadingGif,
              campImages: state.visibleCamps[payload?.campId ?? payload?.id]?.campImages ?? [UploadingGif]
            },
        },
        selectedCamp: payload?.campId ?? payload?.id,
      };
    
    case campActionTypes.UPDATE_CAMP_IMAGES_SUCCESS:
      return {
        ...state,
        processing: false,
        visibleCamps: {
          ...state.visibleCamps,
          [payload?.campId ?? payload?.id]: payload,
        },
        selectedCamp: payload?.campId ?? payload?.id,
      };

    default:
      return state;
  }
};

export default campsReducer;
