import React from 'react';
import './main-page.styles.scss';

import MenuDrawer from '../../components/shared/menu-drawer/menu-drawer.component';
import NotificationPopups from '../../components/notifications/notification-popup/notification-popup.component';
import ErrorBoundary from '../../components/shared/error-boundary/error-boundary.component';

class MainPage extends React.Component {
  render() {
    return (
      <div className="main-page">
        <div className="stage">
          <div className="content">
            <ErrorBoundary>{this.props.children}</ErrorBoundary>
          </div>
          <div className="menubar">
            <MenuDrawer />
          </div>
        </div>
        <NotificationPopups />
      </div>
    );
  }
}

export default MainPage;
