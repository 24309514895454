const userActionTypes = {
  GOOGLE_SIGN_IN_START: 'GOOGLE_SIGN_IN_START',
  FACEBOOK_SIGN_IN_START: 'FACEBOOK_SIGN_IN_START',

  EMAIL_SIGN_IN_START: 'EMAIL_SIGN_IN_START',
  EMAIL_SIGN_UP_START: 'EMAIL_SIGN_UP_START',
  EMAIL_SIGN_UP_SUCCESS: 'EMAIL_SIGN_UP_SUCCESS',
  EMAIL_SIGN_UP_FAILED: 'EMAIL_SIGN_UP_FAILED',

  SIGN_IN_SUCCESS: 'SIGN_IN_SUCCESS',
  SIGN_IN_FAILED: 'SIGN_IN_FAILED',
  SIGN_OUT_START: 'SIGN_OUT_START',
  SIGN_OUT_SUCCESS: 'SIGN_OUT_SUCCESS',
  SIGN_OUT_FAILED: 'SIGN_OUT_FAILED',

  TOKEN_UPDATE_START: 'TOKEN_UPDATE_START',
  PROFILE_UPDATE_START: 'PROFILE_UPDATE_START',
  PROFILE_UPDATE_SUCCESS: 'PROFILE_UPDATE_SUCCESS',
  PROFILE_UPDATE_FAILED: 'PROFILE_UPDATE_FAILED',

  CHECK_USER_SESSION: 'CHECK_USER_SESSION',
  SET_PROFILE_COMPLETE: 'SET_PROFILE_COMPLETE',
  SET_TERMS_ACCEPTED: 'SET_TERMS_ACCEPTED',
  SET_ONBOARDING_DONE: 'SET_ONBOARDING_DONE',
  REP_UPDATE_START: 'REP_UPDATE_START',

  CHECK_USER_EMAIL_EXISTS: 'CHECK_USER_EMAIL_EXISTS',
  VISIBLE_USER_FETCH_SUCCESS: 'VISIBLE_USER_FETCH_SUCCESS',
  VISIBLE_USER_FETCH_FAILED: 'VISIBLE_USER_FETCH_FAILED',

  PASSWORD_RESET_START: 'PASSWORD_RESET_START',
  PASSWORD_RESET_SUCCESS: 'PASSWORD_RESET_SUCCESS',
  PASSWORD_RESET_FAILURE: 'PASSWORD_RESET_FAILURE',

  FETCH_AGENT_START: 'FETCH_AGENT_START',
  FETCH_AGENT_SUCCESS: 'FETCH_AGENT_SUCCESS',
  FETCH_AGENT_FAILURE: 'FETCH_AGENT_FAILURE',
};

export default userActionTypes;
