import reminderActionTypes from './reminder.action-types';

export const clearNotifications = () => ({
  type: reminderActionTypes.CLEAR_NOTIFICATIONS,
});

export const clearReminders = () => ({
    type: reminderActionTypes.CLEAR_REMINDERS,
});

export const updateReminderStart = (reminderDetails) => ({
  type: reminderActionTypes.UPDATE_REMINDER_START,
  payload: reminderDetails,
});

export const updateMessageListener = (messageListener) => ({
    type: reminderActionTypes.UPDATE_MESSAGE_LISTENER,
    payload: messageListener,
  });

export const deleteReminderStart = (reminderDetails) => ({
  type: reminderActionTypes.DELETE_REMINDER_START,
  payload: reminderDetails,
});

export const deleteReminderSuccess = (reminderDetails) => ({
  type: reminderActionTypes.DELETE_REMINDER_SUCCESS,
  payload: reminderDetails,
});

export const deleteReminderFailure = (error) => ({
  type: reminderActionTypes.DELETE_REMINDER_FAILURE,
  payload: error,
});

export const updateReminderSuccess = (reminderDetails) => ({
  type: reminderActionTypes.UPDATE_REMINDER_SUCCESS,
  payload: reminderDetails,
});

export const updateReminderFailure = (error) => ({
  type: reminderActionTypes.UPDATE_REMINDER_FAILURE,
  payload: error,
});

export const setSelectedReminder = (reminderId) => ({
  type: reminderActionTypes.SET_SELECTED_REMINDER,
  payload: reminderId,
});

export const createReminderStart = (reminderDetails) => ({
  type: reminderActionTypes.CREATE_REMINDER_START,
  payload: reminderDetails,
});

export const createReminderSuccess = (reminderDetails) => ({
  type: reminderActionTypes.CREATE_REMINDER_SUCCESS,
  payload: reminderDetails,
});

export const createReminderFailure = (error) => ({
  type: reminderActionTypes.CREATE_REMINDER_FAILURE,
  payload: error,
});

export const fetchReminderStart = () => ({
  type: reminderActionTypes.FETCH_REMINDER_START,
});

export const fetchReminderSuccess = (reminders) => ({
  type: reminderActionTypes.FETCH_REMINDER_SUCCESS,
  payload: reminders,
});

export const fetchReminderFailure = (error) => ({
  type: reminderActionTypes.FETCH_REMINDER_FAILURE,
  payload: error,
});
