import React from 'react';
import { passwordResetStart } from '../../../../redux/users/user.actions';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { Box, Container, Typography } from '@material-ui/core';
import TextField from '../../../shared/react-hook-form/text-field/text-field';
import SubmitButton from '../../../shared/react-hook-form/submit-button/ButtonSubmit';
import {
  GriDisplayFlexEnd,
  GridPadding10,
  MainColor,
  Bold,
  GriDisplayFlexCenter,
  ColorGray,
  TextCenter,
} from '../../../../styles/shared/shared.styles';
import { useForm } from "react-hook-form";

const ForgotPassword = ({ history, passwordReset, error, successMessage }) => {

  const { register, handleSubmit } = useForm();

  const onSubmit = async (values) => {
    await passwordReset(values);
  };

  return (
    <Box mt={7}>
      <Container maxWidth="sm">
        {!successMessage && (
          <GridPadding10>
            <GriDisplayFlexEnd>
              Don’t have an account? &nbsp;
              <Link underline="none" variant="subtitle2" to="/profile/signup">
                <Bold>
                  <MainColor>Sign Up</MainColor>
                </Bold>
              </Link>
            </GriDisplayFlexEnd>
          </GridPadding10>
        )}
        {error && (
          <span style={{ color: 'red' }}>
            There is no user record corresponding to this identifier. Please
            verify that you are submitting the correct email address
          </span>
        )}
        {successMessage ? (
          <Box py={4}>
            <TextCenter
              style={{ color: 'green' }}
            >{`${successMessage.message}. Please check your email and follow instructions to change your password`}</TextCenter>
          </Box>
        ) : (
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box m={3}>
                <Box py={2}>
                  <Typography variant="h3" gutterBottom>
                    Forgot your password?
                  </Typography>
                </Box>
                <Box py={1}>
                  <ColorGray>
                    Please enter the email address associated with your account
                    and We will email you the instructions to reset your
                    password.
                  </ColorGray>
                </Box>
                <Box py={2}>
                  <TextField label="Email" name="email" register={register}/>
                </Box>
                <Box py={2}>
                  <SubmitButton type="submit">Reset Password</SubmitButton>
                </Box>
              </Box>
            </form>
        )}
        <Box>
          <GriDisplayFlexCenter>
            <Link underline="none" variant="subtitle2" to="/profile/signin">
              <Bold>
                <MainColor>Sign In</MainColor>
              </Bold>
            </Link>
          </GriDisplayFlexCenter>
        </Box>
      </Container>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  error: state.user.error,
  successMessage: state.user.message,
});

const mapDispatchToProps = (dispatch) => ({
  passwordReset: (email) => dispatch(passwordResetStart(email)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ForgotPassword));
