const animalActionTypes = {
  CLEAR_FILTERS: 'CLEAR_FILTERS',
  CLEAR_ANIMALS: 'CLEAR_ANIMALS',
  UPDATE_FILTERS: 'UPDATE_FILTERS',
  SET_SELECTED_ANIMAL: 'SET_SELECTED_ANIMAL',
  CREATE_ANIMAL_START: 'CREATE_ANIMAL_START',
  ADD_ANIMAL_WEIGHT_START: 'ADD_ANIMAL_WEIGHT_START',
  ADD_ANIMAL_WEIGHT_SUCCESS: 'ADD_ANIMAL_WEIGHT_SUCCESS',
  ADD_ANIMAL_IMAGES_SUCCESS: 'ADD_ANIMAL_IMAGES_SUCCESS',
  ADD_ANIMAL_WEIGHT_FAILURE: 'ADD_ANIMAL_WEIGHT_FAILURE',
  ADD_ANIMAL_MEDICATION_START: 'ADD_ANIMAL_MEDICATION_START',
  ADD_ANIMAL_MEDICATION_SUCCESS: 'ADD_ANIMAL_MEDICATION_SUCCESS',
  ADD_ANIMAL_MEDICATION_FAILURE: 'ADD_ANIMAL_MEDICATION_FAILURE',
  ADD_ANIMAL_MILK_START: 'ADD_ANIMAL_MILK_START',
  ADD_ANIMAL_MILK_SUCCESS: 'ADD_ANIMAL_MILK_SUCCESS',
  ADD_ANIMAL_MILK_FAILURE: 'ADD_ANIMAL_MILK_FAILURE',
  ADD_ANIMAL_HEAT_START: 'ADD_ANIMAL_HEAT_START',
  ADD_ANIMAL_HEAT_SUCCESS: 'ADD_ANIMAL_HEAT_SUCCESS',
  ADD_ANIMAL_HEAT_FAILURE: 'ADD_ANIMAL_HEAT_FAILURE',
  SELL_ANIMAL_START: 'SELL_ANIMAL_START',
  SELL_ANIMAL_SUCCESS: 'SELL_ANIMAL_SUCCESS',
  SELL_ANIMAL_FAILURE: 'SELL_ANIMAL_FAILURE',
  SOLD_ANIMAL_START: 'SOLD_ANIMAL_START',
  SOLD_ANIMAL_SUCCESS: 'SOLD_ANIMAL_SUCCESS',
  SOLD_ANIMAL_FAILURE: 'SOLD_ANIMAL_FAILURE',
  DELETE_LATEST_ANIMAL_WEIGHT_START: 'DELETE_LATEST_ANIMAL_WEIGHT_START',
  DELETE_LATEST_ANIMAL_MEDICATION_START:
    'DELETE_LATEST_ANIMAL_MEDICATION_START',
  DELETE_LATEST_ANIMAL_MILK_START: 'DELETE_LATEST_ANIMAL_MILK_START',
  DELETE_LATEST_ANIMAL_HEAT_START: 'DELETE_LATEST_ANIMAL_HEAT_START',
  DELETE_LATEST_ANIMAL_FEED_START: 'DELETE_LATEST_ANIMAL_FEED_START',
  DELETE_LATEST_ANIMAL_CAMP_HISTORY_START:
    'DELETE_LATEST_ANIMAL_CAMP_HISTORY_START',
  DELETE_LATEST_ANIMAL_CAMP_HISTORY_SUCCESS:
    'DELETE_LATEST_ANIMAL_CAMP_HISTORY_START',
  DELETE_LATEST_ANIMAL_CAMP_HISTORY_FAILURE:
    'DELETE_LATEST_ANIMAL_CAMP_HISTORY_START',
  DELETE_ANIMAL_START: 'DELETE_ANIMAL_START',
  DELETE_ANIMAL_SUCCESS: 'DELETE_ANIMAL_SUCCESS',
  DELETE_ANIMAL_FAILURE: 'DELETE_ANIMAL_FAILURE',
  UPDATE_ANIMAL_START: 'UPDATE_ANIMAL_START',
  UPDATE_ANIMAL_SUCCESS: 'UPDATE_ANIMAL_SUCCESS',
  UPDATE_ANIMAL_FAILURE: 'UPDATE_ANIMAL_FAILURE',
  CREATE_ANIMAL_SUCCESS: 'CREATE_ANIMAL_SUCCESS',
  CREATE_ANIMAL_FAILURE: 'CREATE_ANIMAL_FAILURE',
  FETCH_ANIMAL_START: 'FETCH_ANIMAL_START',
  FETCH_ANIMAL_FAMILY_START: 'FETCH_ANIMAL_FAMILY_START',
  FETCH_ANIMAL_SUCCESS: 'FETCH_ANIMAL_SUCCESS',
  FETCH_CAMP_ANIMAL_SUCCESS: 'FETCH_CAMP_ANIMAL_SUCCESS',
  FETCH_ANIMAL_FAILURE: 'FETCH_ANIMAL_FAILURE',
  TRANSFER_ANIMAL_CAMP_START: 'TRANSFER_ANIMAL_CAMP_START',
  TRANSFER_ANIMAL_CAMP_SUCCESS: 'TRANSFER_ANIMAL_CAMP_SUCCESS',
  TRANSFER_ANIMAL_CAMP_FAILURE: 'TRANSFER_ANIMAL_CAMP_FAILURE',
  SET_PROCESSING_START: 'SET_PROCESSING_START',
  END_ANIMAL_START: 'END_ANIMAL_START',
  END_ANIMAL_SUCCESS: 'END_ANIMAL_SUCCESS',
  END_ANIMAL_FAILURE: 'END_ANIMAL_FAILURE',
  ADD_ANIMAL_FEED_START: 'ADD_ANIMAL_FEED_START',
  ADD_ANIMAL_FEED_SUCCESS: 'ADD_ANIMAL_FEED_SUCCESS',
  ADD_ANIMAL_FEED_FAILURE: 'ADD_ANIMAL_FEED_FAILURE',
  ADD_CAMP_ANIMAL_FEED_SUCCESS: 'ADD_CAMP_ANIMAL_FEED_SUCCESS',
  ADD_CAMP_ANIMAL_FEED_FAILURE: 'ADD_CAMP_ANIMAL_FEED_FAILURE',
  FETCH_ANIMALS_FORSALE_SUCCESS: 'FETCH_ANIMALS_FORSALE_SUCCESS',
  FETCH_ANIMALS_FORSALE_FAILURE: 'FETCH_ANIMALS_FORSALE_FAILURE',
  FETCH_PURCHASED_ANIMALS_SUCCESS: 'FETCH_PURCHASED_ANIMALS_SUCCESS',
  FETCH_PURCHASED_ANIMALS_FAILURE: 'FETCH_PURCHASED_ANIMALS_FAILURE',
  FETCH_SOLD_ANIMALS_SUCCESS: 'FETCH_SOLD_ANIMALS_SUCCESS',
  FETCH_SOLD_ANIMALS_FAILURE: 'FETCH_SOLD_ANIMALS_FAILURE',
  UNDO_ANIMAL_SELL_START: 'UNDO_ANIMAL_SELL_START',
  UNDO_ANIMAL_SELL_SUCCESS: 'UNDO_ANIMAL_SELL_SUCCESS',
  UNDO_ANIMAL_SELL_FAILURE: 'UNDO_ANIMAL_SELL_FAILURE',
  ASSIGN_FARM_AND_CAMP_START: 'ASSIGN_FARM_AND_CAMP_START',
  ASSIGN_FARM_AND_CAMP_SUCCESS: 'ASSIGN_FARM_AND_CAMP_SUCCESS',
};

export default animalActionTypes;
