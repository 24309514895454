import { omit } from 'lodash';
import transactionActionTypes from './transaction.types';

const INITIAL_STATE = {
  processing: null,
  selectedTransaction: '',
  transactions: {},
  error: null,
};

const transactionsReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case transactionActionTypes.GET_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        transactions: {
          ...state.transactions,
          ...payload,
        },
        processing: false,
      };

    case transactionActionTypes.CREATE_TRANSACTION_SUCCESS:
      return {
        ...state,
        transactions: {
          ...state.transactions,
          [payload.transactionId ?? payload.id]: payload,
        },
        processing: false,
      };

    case transactionActionTypes.EDIT_TRANSACTION_SUCCESS:
      return {
        ...state,
        processing: false,
        transactions: {
          ...state.transactions,
          [payload?.id ?? payload?.transactionId]: payload,
        },
      };

    case transactionActionTypes.DELETE_TRANSACTION_SUCCESS:
      return {
        ...state,
        processing: false,
        transactions: {
          ...omit(state.transactions, payload?.transactionId ?? payload?.id),
        },
      };

    default:
      return state;
  }
};

export default transactionsReducer;
