import React from 'react';
import { CssBaseline, Grid } from '@material-ui/core';
import { MainContainer } from '../../../styles/info.pages/info.page.styles';
import {
  GriDisplayFlexCenter,
  PageTitle,
} from '../../../styles/shared/shared.styles';
import UserDescription from '../profile-description/profile-description.component';
import UserStatus from '../profile-status/profile-status.component';
import { ProfileMain } from '../../../styles/profile.page/profile-page.styles';
import MyAvatar from '../../minimal-ui/avatar/MyAvatar';

const UserDetails = ({ user }) => {
  return (
    <>
      <CssBaseline />
      <MainContainer maxWidth="md">
        <PageTitle as="h2">{user?.displayName}</PageTitle>
        <ProfileMain container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Grid container>
              <GriDisplayFlexCenter item xs={3}>
                {user && (
                  <MyAvatar
                    user={user}
                    sx={{ cursor: 'pointer', width: '600px', height: '600px' }}
                    // variant="square"
                  />
                )}
              </GriDisplayFlexCenter>
              <Grid item xs={9}>
                <UserStatus user={user} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <UserDescription user={user} />
          </Grid>
        </ProfileMain>
      </MainContainer>
    </>
  );
};

export default UserDetails;
