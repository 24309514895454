import { all, call, put, takeLatest } from 'redux-saga/effects';
import { convertCollectionsSnapshotToMap } from '../../firebase/firebase.functions';
import { firestore } from '../../firebase/firebase.utils';
import { handleError } from '../../utils/errors/errorHandler';
import productActionTypes from '../products/product.action-types';
import {
  createProductFailure,
  createProductSuccess,
  deleteProductFailure,
  deleteProductSuccess,
  editProductFailure,
  editProductSuccess,
  fetchProductsFailure,
  fetchProductsSuccess,
} from './product.actions';

export function* fetchAllProducts() {
  try {
    const collectionRef = firestore.collection('products');
    const snapshot = yield collectionRef.get();
    const collectionsMap = yield call(
      convertCollectionsSnapshotToMap,
      snapshot
    );
    yield put(fetchProductsSuccess(collectionsMap));
  } catch (error: any) {
    handleError(error, null);
    yield put(fetchProductsFailure(error.message));
  }
}

export function* editProduct(action) {
  const productDetails  = action.payload;
  const productImage = action.payload.productImage;
  const productImages = action.payload.productImages;
  if (!productDetails) return;
  try {
    const productRef = firestore.doc(
      `products/${productDetails.id ?? productDetails.productId}`
    );
    const updatedProductRef = yield productRef.get();
    if (updatedProductRef.exists) {
      const updatedProductDetails = { ...productDetails };
      delete updatedProductDetails.id;
      delete updatedProductDetails.productId;
      delete updatedProductDetails.productImage;
      delete updatedProductDetails.productImages;
      productRef.update(updatedProductDetails);
      yield put(
        editProductSuccess({
          id: productRef.id,
          productId: productRef.id,
          ...productDetails,
          productImage,
          productImages
        })
      );
    }
  } catch (error: any) {
    handleError(error, action);
    yield put(editProductFailure(error));
  }
}

export function* createNewProduct(action) {
  const productDetails = action.payload;
  if (!productDetails) return;
  try {
    const collectionRef = firestore.collection('products');
    let addedProductRef: any | null = null;
    try {
      let updatedProductDetails = { ...productDetails };
      delete updatedProductDetails.productImages;
      delete updatedProductDetails.productImage;
      addedProductRef = yield collectionRef.add(updatedProductDetails);
    } catch (error) {
      handleError(error, action);
      yield put(createProductFailure(error));
    } finally {
      yield put(
        createProductSuccess({
          id: addedProductRef?.id ?? addedProductRef?.productId,
          productId: addedProductRef?.id ?? addedProductRef?.productId,
          ...productDetails,
        })
      );
    }
  } catch (error: any) {
    handleError(error, action);
    yield put(createProductFailure(error));
  }
}

export function* deleteProductAsync(action) {
  const productId = action.payload;
  if (!productId) return;
  try {
    const productRef = firestore.doc(`products/${productId}`);
    const updatedProductRef = yield productRef.get();
    if (updatedProductRef.exists) {
      const updatedProductDetails = { deleted: true };
      yield productRef.update(updatedProductDetails);
      yield put(
        deleteProductSuccess({
          productId: productId,
          ...updatedProductRef,
          ...updatedProductDetails,
        })
      );
    }
  } catch (error: any) {
    handleError(error, action);
    yield put(deleteProductFailure(error));
  }
}

export function* onAllProductsFetch() {
  yield takeLatest(productActionTypes.FETCH_PRODUCTS_START, fetchAllProducts);
}

export function* onProductCreate() {
  yield takeLatest(productActionTypes.CREATE_PRODUCT_START, createNewProduct);
}

export function* onProductEdit() {
  yield takeLatest(productActionTypes.EDIT_PRODUCT_START, editProduct);
}

export function* onProductDelete() {
  yield takeLatest(productActionTypes.DELETE_PRODUCT_START, deleteProductAsync);
}

export function* productSagas() {
  yield all([
    call(onAllProductsFetch),
    call(onProductCreate),
    call(onProductDelete),
    call(onProductEdit),
  ]);
}
