import { all, call, put, takeLatest } from "redux-saga/effects";
import { convertCollectionsSnapshotToMap } from "../../firebase/firebase.functions";
import { firestore } from "../../firebase/firebase.utils";
import { convertObjectToArray } from "../../utils/array/mapper";
import deviceActionTypes from "../devices/device.action-types";
import userActionTypes from "../users/user.types";
import eventActionTypes from "./event.action-types";
import { fetchEventFailure, fetchEventSuccess, createEventSuccess, createEventFailure, fetchDeviceEventsStart, clearEvents } from "./event.actions";

export function* createNewEventAsync(action) {
    const eventDetails = action.payload;
    if(!eventDetails) return;
    try{
        const collectionRef = firestore.collection('events');
        const addedEventRef = yield collectionRef.add(action.payload);
        yield put(createEventSuccess({eventId: addedEventRef.id, ...action.payload}));
    } catch(error) {
        yield put(createEventFailure(error));
    }
}

export function* fetchPublicEvents(action) {
    // const startDate = action.payload ?? new Date();
    try{
        const collectionRef = firestore.collection('events').where('type', '==', 'public-event');
        const query = collectionRef.limit(10);
        const snapshot = yield query.get();
        const collectionsMap = yield call(convertCollectionsSnapshotToMap, snapshot);
        yield put(fetchEventSuccess(collectionsMap))
    } catch(error) {
        yield put(fetchEventFailure(error.message))
    }
}

export function* fetchDeviceEvents(action) {
    try{
        const device = action.payload;
        const collectionRef = firestore.collection('events').where('deviceIds', "array-contains", device?.id);
        const snapshot = yield collectionRef.get();
        const collectionsMap = yield call(convertCollectionsSnapshotToMap, snapshot);
        yield put(fetchEventSuccess(collectionsMap))
    } catch(error) {
        yield put(fetchEventFailure(error.message))
    }
}

export function* fetchMultipleDeviceEvents(action) {
    const devices = action.payload;
    if(!devices) return;
    const deviceArray = convertObjectToArray(devices)
    try{
        yield all(deviceArray.map(device => {
            return put(fetchDeviceEventsStart(device))
        }))
    } catch(error) {
        yield put(fetchEventFailure(error.message))
    }
}

export function* fetchFarmEvents(action) {
    const farmId = action.payload;
    if(!farmId) return;
    try{
        const collectionRef = firestore.collection('events').where('farmIds', "array-contains", farmId);
        const snapshot = yield collectionRef.get();
        const collectionsMap = yield call(convertCollectionsSnapshotToMap, snapshot);
        yield put(fetchEventSuccess(collectionsMap))
    } catch(error) {
        yield put(fetchEventFailure(error.message))
    }
}

export function* onEventCreate() {
    yield takeLatest(
        eventActionTypes.CREATE_EVENT_START, 
        createNewEventAsync
    );
}

export function* onPublicEventFetch() {
    yield takeLatest(
        eventActionTypes.FETCH_PUBLIC_EVENTS_START, 
        fetchPublicEvents
    );
}

export function* onDeviceFetch() {
    yield takeLatest(
        deviceActionTypes.FETCH_DEVICE_SUCCESS, 
        fetchMultipleDeviceEvents
    );
}

export function* onDeviceEventFetch() {
    yield takeLatest(
        eventActionTypes.FETCH_DEVICE_EVENTS_START, 
        fetchDeviceEvents
    );
}


export function* clearEventsData() {
    yield put(clearEvents())
  }

export function* onUserSignout() {
    yield takeLatest(
        userActionTypes.SIGN_OUT_SUCCESS,
        clearEventsData
    )
}

export function* eventSagas() {
    yield all([
        call(onEventCreate),
        call(onPublicEventFetch),
        call(onDeviceFetch),
        call(onDeviceEventFetch),
        call(onUserSignout)
    ])
}