import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { fetchAgentSubscriptionsStart } from '../../../redux/subscriptions/subscription.actions';
import { selectCurrentUser } from '../../../redux/users/user.selectors';
import { selectCurrentAgentSubscriptionsAsArray } from '../../../redux/subscriptions/subscription.selectors';
import { Grid, Box } from '@material-ui/core';
import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeItem from '@material-ui/lab/TreeItem';
import {
  GriDisplayFlex,
  MainColor,
  Bold,
} from '../../../styles/shared/shared.styles';
import { fDate } from '../../minimal-ui/utils/formatTime';
import TreeViewTitle from '../../shared/tree-view-title/tree-view-title.component';

const AgentSubscriptions = ({
  user,
  fetchAgentSubscriptions,
  agentSubscriptions,
}) => {
  useEffect(() => {
    if (!user || !fetchAgentSubscriptions) return;
    if (user && user?.repCode) {
      fetchAgentSubscriptions(user?.repCode);
    }
  }, [user?.repCode, fetchAgentSubscriptions]);

  return (
    <div>
      {agentSubscriptions.map((subscription) => (
        <Box key={subscription?.createdDate.seconds}>
          <TreeView
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
          >
            <TreeItem
              nodeId="1"
              label={<TreeViewTitle item={subscription} title="Sub:" />}
            >
              <Box>
                {subscription?.active && (
                  <GriDisplayFlex container>
                    <Grid item xs={5}>
                      <Bold>
                        <MainColor>Active</MainColor>
                      </Bold>
                    </Grid>
                    <Grid item xs={7}>
                      {subscription?.active ? 'Yes' : 'No'}
                    </Grid>
                  </GriDisplayFlex>
                )}
                {subscription?.agentFee && (
                  <>
                    <Grid item xs={5}>
                      <Bold>
                        <MainColor>Agent Fee</MainColor>
                      </Bold>
                    </Grid>
                    <Grid item xs={7}>
                      R{subscription?.agentFee}
                    </Grid>
                  </>
                )}
              </Box>
            </TreeItem>
          </TreeView>
        </Box>
      ))}
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  user: selectCurrentUser,
  agentSubscriptions: selectCurrentAgentSubscriptionsAsArray,
});

const mapDispatchToProps = (dispatch) => ({
  fetchAgentSubscriptions: (AgentCode) =>
    dispatch(fetchAgentSubscriptionsStart(AgentCode)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AgentSubscriptions));
