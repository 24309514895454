import { omit } from 'lodash';
import productActionTypes from './product.action-types';
import UploadingGif from '../../common/assets/uploading.gif';

const INITIAL_STATE = {
  error: undefined,
  processing: false,
  selectedProduct: '',
  visibleProducts: {},
  unsyncedProducts: {}
};

const productsReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case productActionTypes.SELECT_PRODUCT:
      return {
        ...state,
        selectedProduct: payload,
      };

    case productActionTypes.CLEAR_PRODUCTS:
      return {
        ...state,
        selectedProduct: '',
        visibleProducts: {},
      };

    case productActionTypes.FETCH_PRODUCTS_START:
      return {
        ...state,
        processing: true,
      };

    case productActionTypes.FETCH_PRODUCTS_FAILURE:
      return {
        ...state,
        error: payload,
        processing: false,
      };

    case productActionTypes.UPDATE_PRODUCT_IMAGES_SUCCESS:
      return {
        ...state,
        processing: false,
        visibleProducts: {
          ...state.visibleProducts,
          [payload?.farmId ?? payload?.id]: payload,
        },
        selectedProduct: payload?.farmId ?? payload?.id,
      };

    case productActionTypes.FETCH_PRODUCTS_SUCCESS:
      return {
        ...state,
        processing: false,
        visibleProducts: {
          ...payload,
        },
      };
    case productActionTypes.CREATE_PRODUCT_SUCCESS:
      if(!payload.productId && !payload.id)
      {
        return {
          ...state,
          processing: false,
          unsyncedProducts: {...state.unsyncedProducts, [(new Date()).toString()]: payload}
        };
      }
      return {
        ...state,
        processing: false,
        visibleProducts: {
          ...state.visibleProducts,
          [payload.productId]: {
            ...payload,
            productImage: UploadingGif,
            productImages: [UploadingGif],
          },
        },
      };

    case productActionTypes.EDIT_PRODUCT_SUCCESS:
      return {
        ...state,
        processing: false,
        visibleProducts: {
          ...state.visibleProducts,
          [payload?.id ?? payload?.productId]: payload,
        },
      };

    case productActionTypes.DELETE_PRODUCT_SUCCESS:
      return {
        ...state,
        processing: false,
        visibleProducts: {
          ...omit(state.visibleProducts, payload?.productId ?? payload?.id),
        },
      };

    default:
      return state;
  }
};

export default productsReducer;
