import animalActionTypes from './animal.action-types';
import { Animal, AnimalWeight, AnimalMedication } from '../../common'

export const clearAnimalFilters = () => ({
  type: animalActionTypes.CLEAR_FILTERS,
});

export const clearAnimals = () => ({
  type: animalActionTypes.CLEAR_ANIMALS,
});

export const setProcessingStart = () => ({
  type: animalActionTypes.SET_PROCESSING_START,
});

export const updateAnimalFilters = (filterString) => ({
  type: animalActionTypes.UPDATE_FILTERS,
  payload: filterString,
});

export const updateAnimalStart = (animalDetails:Animal) => ({
  type: animalActionTypes.UPDATE_ANIMAL_START,
  payload: animalDetails,
});
export const updateAnimalSuccess = (animalDetails:Animal) => ({
  type: animalActionTypes.UPDATE_ANIMAL_SUCCESS,
  payload: animalDetails,
});

export const updateAnimalFailure = (error) => ({
  type: animalActionTypes.UPDATE_ANIMAL_FAILURE,
  payload: error,
});

export const deleteAnimalStart = (animalDetails:Animal) => ({
  type: animalActionTypes.DELETE_ANIMAL_START,
  payload: animalDetails,
});

export const deleteAnimalSuccess = (animalDetails:Animal) => ({
  type: animalActionTypes.DELETE_ANIMAL_SUCCESS,
  payload: animalDetails,
});

export const deleteAnimalFailure = (error) => ({
  type: animalActionTypes.DELETE_ANIMAL_FAILURE,
  payload: error,
});

export const addAnimalWeightStart = (animalDetails:Animal, weightDetails:AnimalWeight) => ({
  type: animalActionTypes.ADD_ANIMAL_WEIGHT_START,
  payload: { animalDetails, weightDetails },
});

export const addAnimalWeightSuccess = (animalDetails:Animal, weightDetails:AnimalWeight) => ({
  type: animalActionTypes.ADD_ANIMAL_WEIGHT_SUCCESS,
  payload: { animalDetails, weightDetails },
});

export const addAnimalImagesSuccess = (animalDetails:Animal) => ({
  type: animalActionTypes.ADD_ANIMAL_IMAGES_SUCCESS,
  payload: animalDetails,
});

export const addAnimalWeightFailure = (error) => ({
  type: animalActionTypes.ADD_ANIMAL_WEIGHT_FAILURE,
  payload: error,
});

export const addAnimalMedicationStart = (animalDetails:Animal, medicationDetails:AnimalMedication) => ({
  type: animalActionTypes.ADD_ANIMAL_MEDICATION_START,
  payload: { animalDetails, medicationDetails },
});

export const addAnimalMedicationSuccess = (
  animalDetails:Animal,
  medicationDetails:AnimalMedication
) => ({
  type: animalActionTypes.ADD_ANIMAL_MEDICATION_SUCCESS,
  payload: { animalDetails, medicationDetails },
});

export const addAnimalMedicationFailure = (error) => ({
  type: animalActionTypes.ADD_ANIMAL_MEDICATION_FAILURE,
  payload: error,
});

export const addAnimalMilkStart = (animalDetails, milkDetails) => ({
  type: animalActionTypes.ADD_ANIMAL_MILK_START,
  payload: { animalDetails, milkDetails },
});

export const addAnimalMilkSuccess = (animalDetails, milkDetails) => ({
  type: animalActionTypes.ADD_ANIMAL_MILK_SUCCESS,
  payload: { animalDetails, milkDetails },
});

export const addAnimalMilkFailure = (error) => ({
  type: animalActionTypes.ADD_ANIMAL_MILK_FAILURE,
  payload: error,
});

export const addAnimalHeatStart = (animalDetails, heatDetails) => ({
  type: animalActionTypes.ADD_ANIMAL_HEAT_START,
  payload: { animalDetails, heatDetails },
});

export const addAnimalHeatSuccess = (animalDetails, heatDetails) => ({
  type: animalActionTypes.ADD_ANIMAL_HEAT_SUCCESS,
  payload: { animalDetails, heatDetails },
});

export const addAnimalHeatFailure = (error) => ({
  type: animalActionTypes.ADD_ANIMAL_HEAT_FAILURE,
  payload: error,
});

export const sellAnimalStart = (animalDetails, sellDetails) => ({
  type: animalActionTypes.SELL_ANIMAL_START,
  payload: { animalDetails, sellDetails },
});

export const sellAnimalSuccess = (animalDetails, sellDetails) => ({
  type: animalActionTypes.SELL_ANIMAL_SUCCESS,
  payload: { animalDetails, sellDetails },
});

export const sellAnimalFailure = (error) => ({
  type: animalActionTypes.SELL_ANIMAL_FAILURE,
  payload: error,
});

export const soldAnimalStart = (animalDetails, purchaseDetails) => ({
  type: animalActionTypes.SOLD_ANIMAL_START,
  payload: { animalDetails, purchaseDetails },
});

export const soldAnimalSuccess = (animalDetails, purchaseDetails) => ({
  type: animalActionTypes.SOLD_ANIMAL_SUCCESS,
  payload: { animalDetails, purchaseDetails },
});

export const soldAnimalFailure = (error) => ({
  type: animalActionTypes.SOLD_ANIMAL_FAILURE,
  payload: error,
});

export const endAnimalStart = (animalDetails, endDetails) => ({
  type: animalActionTypes.END_ANIMAL_START,
  payload: { animalDetails, endDetails },
});

export const endAnimalSuccess = (animalDetails, endDetails) => ({
  type: animalActionTypes.END_ANIMAL_SUCCESS,
  payload: { animalDetails, endDetails },
});

export const endAnimalFailure = (error) => ({
  type: animalActionTypes.END_ANIMAL_FAILURE,
  payload: error,
});

export const deleteLatestAnimalWeightStart = (
  animalDetails,
  weightDataArray
) => ({
  type: animalActionTypes.DELETE_LATEST_ANIMAL_WEIGHT_START,
  payload: { animalDetails, weightDataArray },
});

export const deleteLatestAnimalMedicationStart = (animalDetails) => ({
  type: animalActionTypes.DELETE_LATEST_ANIMAL_MEDICATION_START,
  payload: { animalDetails },
});

export const deleteLatestAnimalMilkStart = (animalDetails) => ({
  type: animalActionTypes.DELETE_LATEST_ANIMAL_MILK_START,
  payload: { animalDetails },
});

export const deleteLatestAnimalHeatStart = (animalDetails) => ({
  type: animalActionTypes.DELETE_LATEST_ANIMAL_HEAT_START,
  payload: { animalDetails },
});

export const deleteLatestAnimalFeedStart = (animalDetails) => ({
  type: animalActionTypes.DELETE_LATEST_ANIMAL_FEED_START,
  payload: { animalDetails },
});

export const deleteLatestAnimalCampHistoryStart = (animalDetails, campId) => ({
  type: animalActionTypes.DELETE_LATEST_ANIMAL_CAMP_HISTORY_START,
  payload: { animalDetails, campId },
});

export const deleteLatestAnimalCampHistorySuccess = (
  animalDetails,
  campHistory
) => ({
  type: animalActionTypes.DELETE_LATEST_ANIMAL_CAMP_HISTORY_SUCCESS,
  payload: { animalDetails, campHistory },
});

export const deleteLatestAnimalCampHistoryFailure = (error) => ({
  type: animalActionTypes.DELETE_LATEST_ANIMAL_CAMP_HISTORY_FAILURE,
  payload: error,
});

export const setSelectedAnimal = (animalId) => ({
  type: animalActionTypes.SET_SELECTED_ANIMAL,
  payload: animalId,
});

export const createAnimalStart = (animalDetails) => ({
  type: animalActionTypes.CREATE_ANIMAL_START,
  payload: animalDetails,
});

export const createAnimalSuccess = (animalDetails) => ({
  type: animalActionTypes.CREATE_ANIMAL_SUCCESS,
  payload: animalDetails,
});

export const createAnimalFailure = (error) => ({
  type: animalActionTypes.CREATE_ANIMAL_FAILURE,
  payload: error,
});

export const transferAnimalCampStart = (animalDetails, campDetails) => ({
  type: animalActionTypes.TRANSFER_ANIMAL_CAMP_START,
  payload: { animalDetails, campDetails },
});

export const fetchAnimalStart = () => ({
  type: animalActionTypes.FETCH_ANIMAL_START,
});

export const fetchAnimalSuccess = (animals) => ({
  type: animalActionTypes.FETCH_ANIMAL_SUCCESS,
  payload: animals,
});

export const fetchCampAnimalSuccess = (animals) => ({
  type: animalActionTypes.FETCH_CAMP_ANIMAL_SUCCESS,
  payload: animals,
});

export const fetchAnimalFailure = (error) => ({
  type: animalActionTypes.FETCH_ANIMAL_FAILURE,
  payload: error,
});

export const fetchAnimalFamilyStart = (animals) => ({
  type: animalActionTypes.FETCH_ANIMAL_FAMILY_START,
  payload: animals,
});

export const addCampAnimalFeedSuccess = (animalsDetails, feedInfo) => ({
  type: animalActionTypes.ADD_CAMP_ANIMAL_FEED_SUCCESS,
  payload: { animalsDetails, feedInfo },
});

export const addAnimalFeedStart = (animalDetails, feedDetails) => ({
  type: animalActionTypes.ADD_ANIMAL_FEED_START,
  payload: { animalDetails, feedDetails },
});

export const addAnimalFeedSuccess = (animalDetails, feedDetails) => ({
  type: animalActionTypes.ADD_ANIMAL_FEED_SUCCESS,
  payload: { animalDetails, feedDetails },
});

export const addAnimalFeedFailure = (error) => ({
  type: animalActionTypes.ADD_ANIMAL_FEED_FAILURE,
  payload: error,
});

export const fetchAnimalsForSaleSuccess = (animals) => ({
  type: animalActionTypes.FETCH_ANIMALS_FORSALE_SUCCESS,
  payload: animals,
});

export const fetchAnimalsForSaleFailure = (error) => ({
  type: animalActionTypes.FETCH_ANIMALS_FORSALE_FAILURE,
  payload: error,
});

export const fetchPurchasedAnimalsSuccess = (animals) => ({
  type: animalActionTypes.FETCH_PURCHASED_ANIMALS_SUCCESS,
  payload: animals,
});

export const fetchPurchasedAnimalsFailure = (error) => ({
  type: animalActionTypes.FETCH_PURCHASED_ANIMALS_FAILURE,
  payload: error,
});

export const fetchSoldAnimalsSuccess = (animals) => ({
  type: animalActionTypes.FETCH_SOLD_ANIMALS_SUCCESS,
  payload: animals,
});

export const fetchSoldAnimalsFailure = (error) => ({
  type: animalActionTypes.FETCH_SOLD_ANIMALS_FAILURE,
  payload: error,
});

export const undoAnimalSellStart = (animalDetails) => ({
  type: animalActionTypes.UNDO_ANIMAL_SELL_START,
  payload: { animalDetails },
});

export const undoAnimalSellSuccess = (animalDetails) => ({
  type: animalActionTypes.UNDO_ANIMAL_SELL_SUCCESS,
  payload: { animalDetails },
});

export const undoAnimalSellFailure = (error) => ({
  type: animalActionTypes.UNDO_ANIMAL_SELL_FAILURE,
  payload: error,
});

export const assignAnimalCampAndFarmStart = (animalDetails) => ({
  type: animalActionTypes.ASSIGN_FARM_AND_CAMP_START,
  payload: animalDetails,
});

export const assignAnimalCampAndFarmSuccess = (animalDetails) => ({
  type: animalActionTypes.ASSIGN_FARM_AND_CAMP_SUCCESS,
  payload: animalDetails,
});
