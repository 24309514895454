const transactionActionTypes = {
  GET_TRANSACTIONS_START: 'GET_TRANSACTIONS_START',
  GET_TRANSACTIONS_SUCCESS: 'GET_TRANSACTIONS_SUCCESS',
  GET_TRANSACTIONS_FAILURE: 'GET_TRANSACTIONS_FAILURE',
  CREATE_TRANSACTION_START: 'CREATE_TRANSACTION_START',
  CREATE_TRANSACTION_SUCCESS: 'CREATE_TRANSACTION_SUCCESS',
  CREATE_TRANSACTION_FAILURE: 'CREATE_TRANSACTION_FAILURE',
  EDIT_TRANSACTION_START: 'EDIT_TRANSACTION_START',
  EDIT_TRANSACTION_SUCCESS: 'EDIT_TRANSACTION_SUCCESS',
  EDIT_TRANSACTION_FAILURE: 'EDIT_TRANSACTION_FAILURE',
  DELETE_TRANSACTION_START: 'DELETE_TRANSACTION_START',
  DELETE_TRANSACTION_SUCCESS: 'DELETE_TRANSACTION_SUCCESS',
  DELETE_TRANSACTION_FAILURE: 'DELETE_TRANSACTION_FAILURE',
};

export default transactionActionTypes;
