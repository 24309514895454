import feedbackActionTypes from './feedback.action-types';

export const createFeedbackStart = (feedbackDetails) => ({
  type: feedbackActionTypes.CREATE_FEEDBACK_START,
  payload: feedbackDetails,
});

export const createFeedbackSuccess = (feedbackDetails) => ({
  type: feedbackActionTypes.CREATE_FEEDBACK_SUCCESS,
  payload: feedbackDetails,
});

export const createFeedbackFailure = (error) => ({
  type: feedbackActionTypes.CREATE_FEEDBACK_FAILURE,
  payload: error,
});
