const subscriptionActionTypes = {
  CLEAR_SUBSCRIPTIONS: 'CLEAR_SUBSCRIPTIONS',
  START_SUBSCRIPTION_FETCH: 'START_SUBSCRIPTION_FETCH',
  SUBSCRIPTION_FETCH_SUCCESS: 'SUBSCRIPTION_FETCH_SUCCESS',
  SUBSCRIPTION_FETCH_FAILED: 'SUBSCRIPTION_FETCH_FAILED',

  START_SUBSCRIPTION_CREATE: 'START_SUBSCRIPTION_CREATE',
  SUBSCRIPTION_CREATE_SUCCESS: 'SUBSCRIPTION_CREATE_SUCCESS',
  SUBSCRIPTION_CREATE_FAILED: 'SUBSCRIPTION_CREATE_FAILED',

  START_SUBSCRIPTION_UPDATE: 'START_SUBSCRIPTION_UPDATE',
  SUBSCRIPTION_UPDATE_SUCCESS: 'SUBSCRIPTION_UPDATE_SUCCESS',
  SUBSCRIPTION_UPDATE_FAILED: 'SUBSCRIPTION_UPDATE_FAILED',

  DELETE_SUBSCRIPTION_START: 'DELETE_SUBSCRIPTION_START',
  DELETE_SUBSCRIPTION_SUCCESS: 'DELETE_SUBSCRIPTION_SUCCESS',
  DELETE_SUBSCRIPTION_FAILURE: 'DELETE_SUBSCRIPTION_FAILURE',

  FETCH_AGENT_SUBSCRIPTIONS_START: 'FETCH_AGENT_SUBSCRIPTIONS_START',
  FETCH_AGENT_SUBSCRIPTIONS_SUCCESS: 'FETCH_AGENT_SUBSCRIPTIONS_SUCCESS',
  FETCH_AGENT_SUBSCRIPTIONS_FAILURE: 'FETCH_AGENT_SUBSCRIPTIONS_FAILURE',
};

export default subscriptionActionTypes;
