import imageActionTypes from "./image.action-types";

export const clearImages = () => ({
  type: imageActionTypes.CLEAR_IMAGES,
});

export const startImageUpload = (imageInfo) => ({
  type: imageActionTypes.START_IMAGE_UPLOAD,
  payload: imageInfo,
});

export const imageUploadSuccess = (imageInfo) => ({
  type: imageActionTypes.IMAGE_UPLOAD_SUCCESS,
  payload: imageInfo,
});

export const imageUploadFailed = (error) => ({
  type: imageActionTypes.IMAGE_UPLOAD_FAILED,
  payload: error,
});

export const startImageFetch = (imageId) => ({
  type: imageActionTypes.START_IMAGE_FETCH,
  payload: imageId,
});

export const imageFetchSuccess = (imageInfo) => ({
  type: imageActionTypes.IMAGE_FETCH_SUCCESS,
  payload: imageInfo,
});

export const imageFetchFailed = (error) => ({
  type: imageActionTypes.IMAGE_FETCH_FAILED,
  payload: error,
});
