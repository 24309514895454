// hooks
// import useAuth from '../hooks/useAuth';
//
import MAvatar from './MAvatar';
import createAvatar from '../utils/createAvatar';

// ----------------------------------------------------------------------

export default function MyAvatar({ user, ...other }) {
  // const { user } = useAuth();
  if(!user.photoURL) return null;
  return (
    <MAvatar referrerPolicy="no-referrer"
      src={user?.photoURL}
      alt={user?.displayName}
      color={user?.photoURL ? 'default' : createAvatar(user?.displayName).color}
      {...other}
    >
      {createAvatar(user?.displayName).name}
    </MAvatar>
  );
}
