const breedActionTypes = {
    CLEAR_BREEDS: 'CLEAR_BREEDS',
    START_BREED_FETCH: 'START_BREED_FETCH',
    BREED_FETCH_SUCCESS: 'BREED_FETCH_SUCCESS',
    BREED_FETCH_FAILED: 'BREED_FETCH_FAILED',

    START_BREED_CREATE: 'START_BREED_CREATE',
    BREED_CREATE_SUCCESS: 'BREED_CREATE_SUCCESS',
    BREED_CREATE_FAILED: 'BREED_CREATE_FAILED',

    START_BREED_UPDATE: 'START_BREED_UPDATE',
    BREED_UPDATE_SUCCESS:'BREED_UPDATE_SUCCESS',
    BREED_UPDATE_FAILED: 'BREED_UPDATE_FAILED',
    
    DELETE_BREED_START: 'DELETE_BREED_START',
    DELETE_BREED_SUCCESS: 'DELETE_BREED_SUCCESS',
    DELETE_BREED_FAILURE: 'DELETE_BREED_FAILURE',
}

export default breedActionTypes;