const ANIMAL_DETAILS_INITIAL_STATE = {
  processing: false,
  selectedAnimalId: '',
  // animalFilters: {},
  animalsForSale: {},
  purchasedAnimals: {},
  soldAnimals: {},
  visibleAnimals: {},
};

export default ANIMAL_DETAILS_INITIAL_STATE;
