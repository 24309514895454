import { omit } from 'lodash';
import documentActionTypes from './document.action-types';

const INITIAL_STATE = {
  uploadState: null,
  documents: {},
  uploadingDocuments: {},
  error: null,
};

const documentsReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case documentActionTypes.CLEAR_DOCUMENTS:
      return INITIAL_STATE;

    case documentActionTypes.START_DOCUMENT_UPLOAD:
      return {
        ...state,
        uploadingDocuments: {
          ...state.uploadingDocuments,
          [payload.path]: payload,
        },
      };

    case documentActionTypes.DOCUMENT_UPLOAD_SUCCESS:
      return {
        ...state,
        uploadingDocuments: {
          ...omit(state.uploadingDocuments, payload.path),
        },
        documents: {
          ...state.documents,
          [payload.documentId ?? payload.id]: payload,
        },
      };

    case documentActionTypes.DOCUMENT_FARM_FETCH_SUCCESS:
      return {
        ...state,
        documents: {
          ...state.documents,
          ...payload,
        },
      };

    case documentActionTypes.DOCUMENT_ANIMAL_FETCH_SUCCESS:
      return {
        ...state,
        documents: {
          ...state.documents,
          ...payload,
        },
      };
    case documentActionTypes.DELETE_DOCUMENT_SUCCESS:
      return {
        ...state,
        documents: {
          ...state.documents,
          [payload.id ?? payload.documentId]: payload,
        },
      };

    default:
      return state;
  }
};

export default documentsReducer;
