import farmActionTypes from './farm.action-types';

export const selectFarm = (farmId) => ({
  type: farmActionTypes.SELECT_FARM,
  payload: farmId,
});

export const clearFarms = () => ({
  type: farmActionTypes.CLEAR_FARMS
});

export const createFarmStart = (farmData) => ({
  type: farmActionTypes.CREATE_FARM_START,
  payload: farmData,
});

export const createFarmSuccess = (farmDetails) => ({
  type: farmActionTypes.CREATE_FARM_SUCCESS,
  payload: farmDetails,
});

export const createFarmFailure = (error) => ({
  type: farmActionTypes.CREATE_FARM_FAILURE,
  payload: error,
});

export const fetchUserFarmsStart = (userAuth) => ({
  type: farmActionTypes.FETCH_USER_FARMS_START,
  payload: userAuth,
});

export const fetchFarmsSuccess = (farmDetails) => ({
  type: farmActionTypes.FETCH_FARMS_SUCCESS,
  payload: farmDetails,
});

export const fetchFarmsFailure = (error) => ({
  type: farmActionTypes.FETCH_FARMS_FAILURE,
  payload: error,
});

export const updateFarmStart = (farmDetails) => ({
  type: farmActionTypes.UPDATE_FARM_START,
  payload: farmDetails,
});

export const updateFarmSuccess = (farmDetails) => {
  return ({
    type: farmActionTypes.UPDATE_FARM_SUCCESS,
    payload: farmDetails,
  });
}

export const updateFarmImagesSuccess = (farmDetails) => {
  return({
    type: farmActionTypes.UPDATE_FARM_IMAGES_SUCCESS,
    payload: farmDetails,
  });
};

export const updateFarmFailure = (error) => ({
  type: farmActionTypes.UPDATE_FARM_FAILURE,
  payload: error,
});

export const addFarmWorkerStart = (farmDetails, workerDetails) => ({
  type: farmActionTypes.ADD_FARM_WORKER_START,
  payload: { farmDetails, workerDetails },
});

export const addFarmWorkerSuccess = (farmDetails, workerDetails) => ({
  type: farmActionTypes.ADD_FARM_WORKER_SUCCESS,
  payload: { farmDetails, workerDetails },
});

export const addFarmWorkerFailure = (error) => ({
  type: farmActionTypes.ADD_FARM_WORKER_FAILURE,
  payload: error,
});

export const deleteInvitedWorkerStart = (farmDetails) => ({
  type: farmActionTypes.DELETE_INVITED_WORKER_START,
  payload: { farmDetails },
});

export const deleteFarmWorkerStart = (farmDetails) => ({
  type: farmActionTypes.DELETE_FARM_WORKER_START,
  payload: { farmDetails },
});

export const deleteWorkerSuccess = (farmDetails) => ({
  type: farmActionTypes.DELETE_WORKER_SUCCESS,
  payload: { farmDetails },
});

export const deleteWorkerFailure = (error) => ({
  type: farmActionTypes.DELETE_WORKER_FAILURE,
  payload: error,
});

export const deleteFarmStart = (farmDetails) => ({
  type: farmActionTypes.DELETE_FARM_START,
  payload: farmDetails,
});

export const deleteFarmSuccess = (farmDetails) => ({
  type: farmActionTypes.DELETE_FARM_SUCCESS,
  payload: farmDetails,
});

export const deleteFarmFailure = (error) => ({
  type: farmActionTypes.DELETE_FARM_FAILURE,
  payload: error,
});

export const acceptInvitationStart = (farmDetails, user) => ({
  type: farmActionTypes.ACCEPT_INVITATION_START,
  payload: { farmDetails, user },
});

export const acceptInvitationSuccess = (farmDetails, user) => ({
  type: farmActionTypes.ACCEPT_INVITATION_SUCCESS,
  payload: { farmDetails, user },
});

export const acceptInvitationFailure = (error) => ({
  type: farmActionTypes.ACCEPT_INVITATION_FAILURE,
  payload: error,
});
